<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div class="search-form btn-2b5a95">
      <div
        class="l-w"
        :style="{
          height: $store.state.global.isSearchExtend_1
            ? ''
            : $store.state.global.searchFormHeight_1 + 10 + 'px',
        }"
      >
        <el-form
          label-position="right"
          :class="
            $store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'
          "
					:model="searchForm"
					@submit.native.prevent
        >
          <el-form-item label="姓名:">
            <el-input
							v-model.trim="searchForm.name"
							@submit.native.prevent="seach"
							clearable />
          </el-form-item>
          <el-form-item label="跟进人:">
            <el-input
							v-model.trim="searchForm.followusername"
							@submit.native.prevent="seach"
							clearable />
          </el-form-item>
          <el-form-item label="手机:">
            <el-input
							v-model.trim="searchForm.mobile"
							@submit.native.prevent="seach"
							clearable />
          </el-form-item>
          <el-form-item label="QQ:">
            <el-input
							v-model.trim="searchForm.qq"
							@submit.native.prevent="seach"
							clearable />
          </el-form-item>
          <el-form-item label="跟进次数:">
            <el-input
							v-model.trim="searchForm.followcount"
							@submit.native.prevent="seach"
							clearable />
          </el-form-item>
          <el-form-item label="身份证号:">
            <el-input
							v-model.trim="searchForm.idcard"
							@submit.native.prevent="seach"
							clearable />
          </el-form-item>
          <el-form-item label="籍贯:">
            <el-input
							v-model.trim="searchForm.comefrom"
							@submit.native.prevent="seach"
							clearable />
          </el-form-item>
          <el-form-item label="教学点:">
            <el-input
							v-model.trim="searchForm.schoolorgname"
							@submit.native.prevent="seach"
							clearable />
          </el-form-item>
          <el-form-item label="固定电话:">
            <el-input
							v-model.trim="searchForm.phone"
							@submit.native.prevent="seach"
							clearable />
          </el-form-item>
          <el-form-item label="职业:">
            <el-input
							v-model.trim="searchForm.job"
							@submit.native.prevent="seach"
							clearable />
          </el-form-item>
          <el-form-item label="总部给予:">
            <el-select
							v-model="searchForm.hqsend"
							@visible-change="visibleType"
							ref="hqsend"
							clearable>
              <el-option value="1" label="是"></el-option>
              <el-option value="0" label="否"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="城市:">
            <el-input
							v-model.trim="searchForm.cityval"
							@submit.native.prevent="seach"
							clearable />
          </el-form-item>
					<el-form-item label="录入时间起:">
					  <el-date-picker
					    v-model="searchForm.t1"
					    type="date"
					    placeholder="选择日期"
					    value-format="yyyy-MM-dd"
					  >
					  </el-date-picker>
					</el-form-item>
					<el-form-item label="录入时间起止:">
					  <el-date-picker
					    v-model="searchForm.t2"
					    type="date"
					    placeholder="选择日期"
					    value-format="yyyy-MM-dd"
					  >
					  </el-date-picker>
					</el-form-item>
        </el-form>
      </div>
      <div class="r-w">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="seach"
          class="btn-2b5a95"
          >搜索</el-button
        >
				<el-button type="primary" @click="resetQuery">重置</el-button>
        <el-button
          type="primary"
          :icon="
            $store.state.global.isSearchExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          v-if="$store.state.global.searchExtend_1"
          class="btn-2b5a95"
          @click="onExtendClick('isSearchExtend_1')"
          >{{ $store.state.global.searchExtendText_1 }}</el-button
        >
      </div>
    </div>
    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>

      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>
    <!-- 表格 -->
    <el-table
			stripe
			border
			:header-cell-style="{ background: '#044d8c', color: '#fff'}"
      :data="tableRes.list"
      ref="multipleTable"
      style="width: 100%"
      :cell-style="{ padding: 0 }"
      @selection-change="handleSelectionChange"
			:height="tableHeight"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column label="姓名" align="center" width="100">
        <template slot-scope="scope">
          <el-button @click="watchDetail(scope.row.id)" type="text">{{
            scope.row.name
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="手机" prop="mobile" align="center" width="120">
       <template slot-scope="scope" v-if="scope.row.mobile !== null">
				 		 {{ scope.row.mobile | hideMobile(scope.row.followuserid)}}
			</template>
      </el-table-column>
      <el-table-column
        label="跟进人"
        prop="followusername"
        align="center"
        width="100"
      />
      <el-table-column
        label="意向院校/项目"
        prop="wantcollegename"
        align="center"
        width="150"
      />
      <el-table-column
        label="意向专业/培训"
        prop="wantcollegemajor"
        align="center"
        width="150"
      />
      <el-table-column
        label="报名状态"
        prop="baomingtag"
        align="center"
        width="140"
      />

      <el-table-column
        label="最后跟进时间"
        prop="lastfollowtime"
        align="center"
        width="120"
      >
        <template slot-scope="scope">
          {{ scope.row.lastfollowtime | formatDate("YYYY-MM-DD") }}
        </template>
      </el-table-column>
      <el-table-column
        label="最后跟进内容"
        prop="lastfollowcount"
        align="center"
        width="200"
				:show-overflow-tooltip="true"
      />
			<el-table-column
			  label="录入时间"
			  prop="inserttime"
			  align="center"
			  width="100"
				:show-overflow-tooltip="true"
			>
				<template slot-scope="scope">
					{{ scope.row.inserttime | formatDate("YYYY-MM-DD") }}
				</template>
			</el-table-column>
      <el-table-column
        label="教学点"
        prop="schoolorgname"
        align="center"
        width="100"
      />
    </el-table>
    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
			 @size-change="handleSizeChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10,20,50,100,200,300,400,500]"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>
    <!--添加，修改-->
    <el-dialog
      class="btn-2b5a95"
      width="70%"
			v-dialogDrag
      :title="dialogTitle"
			:close-on-click-modal='false'
      :visible.sync="showAddModal"
      v-if="showAddModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="35%"
        :rules="rules"
        class="form-item-w-33"
      >
        <el-form-item prop="companyorgname" label="所属分公司">
          <el-select
            v-model="dynamicValidateForm.companyorgname"
            placeholder="请选择"
            :disabled="!$store.state.login.isMaxAuth"
            clearable
            @change="onChangeCompany($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgid" label="所属教学点">
          <el-select
            v-model="dynamicValidateForm.schoolorgname"
            :disabled="!$store.state.login.isMaxAuth"
            placeholder="请选择"
            clearable
            @change="onChangeSchool($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="name" label="姓名:">
          <el-input v-model.trim="dynamicValidateForm.name" clearable/>
        </el-form-item>
        <el-form-item prop="mobile" label="手机:">
          <el-input
            v-model.trim="dynamicValidateForm.mobile"
            :disabled="addEditDisable"
            maxlength="11"
						autocomplete="off"
						clearable
          />
        </el-form-item>
        <el-form-item label="手机2:">
          <el-input v-model.trim="dynamicValidateForm.mobile2" clearable/>
        </el-form-item>
        <el-form-item label="紧急联系人电话:">
          <el-input v-model.trim="dynamicValidateForm.emergentphone" clearable/>
        </el-form-item>
        <el-form-item label="性别:">
          <el-select
            v-model="dynamicValidateForm.sexval"
            @change="(val, prop) => onChangeSelect(val, 'sexkey')"
            clearable
          >
            <el-option value="1" label="男"></el-option>
            <el-option value="2" label="女"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="微信:">
					<el-input v-model.trim="dynamicValidateForm.weixin" clearable/>
        </el-form-item>
        <el-form-item label="QQ:">
          <el-input v-model.trim="dynamicValidateForm.qq" clearable/>
        </el-form-item>
        <el-form-item prop="photo" label="照片：">
          <el-input v-model.trim="dynamicValidateForm.photo" disabled />
          <el-upload
            class="upload-demo"
            :action="$store.state.global.baseUrl"
            multiple
            :before-upload="beforeUpload"
            :http-request="() => uploadFile('photo')"
            :show-file-list="false"
          >
            <el-button type="primary" class="select-btn">点击上传</el-button>
            <div slot="tip" class="el-upload__tip"></div>
          </el-upload>
        </el-form-item>
				<el-form-item label="家庭邮编:">
				  <el-input v-model.trim="dynamicValidateForm.homezipcode" clearable/>
				</el-form-item>
        <el-form-item label="家庭住址:">
          <el-input v-model.trim="dynamicValidateForm.homeaddr" clearable/>
        </el-form-item>
        <el-form-item prop="studentfromtypeval" label="生源类别:">
          <el-select
        		v-model="dynamicValidateForm.studentfromtypeval"
						@change="studentChange"
						clearable
        		>
            <el-option
              v-for="(student, index) in studentfromtypeList"
              :key="index"
              :value="student"
              :label="student.val"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="转介绍人:" :required="isHaveTo">
        	<el-input v-model.trim="dynamicValidateForm.introducer" clearable/>
        </el-form-item>
        <el-form-item label="介绍费:" :required="isHavefee">
          <el-input v-model.trim="dynamicValidateForm.introducerfee" clearable/>
        </el-form-item>
        <el-form-item prop="introducerpz" label="介绍人凭证：">
          <el-input v-model.trim="dynamicValidateForm.introducerpz" disabled />
          <el-upload
            class="upload-demo"
            :action="$store.state.global.baseUrl"
            multiple
            :before-upload="beforeUpload"
            :http-request="() => uploadFile('introducerpz')"
            :show-file-list="false"
          >
            <el-button type="primary" class="select-btn">点击上传</el-button>
            <div slot="tip" class="el-upload__tip"></div>
          </el-upload>
        </el-form-item>
        <el-form-item label="合作企业登记人:">
          <el-input v-model.trim="dynamicValidateForm.enterprisename" clearable/>
        </el-form-item>
        <el-form-item label="固定电话:">
          <el-input v-model.trim="dynamicValidateForm.phone" clearable/>
        </el-form-item>
        <el-form-item prop="intentionkey" label="意向程度:">
          <el-select v-model.trim="dynamicValidateForm.intentionkey" clearable>
            <el-option
              v-for="item in intentionList"
              :key="item.key"
              :value="item.key"
              :label="item.val"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="followusername" label="跟进人:">
          <el-input v-model.trim="dynamicValidateForm.followusername" disabled />
          <el-button
            type="primary"
            class="select-btn"
						:disabled="followDisabled"
            @click="
              onSelectData(vuser_dialogresult, 'showFollowDataModal', {
                schoolorgid: dynamicValidateForm.schoolorgid,
                leaveflag: 0,
              })
            "
            >选择</el-button
          >
        </el-form-item>
        <el-form-item label="职业:">
					<el-select
					v-model="dynamicValidateForm.job"
					 @change="changeJob"
					 clearable
					 >
						<el-option
							v-for="(objob, index) in occupationList"
							:key="index"
							:label="objob.val"
							:value="objob"
							clearable

						/>
					</el-select>
        </el-form-item>
        <el-form-item label="身份证号:">
          <el-input v-model.trim="dynamicValidateForm.idcard" clearable/>
        </el-form-item>
        <el-form-item label="意向院校:">
          <el-input v-model.trim="dynamicValidateForm.wantcollegename" clearable/>
        </el-form-item>
        <el-form-item label="意向专业:">
          <el-input v-model.trim="dynamicValidateForm.wantcollegemajor" clearable/>
        </el-form-item>
        <el-form-item label="入伍时间:">
          <el-date-picker
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd hh:mm:ss"
            v-model="dynamicValidateForm.joinuptime"
            placeholder="请选择"
						clearable
          />
        </el-form-item>
        <el-form-item label="退伍时间:">
          <el-date-picker
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd hh:mm:ss"
            v-model="dynamicValidateForm.joinouttime"
            placeholder="请选择"
						clearable
          />
        </el-form-item>
        <el-form-item label="退役号:">
          <el-input v-model.trim="dynamicValidateForm.retirementnumber" clearable/>
        </el-form-item>
				<el-form-item prop="fromtypeval" label="生源来源:">
				  <el-select
						v-model="dynamicValidateForm.fromtypeval"
						@change="fromtypevalChange"
						clearable
						>
							<el-option
							  v-for="(studently, index) in fromtypeList"
							  :key="index"
							  :value="studently"
							  :label="studently.val"
							/>
				  </el-select>
				</el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
       <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm', 'reportRoutePage')"
          >报学历
        </el-button>
        <el-button
          type="primary"
          @click="
            submitAddOrEditForm('dynamicValidateForm', 'reportClassRoutePage')
          "
          >报辅导班
        </el-button>
        <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm', 'purchaseRoute')"
          >购买教材
        </el-button>
        <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm', 'preRoute')"
          >代预报名
        </el-button>
        <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm', 'techfudaoFree')"
          >报技能培训
        </el-button>
        <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm')"
          >保存
        </el-button>
      </span>
    </el-dialog>
		<!-- 注册收费 -->
		<el-dialog
		  class="btn-2b5a95"
		  title="注册收费："
			v-dialogDrag
			:close-on-click-modal='false'
		  :visible.sync="showRegisterModal"
		  v-if="showRegisterModal"
		  append-to-body
		  width="1300px"
		>
		  <el-form
		    :model="dynamicValidateForm"
		    ref="dynamicValidateForm"
		    class="demo-dynamic form-item-w-33"
		    status-icon
		    label-width="150px"
		    :rules="rules"
		  >
		    <h3>学生信息</h3>
		    <el-form-item prop="companyorgid" label="分公司：">
		      <el-select
		        v-model="dynamicValidateForm.companyorgname"
		        placeholder="请选择"
		        :disabled="!$store.state.login.isMaxAuth"
		        clearable
		        @change="onChangeCompany($event, 'dynamicValidateForm')"
		      >
		        <el-option
		          v-for="item in $store.state.selectData.companyList"
		          :key="item.key"
		          :label="item.val"
		          :value="item.key"
		        >
		        </el-option>
		      </el-select>
		    </el-form-item>
		    <el-form-item prop="schoolorgid" label="教学点：">
		      <el-select
		        v-model="dynamicValidateForm.schoolorgname"
		        placeholder="请选择"
		        clearable
		        @change="onChangeSchool($event, 'dynamicValidateForm')"
		      >
		        <el-option
		          v-for="item in $store.state.selectData.schoolList"
		          :key="item.key"
		          :label="item.val"
		          :value="item.key"
		        >
		        </el-option>
		      </el-select>
		    </el-form-item>
		    <el-form-item label="姓名：">
		      <el-input
		        v-model="dynamicValidateForm.studentname"
		        disabled
		      ></el-input>
		    </el-form-item>
		    <el-form-item label="院校：">
		      <el-input
		        v-model="dynamicValidateForm.collegename"
		        disabled
		      ></el-input>
		    </el-form-item>
		    <el-form-item label="专业：">
		      <el-input v-model="dynamicValidateForm.majorname" disabled></el-input>
		    </el-form-item>
		    <el-form-item label="缴费学年：">
		      <el-input v-model="dynamicValidateForm.iyear" disabled></el-input>
		    </el-form-item>
		    <h3>收费信息(***请仔细核对每一项)</h3>
		    <el-form-item label="预收学位费：" prop="studentfee">
		      <el-input v-model.trim="dynamicValidateForm.studentfee" clearable/>
		    </el-form-item>
		    <el-form-item label="学位费凭证号：" prop="studentshouju">
		      <el-input v-model.trim="dynamicValidateForm.studentshouju" clearable/>
		    </el-form-item>
		    <el-form-item label="预收其它费抵学费：" prop="otherfee">
		      <el-input v-model.trim="dynamicValidateForm.otherfee" clearable/>
		    </el-form-item>
		    <el-form-item label="其它抵学费凭证：" prop="othershouju">
		      <el-input v-model.trim="dynamicValidateForm.othershouju" clearable/>
		    </el-form-item>
		    <el-form-item label="收费项目：" prop="itemname">
		      <el-input v-model.trim="dynamicValidateForm.itemname" clearable/>
		    </el-form-item>
		    <el-form-item label="应收金额：" prop="money">
		      <el-input v-model.trim="dynamicValidateForm.money" clearable/>
		    </el-form-item>
		    <el-form-item label="优惠">
					<el-select
						v-model="dynamicValidateForm.discountfee"
						clearable
						placeholder="请选择"
						>
						<el-option
							v-for="(free, index) in discoundtList"
							:key="index"
							:value="free.discountamount"
							:label="free.discountamount"
							/>
					</el-select>
		    </el-form-item>
		    <el-form-item label="实收金额：" prop="recvitemmoney">
		      <el-input v-model.trim="dynamicValidateForm.recvitemmoney" clearable/>
		    </el-form-item>
		    <el-form-item label="凭证编号：" prop="shoujucode">
		      <el-input
		        v-model="dynamicValidateForm.shoujucode"
		        disabled
		      ></el-input>
		      <el-button type="primary" class="select-btn" @click="getshoujuinfo"
		        >重新获取</el-button
		      >
		    </el-form-item>
		    <el-form-item label="收款时间：" prop="shoukuantime">
		      <el-date-picker
		        type="datetime"
		        v-model="dynamicValidateForm.shoukuantime"
		        format="yyyy-MM-dd HH:mm:ss"
		        value-format="yyyy-MM-dd HH:mm:ss"
		        placeholder="请选择"
		      />
		    </el-form-item>
		    <el-form-item label="银行/现金：" prop="bankorcash" >
		      <el-input
		        v-model="bankorcash[dynamicValidateForm.bankorcash]"
		        disabled
		      />
		      <el-button
		        type="primary"
		        class="select-btn"
		        @click="
		          onSelectData(account_dialogresult, 'showSelectAccountModal', {
		            schoolorgid: dynamicValidateForm.schoolorgid,
		          })
		        "
		        >选择账户</el-button
		      >
		    </el-form-item>
		    <el-form-item label="账户名称：" prop="accountname">
		      <el-input
		        v-model="dynamicValidateForm.accountname"
		        disabled
		      ></el-input>
		    </el-form-item>
		    <el-form-item label="卡号：" prop="cardno">
		      <el-input v-model="dynamicValidateForm.cardno" disabled></el-input>
		    </el-form-item>
		    <el-form-item label="开票说明：" prop="shoukuanremark">
		      <el-input v-model.trim="dynamicValidateForm.shoukuanremark" clearable></el-input>
		    </el-form-item>
		  </el-form>
		  <span slot="footer" class="dialog-footer">
		    <el-button
		      type="primary"
		      @click="submitRegisterForm('dynamicValidateForm')"
		      >提交</el-button
		    >
		  </span>
		</el-dialog>

    <!-- 跟进 -->
    <el-dialog
      class="btn-2b5a95"
      title="跟进数据"
			v-dialogDrag
      :visible.sync="showFollowModal"
      v-if="showFollowModal"
			:close-on-click-modal='false'
      width="35%"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        :rules="rules"
        ref="dynamicValidateForm"
        class="form-item-w-100"
        label-width="20%"
      >
        <el-form-item label="教学点:">
          <el-input v-model="dynamicValidateForm.schoolorgname" disabled />
        </el-form-item>
        <el-form-item label="姓名:">
          <el-input v-model="dynamicValidateForm.name" disabled />
        </el-form-item>
        <el-form-item label="手机:">
          <el-input v-model="dynamicValidateForm.mobile" disabled autocomplete="off"/>
        </el-form-item>
        <el-form-item label="qq:">
          <el-input v-model="dynamicValidateForm.qq" disabled />
        </el-form-item>
        <el-form-item label="跟进内容:" prop="followcontent">
          <el-input
            v-model.trim="dynamicValidateForm.followcontent"
            type="textarea"
						clearable
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitFollowForm('dynamicValidateForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 跟进详情 -->
    <el-dialog
      class="btn-2b5a95"
      title="跟进详情"
			v-dialogDrag
			:close-on-click-modal='false'
      :visible.sync="showFollowDetailModals"
      v-if="showFollowDetailModals"
      width="70%"
      append-to-body
    >
      <el-table
				:data="detailData.list"
				border
				stripe
				:key="key"
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
				style="width: 100%">
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="学生" prop="qianzainame" align="center" />
        <el-table-column label="跟进时间" align="center">
          <template slot-scope="scope">
            {{ scope.row.inserttime | formatDate("YYYY-MM-DD HH:mm:ss") }}
          </template>
        </el-table-column>
        <el-table-column label="跟进人" prop="username" align="center" />
        <el-table-column
          label="潜在学生教学点"
          prop="schoolorgname"
          align="center"
        />
        <el-table-column label="跟进内容" prop="followcontent" align="center" :show-overflow-tooltip="true"/>
      </el-table>
      <el-pagination
        background
        :current-page="selectPageInfo.pageindex"
        :current-size="selectPageInfo.pagesize"
        layout="total, prev, pager, next, jumper"
        :total="detailData.totalcount"
        @current-change="followDetailHandleCurrentChange"
      />
    </el-dialog>

    <!-- 报读学历 -->
    <el-dialog
      class="btn-2b5a95"
			v-dialogDrag
      :visible.sync="showReportModal"
			:close-on-click-modal='false'
      v-if="showReportModal"
      width="1300px"
      append-to-body
      title="报读学历"
    >
      <el-form
        :model="report_dynamicValidateForm"
        ref="report_dynamicValidateForm"
        :rules="rules"
        class="form-item-w-33"
        label-width="30%"
      >
        <div class="title">本人简历</div>
          <div
            v-for="(item, i) in report_dynamicValidateForm.moreItems1"
            :key="item.id"
            class="more-form-item-w box-w-33"
          >
            <el-form-item
              label="起止时间:"
              :prop="'moreItems1.' + i + '.duration'"
            >
              <el-input v-model.trim="item.duration" clearable></el-input>
            </el-form-item>
            <el-form-item
              label-width="135px"
              label="学习及工作经历："
              :prop="'moreItems1.' + i + '.appoint'"

            >
              <el-input type="textarea" v-model.trim="item.appoint" clearable></el-input>
            </el-form-item>
            <el-form-item
              label="职务："
              :prop="'moreItems1.' + i + '.post'"
            >
              <el-input v-model.trim="item.post" clearable></el-input>
            </el-form-item>
            <el-form-item
              label="学制："
              :prop="'moreItems1.' + i + '.xuezhival'"
            >
              <el-input v-model.trim="item.xuezhival" clearable></el-input>
            </el-form-item>
            <el-form-item
              label="工作年限："
              :prop="'moreItems1.' + i + '.workingyears'"
            >
              <el-input v-model.trim="item.workingyears" clearable></el-input>
            </el-form-item>
            <el-form-item
              label="毕业/肄业："
              :prop="'moreItems1.' + i + '.graduation'"
            >
              <el-input v-model.trim="item.graduation" clearable></el-input>
            </el-form-item>
            <el-button
              v-if="i !== 0"
              @click="report_onRemoveMoreForm(i, 'moreItems1')"
              style="margin: 0 10px; height: 40px"
              >删除</el-button
            >
          </div>
          <el-button @click="report_addFormItem1" style="margin: 10px"
            >添加一行</el-button
          >

          <div class="title">家庭成员</div>
          <div
            v-for="(item, i) in report_dynamicValidateForm.moreItems2"
            :key="item.id"
            class="more-form-item-w box-w-25"
          >
            <el-form-item
              label="姓名："
              :prop="'moreItems2.' + i + '.resumename'"

            >
              <el-input v-model.trim="item.resumename" clearable></el-input>
            </el-form-item>
            <el-form-item
              label="称谓："
              :prop="'moreItems2.' + i + '.appellation'"
            >
              <el-input v-model.trim="item.appellation" clearable></el-input>
            </el-form-item>
            <el-form-item
              label="年龄："
              :prop="'moreItems2.' + i + '.age'"
            >
              <el-input v-model.trim="item.age" clearable></el-input>
            </el-form-item>
            <el-form-item
              label="职业："
              :prop="'moreItems2.' + i + '.occupation'"
            >
              <el-input v-model.trim="item.occupation" clearable></el-input>
            </el-form-item>
            <el-button
              v-if="i !== 0"
              @click="report_onRemoveMoreForm(i, 'moreItems2')"
              style="margin: 0 10px; height: 40px"
              >删除</el-button
            >
          </div>
          <el-button @click="report_addFormItem2" style="margin: 10px"
            >添加一行</el-button
          >

          <div class="title">资料上传</div>
          <el-form-item prop="idphoto" label="身份证正面:">
            <el-input v-model="report_dynamicValidateForm.idphoto" disabled />
            <el-upload
              class="upload-demo"
              :action="$store.state.global.baseUrl"
              multiple
              :before-upload="beforeUpload"
              :http-request="() => upload('idphoto')"
              :show-file-list="false"
            >
              <el-button type="primary" class="select-btn">点击上传</el-button>
              <div slot="tip" class="el-upload__tip"></div>
            </el-upload>
          </el-form-item>
					<el-form-item prop="idphoto2" label="身份证反面:">
					  <el-input v-model="report_dynamicValidateForm.idphoto2" disabled />
					  <el-upload
					    class="upload-demo"
					    :action="$store.state.global.baseUrl"
					    multiple
					    :before-upload="beforeUpload"
					    :http-request="() => upload('idphoto2')"
					    :show-file-list="false"
					  >
					    <el-button type="primary" class="select-btn">点击上传</el-button>
					    <div slot="tip" class="el-upload__tip"></div>
					  </el-upload>
					</el-form-item>
          <el-form-item prop="schoolphoto" label="毕业证:">
            <el-input v-model="report_dynamicValidateForm.schoolphoto" disabled />
            <el-upload
              class="upload-demo"
              :action="$store.state.global.baseUrl"
              multiple
              :before-upload="beforeUpload"
              :http-request="() => upload('schoolphoto')"
              :show-file-list="false"
            >
              <el-button type="primary" class="select-btn">点击上传</el-button>
              <div slot="tip" class="el-upload__tip"></div>
            </el-upload>
          </el-form-item>
          <el-form-item prop="registerphoto" label="户口本首页:">
            <el-input v-model="report_dynamicValidateForm.registerphoto" disabled />
            <el-upload
              class="upload-demo"
              :action="$store.state.global.baseUrl"
              multiple
              :before-upload="beforeUpload"
              :http-request="() => upload('registerphoto')"
              :show-file-list="false"
            >
              <el-button type="primary" class="select-btn">点击上传</el-button>
              <div slot="tip" class="el-upload__tip"></div>
            </el-upload>
          </el-form-item>
					<el-form-item prop="registerphoto2" label="户口本本人页:">
					  <el-input v-model="report_dynamicValidateForm.registerphoto2" disabled />
					  <el-upload
					    class="upload-demo"
					    :action="$store.state.global.baseUrl"
					    multiple
					    :before-upload="beforeUpload"
					    :http-request="() => upload('registerphoto2')"
					    :show-file-list="false"
					  >
					    <el-button type="primary" class="select-btn">点击上传</el-button>
					    <div slot="tip" class="el-upload__tip"></div>
					  </el-upload>
					</el-form-item>

					<el-form-item prop="photo" label="照片:">
					  <el-input v-model="report_dynamicValidateForm.photo" disabled />
					  <el-upload
					    class="upload-demo"
					    :action="$store.state.global.baseUrl"
					    multiple
					    :before-upload="beforeUpload"
					    :http-request="() => upload('photo')"
					    :show-file-list="false"
					  >
					    <el-button type="primary" class="select-btn">点击上传</el-button>
					    <div slot="tip" class="el-upload__tip"></div>
					  </el-upload>
					</el-form-item>
					<el-form-item prop="regphoto" label="注册备案表:">
					  <el-input v-model="report_dynamicValidateForm.regphoto" disabled />
					  <el-upload
					    class="upload-demo"
					    :action="$store.state.global.baseUrl"
					    multiple
					    :before-upload="beforeUpload"
					    :http-request="() => upload('regphoto')"
					    :show-file-list="false"
					  >
					    <el-button type="primary" class="select-btn">点击上传</el-button>
					    <div slot="tip" class="el-upload__tip"></div>
					  </el-upload>
					</el-form-item>
					<el-form-item prop="residence" label="居住证明:">
					  <el-input v-model="report_dynamicValidateForm.residence" disabled />
					  <el-upload
					    class="upload-demo"
					    :action="$store.state.global.baseUrl"
					    multiple
					    :before-upload="beforeUpload"
					    :http-request="() => upload('residence')"
					    :show-file-list="false"
					  >
					    <el-button type="primary" class="select-btn">点击上传</el-button>
					    <div slot="tip" class="el-upload__tip"></div>
					  </el-upload>
					</el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button
            type="primary"
            @click="onComfirm(
						'report_dynamicValidateForm'
						)"
            >确 定</el-button
          >
        </div>
    </el-dialog>
    <!-- 报读辅导班 -->
    <el-dialog
      class="btn-2b5a95"
			v-dialogDrag
      :visible.sync="reportClassModal"
      v-if="reportClassModal"
      width="50%"
			:close-on-click-modal='false'
      append-to-body
      title="报读辅导班"
    >
      <el-form
        :model="dynamicValidateForm"
        :rules="rules"
        ref="reportClass_dynamicValidateForm"
        label-width="30%"
        class="form-item-w-50"
      >
        <div class="title">基本信息</div>
        <el-form-item prop="name" label="姓名:">
          <el-input v-model.trim="dynamicValidateForm.name" disabled />
        </el-form-item>
        <el-form-item label="身份证号:" prop="idcard">
          <el-input v-model.trim="dynamicValidateForm.idcard" clearable />
        </el-form-item>
        <el-form-item prop="companyorgname" label="所属分公司">
          <el-select
            v-model="dynamicValidateForm.companyorgname"
            placeholder="请选择"
            :disabled="!$store.state.login.isMaxAuth"
            clearable
            @change="onChangeCompany($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgid" label="所属教学点">
          <el-select
            v-model="dynamicValidateForm.schoolorgname"
            placeholder="请选择"
            clearable
            @change="onChangeSchool($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <div class="title">报读辅导班</div>
        <el-form-item prop="fudaoclasstypename" label="辅导班班别:">
          <el-input disabled v-model.trim="dynamicValidateForm.fudaoclasstypename" />
          <el-button
            type="primary"
            class="select-btn"
            @click="
              onSelectData(
                stufudaofeestandard_dialogresult,
                'reportClassDataModal',
                {
                  schoolorgid: dynamicValidateForm.schoolorgid,
                  projecttypeval: '成人高考',
                }
              )
            "
            >选择</el-button
          >
        </el-form-item>
        <el-form-item prop="fudaotypeval" label="辅导班类型:">
          <el-input disabled v-model.trim="dynamicValidateForm.fudaotypeval" />
        </el-form-item>
        <el-form-item prop="fudaofee" label="辅导费:">
          <el-input disabled v-model.trim="dynamicValidateForm.fudaofee" />
        </el-form-item>
        <el-form-item prop="bookfee" label="教材费:">
          <el-input disabled v-model.trim="dynamicValidateForm.bookfee" />
        </el-form-item>

        <el-form-item prop="levelkey" label="层次:">
          <el-select v-model="dynamicValidateForm.levelkey" clearable>
            <el-option
              v-for="item in leveltypeList"
              :key="item.key"
              :value="item.key"
              :label="item.val"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="regdate" label="报读时间:">
          <el-date-picker
            v-model="dynamicValidateForm.regdate"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="请选择日期"
						clearable
          />
        </el-form-item>
        <el-form-item prop="grade" label="年级:">
          <el-select v-model="dynamicValidateForm.grade" clearable>
            <el-option
              v-for="item in gradeList"
              :key="item.key"
              :value="item.key"
              :label="item.val"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="reportClassComfirm('reportClass_dynamicValidateForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 购买教材 -->
    <el-dialog
      class="btn-2b5a95"
			v-dialogDrag
      :visible.sync="purchaseModal"
      v-if="purchaseModal"
      width="82%"
			:close-on-click-modal='false'
      append-to-body
      title="购买教材"
    >
      <el-form
        :model="moreItemForm"
        :rules="rules"
        ref="moreItemForm"
        class="form-item-w-50"
        label-width="40%"
      >
        <div class="title">基本信息</div>
        <el-form-item prop="name" label="姓名:">
          <el-input v-model="moreItemForm.name" disabled />
        </el-form-item>
        <el-form-item prop="mobile" label="手机号:">
          <el-input v-model="moreItemForm.mobile" disabled />
        </el-form-item>
        <el-form-item prop="companyorgname" label="所属分公司:">
          <el-select
            v-model="moreItemForm.companyorgname"
            placeholder="请选择"
            :disabled="!$store.state.login.isMaxAuth"
            clearable
            @change="onChangeCompany($event, 'moreItemForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgid" label="所属教学点:">
          <el-select
            v-model="moreItemForm.schoolorgname"
            placeholder="请选择"
            clearable
            @change="onChangeSchool($event, 'moreItemForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="buytime" label="购买时间:">
          <el-date-picker
            v-model="moreItemForm.buytime"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd 00:00:00"
						clearable
          >
          </el-date-picker>
        </el-form-item>

        <div class="title">增加教材</div>
        <div
          v-for="(item, i) in moreItemForm.moreItems"
          :key="i"
					class="more-form-www"
        >
          <el-form-item
            label="教材名称"
            :prop="'moreItems.' + i + '.bookname'"
            :rules="{
              required: true,
              message: '必填字段',
              trigger: 'blur',
            }"
          >
            <el-input v-model.trim="item.bookname" clearable></el-input>
          </el-form-item>
          <el-form-item
            label="单价"
            :prop="'moreItems.' + i + '.price'"
            :rules="{
              required: true,
              message: '必填字段',
              trigger: 'blur',
            }"
          >
            <el-input v-model.trim="item.price" clearable></el-input>
          </el-form-item>
          <el-form-item
            label="数量"
            :prop="'moreItems.' + i + '.quality'"
            :rules="{
              required: true,
              message: '必填字段',
              trigger: 'blur',
            }"
          >
            <el-input v-model.trim="item.quality" clearable></el-input>
          </el-form-item>
          <el-form-item
            label="折扣"
            :prop="'moreItems.' + i + '.discount'"
            :rules="{
              required: true,
              message: '必填字段',
              trigger: 'blur',
            }"
          >
            <el-select v-model.trim="item.discount" placeholder="请选择" clearable>
              <el-option label="不打折" value="1"></el-option>
              <el-option
                v-for="item in 9"
                :key="item"
                :label="item + '折'"
                :value="item / 10"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-button
            v-if="i !== 0"
            @click="onRemoveMoreForm(i)"
            style="margin: 0 10px; height: 40px"
            >删除</el-button
          >
        </div>
        <el-button @click="addFormItem">添加一行</el-button>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="purchaseComfirm('moreItemForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 代预报名 -->
    <el-dialog
      class="btn-2b5a95"
			v-dialogDrag
			:close-on-click-modal='false'
      :visible.sync="preModal"
      v-if="preModal"
      width="50%"
      append-to-body
      title="代预报名"
    >
      <el-form
        :model="dynamicValidateForm"
        :rules="rules"
        ref="pre_dynamicValidateForm"
        class="form-item-w-50"
        label-width="30%"
      >
        <div class="title">基本信息</div>
        <el-form-item prop="name" label="姓名:">
          <el-input v-model="dynamicValidateForm.name" disabled />
        </el-form-item>
        <el-form-item label="性别:">
          <el-select
            v-model="dynamicValidateForm.sexval"
            @change="(val, prop) => onChangeSelect(val, 'sexkey')"
            clearable
          >
            <el-option value="1" label="男"></el-option>
            <el-option value="2" label="女"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="民族:" prop="nationkey">
          <el-select v-model="dynamicValidateForm.nationkey" clearable>
            <el-option
              v-for="(item, index) in nationList"
              :key="index"
              :label="item.val"
              :value="item.key"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="政治面貌:" prop="polikey">
          <el-select v-model="dynamicValidateForm.polikey" clearable>
            <el-option
              v-for="item in policyList"
              :key="item.key"
              :value="item.key"
              :label="item.val"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="家庭住址:" prop="homeaddr">
          <el-input v-model.trim="dynamicValidateForm.homeaddr" clearable/>
        </el-form-item>
        <el-form-item label="家庭邮编:" prop="homezipcode">
          <el-input v-model.trim="dynamicValidateForm.homezipcode" clearable/>
        </el-form-item>
        <el-form-item label="职业:" prop="job">
          <el-input v-model.trim="dynamicValidateForm.job" clearable/>
        </el-form-item>
        <el-form-item label="证号:" prop="idcard">
          <el-input v-model.trim="dynamicValidateForm.idcard" clearable/>
        </el-form-item>

        <el-form-item prop="companyorgname" label="所属分公司">
          <el-select
            v-model="dynamicValidateForm.companyorgname"
            placeholder="请选择"
            :disabled="!$store.state.login.isMaxAuth"
            clearable
            @change="onChangeCompany($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgid" label="所属教学点">
          <el-select
            v-model="dynamicValidateForm.schoolorgname"
            placeholder="请选择"
            clearable
            @change="onChangeSchool($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <div class="title">预报名信息</div>

        <el-form-item prop="levelkey" label="层次:">
                  <el-select v-model="dynamicValidateForm.levelkey" clearable>
                     <el-option
              v-for="item in leveltypeList"
              :key="item.key"
              :value="item.key"
              :label="item.val"
            >
            </el-option>
                     </el-select
          >
              </el-form-item
        >

        <el-form-item label="年级:" prop="year">
          <el-select
            v-model="dynamicValidateForm.year"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in gradeList"
              :key="item.key"
              :value="item.key"
              :label="item.val"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="考试科目组:"
          :rules="[{ required: true }]"
          style="width: 100%"
        >
          <div class="row-2col">
            <span>考试科目组：高升专/本</span>
            <el-checkbox-group
              v-model="highSubjects"
              @change="onChangeHighSubjects"
              :disabled="dynamicValidateForm.levelkey !== '02'"
            >
              <el-checkbox label="kaoshikemu10">语文</el-checkbox>
              <el-checkbox label="kaoshikemu11">数学</el-checkbox>
              <el-checkbox label="kaoshikemu12">英语</el-checkbox>
              <el-checkbox label="kaoshikemu13">史地(高升本加试)</el-checkbox>
              <el-checkbox label="kaoshikemu14">理化(高升本加试)</el-checkbox>
              <el-checkbox label="kaoshikemu15">术科(艺术类加试)</el-checkbox>
            </el-checkbox-group>
          </div>

          <div class="row-2col">
            <span>专升本</span>
            <el-checkbox-group
              v-model="speciallySubjects"
              @change="onChangeSpeciallySubjects"
              :disabled="dynamicValidateForm.levelkey !== '03'"
            >
              <el-checkbox label="kaoshikemu20">政治</el-checkbox>
              <el-checkbox label="kaoshikemu21">英语 </el-checkbox>
              <el-checkbox label="kaoshikemu22">高数</el-checkbox>
              <el-checkbox label="kaoshikemu23">语文</el-checkbox>
              <el-checkbox label="kaoshikemu24">民法</el-checkbox>
              <el-checkbox label="kaoshikemu25">医学综合</el-checkbox>
              <el-checkbox label="kaoshikemu26">艺术概论</el-checkbox>
              <el-checkbox label="kaoshikemu27">教育理论</el-checkbox>
              <el-checkbox label="kaoshikemu28">生态学</el-checkbox>
              <el-checkbox label="kaoshikemu29">术科</el-checkbox>
            </el-checkbox-group>
          </div>
        </el-form-item>

        <el-form-item prop="wantcollegename1" label="院校1:">
          <el-input v-model.trim="dynamicValidateForm.wantcollegename1" clearable/>
        </el-form-item>
        <el-form-item prop="wantmarjorname11" label="专业1-1:">
          <el-input v-model.trim="dynamicValidateForm.wantmarjorname11" clearable/>
        </el-form-item>
        <el-form-item prop="wantmarjorname12" label="专业1-2:">
          <el-input v-model.trim="dynamicValidateForm.wantmarjorname12" clearable/>
        </el-form-item>
        <el-form-item prop="wantcollegename2" label="院校2:">
          <el-input v-model.trim="dynamicValidateForm.wantcollegename2" clearable/>
        </el-form-item>
        <el-form-item prop="wantmarjorname21" label="专业2-1:">
          <el-input v-model.trim="dynamicValidateForm.wantmarjorname21" clearable/>
        </el-form-item>
        <el-form-item prop="wantmarjorname22" label="专业2-2:">
          <el-input v-model.trim="dynamicValidateForm.wantmarjorname22" clearable/>
        </el-form-item>
        <el-form-item prop="wantcollegename3" label="院校3:">
          <el-input v-model.trim="dynamicValidateForm.wantcollegename3" clearable/>
        </el-form-item>
        <el-form-item prop="wantmarjorname31" label="专业3-1:">
          <el-input v-model.trim="dynamicValidateForm.wantmarjorname31" clearable/>
        </el-form-item>
        <el-form-item prop="wantmarjorname32" label="专业3-2:">
          <el-input v-model.trim="dynamicValidateForm.wantmarjorname32" clearable/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="preComfirm('pre_dynamicValidateForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 报读技能培训 -->
    <el-dialog
      class="btn-2b5a95"
      title="报读技能"
			v-dialogDrag
			:close-on-click-modal='false'
      :visible.sync="skillModal"
      v-if="skillModal"
      width="40%"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        :rules="rules"
				status-icon
        ref="skill_dynamicValidateForm"
        class="form-item-w-50"
        label-width="35%"
      >
        <div class="title">基本信息</div>
        <el-form-item prop="studentname" label="姓名:">
          <el-input
            v-model="dynamicValidateForm.studentname"
            disabled
            placeholder="请填写姓名"
          />
        </el-form-item>

        <el-form-item prop="companyorgname" label="所属分公司">
          <el-select
            v-model="dynamicValidateForm.companyorgname"
            placeholder="请选择"
            :disabled="!$store.state.login.isMaxAuth"
            clearable
            @change="onChangeCompany($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgid" label="所属教学点">
          <el-select
            v-model="dynamicValidateForm.schoolorgname"
            placeholder="请选择"
            clearable
            @change="onChangeSchool($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="身份证号:" prop="idcard">
          <el-input
            v-model.trim="dynamicValidateForm.idcard"
            placeholder="请填写证号"
						clearable
          />
        </el-form-item>
        <div class="title">报读技能</div>
        <el-form-item prop="fudaoclasstypename" label="班别名称:">
          <el-input disabled v-model="dynamicValidateForm.fudaoclasstypename" />
          <el-button
            type="primary"
            class="select-btn"
            @click="
              onSelectData(stufudaofeestandard_dialogresult, 'skillDataModal', {
                schoolorgid: dynamicValidateForm.schoolorgid,
                projecttypeval: '技能培训',
              })
            "
            >选择</el-button
          >
        </el-form-item>
        <el-form-item prop="fudaotypeval" label="培训类型:">
          <el-input disabled v-model.trim="dynamicValidateForm.fudaotypeval" />
        </el-form-item>
        <el-form-item prop="fudaofee" label="培训费:">
          <el-input disabled v-model.trim="dynamicValidateForm.fudaofee" />
        </el-form-item>
        <el-form-item prop="bookfee" label="教材费:">
          <el-input disabled v-model.trim="dynamicValidateForm.bookfee" />
        </el-form-item>

        <el-form-item prop="regdate" label="报读时间:">
          <el-date-picker
            v-model="dynamicValidateForm.regdate"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd hh:mm:ss"
            placeholder="请选择日期"
						clearable
          />
        </el-form-item>
				<el-form-item label="学生类型:" prop="type">
						<el-select
						  v-model="dynamicValidateForm.type"
						  placeholder="请选择"
						  clearable
						>
						    <el-option
									v-for="(value, key) in type"
									:key="key"
									:value="value"
									:label="value"
									clearable
						    >
						    </el-option>
						</el-select>
					</el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="skillComfirm('skill_dynamicValidateForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 分配班主任 -->
    <el-dialog
      class="btn-2b5a95"
      title="分配班主任"
			v-dialogDrag
			:close-on-click-modal='false'
      :visible.sync="distributionModal"
      v-if="distributionModal"
      width="660px"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        :rules="rules"
        ref="dis_dynamicValidateForm"
        class="form-item-w-100"
      >
        <el-form-item label="学生姓名">
          <el-input v-model="dynamicValidateForm.name" disabled />
        </el-form-item>
        <el-form-item prop="companyorgid" label="新跟进人所属分公司">
          <el-select
            v-model="dynamicValidateForm.companyorgname"
            placeholder="请选择"
            :disabled="!$store.state.login.isMaxAuth"
            clearable
            @change="onChangeCompany($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgid" label="新跟进人所属教学点">
          <el-select
            v-model="dynamicValidateForm.schoolorgname"
            placeholder="请选择"
            clearable
            :disabled="disabled"
            @change="onChangeSchool($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          prop="followusername"
          label="新跟进人姓名:"
          :rules="[{ required: true, message: '必填字段', trigger: 'change' }]"
        >
          <el-input v-model="dynamicValidateForm.followusername" disabled />
          <el-button
            type="primary"
            class="select-btn"
            @click="
              onSelectData(user_dialogresult, 'showFollowDataModal', {
                schoolorgid: dynamicValidateForm.schoolorgid,
                leaveflag: 0,
              })
            "
            >选择</el-button
          >
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="distributionComfirm('dis_dynamicValidateForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- 收费 -->
    <el-dialog
      class="btn-2b5a95"
      title="收费"
			v-dialogDrag
			:close-on-click-modal='false'
      :visible.sync="payModal"
      v-if="payModal"
      width="1300px"
      append-to-body
      :fullscreen="$store.state.global.isFullscreen"
    >
      <span slot="title" class="el-dialog__title">
        <span>收费</span>
        <span
          :class="
            $store.state.global.isFullscreen
              ? 'el-icon-copy-document'
              : 'el-icon-full-screen'
          "
          @click="isExpand($store.state.global.isFullscreen)"
        ></span>
      </span>
      <el-tabs v-model="activeName" type="card" @tab-click="handleClickPay">
        <el-tab-pane label="学位费" name="studentlist">
          <el-table
            :data="payForm.studentlist"
            style="width: 100%"
						border
						:key="key"
						stripe
						:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              label="流水号"
              prop="id"
              align="center"
              width="100"
            ></el-table-column>
            <el-table-column
              label="学生姓名"
              prop="qianzainame"
              align="center"
              width="150"
            >
            </el-table-column>
            <el-table-column
              label="报读年级"
              prop="grade"
              align="center"
              width="100"
            ></el-table-column>
            <el-table-column
              label="应收"
              prop="shouldrecvmoney"
              align="center"
              width="100"
            >
            </el-table-column>
            <el-table-column
              label="实收"
              prop="realrecvmoney"
              align="center"
              width="100"
            >
            </el-table-column>
            <el-table-column
              label="退费"
              prop="feebackmoney"
              align="center"
              width="100"
            >
            </el-table-column>
            <el-table-column
              label="所属教学点"
              prop="schoolorgname"
              align="center"
              width="100"
            >
            </el-table-column>
            <el-table-column label="操作" align="center" width="100">
              <template slot-scope="scope">
                <el-button @click="studentFree(scope.row.id)">收费</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="入学注册" name="studentregisterfeedetail">
          <el-table
            :data="payForm.studentregisterfeedetail"
						border
						stripe
						:key="key"
						:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
            style="width: 100%"
          >
            <el-table-column
              label="流水号"
              prop="id"
              align="center"
              width="100"
            ></el-table-column>
            <el-table-column
              label="学生姓名"
              prop="studentname"
              align="center"
              width="150"
            >
            </el-table-column>
            <el-table-column
              label="报读年级"
              prop="grade"
              align="center"
              width="100"
            ></el-table-column>
            <el-table-column
              label="学年"
              prop="iyear"
              align="center"
              width="100"
            ></el-table-column>
            <el-table-column
              label="学校"
              prop="collegename"
              align="center"
              width="100"
            >
            </el-table-column>
            <el-table-column
              label="专业"
              prop="majorname"
              align="center"
              width="100"
            >
            </el-table-column>
            <el-table-column
              label="费用类型"
              prop="itemname"
              align="center"
              width="100"
            >
            </el-table-column>
            <el-table-column
              label="应收"
              prop="money"
              align="center"
              width="100"
            >
            </el-table-column>
            <el-table-column
              label="减免"
              prop="extrasubmoney"
              align="center"
              width="100"
            >
            </el-table-column>
            <el-table-column
              label="优惠"
              prop="discountfee"
              align="center"
              width="100"
            >
            </el-table-column>
            <el-table-column
              label="所属教学点"
              prop="schoolorgname"
              align="center"
              width="100"
            >
            </el-table-column>
            <el-table-column label="操作" align="center" width="100">
              <template slot-scope="scope">
                <el-button @click="studentRegisterfee(scope.row.id)"
                  >收费</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="预报名" name="studentyubaoming">
          <el-table
            :data="payForm.studentyubaoming"
            style="width: 100%"
						border
						:key="key"
						stripe
						:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              label="流水号"
              prop="id"
              align="center"
              width="100"
            ></el-table-column>
            <el-table-column
              label="学生姓名"
              prop="qianzainame"
              align="center"
              width="150"
            >
            </el-table-column>

            <el-table-column
              label="年级"
              prop="year"
              align="center"
              width="100"
            ></el-table-column>
            <el-table-column
              label="应收金额"
              prop="totalmoney"
              align="center"
              width="100"
            >
            </el-table-column>
            <el-table-column
              label="实收金额"
              prop="recvmoney"
              align="center"
              width="100"
            >
            </el-table-column>
            <el-table-column
              label="退费金额"
              prop="feebackmoney"
              align="center"
              width="100"
            >
            </el-table-column>
            <el-table-column
              label="收据号"
              prop="shoujucode"
              align="center"
              width="120"
            >
            </el-table-column>
            <el-table-column
              label="所属教学点"
              prop="schoolorgname"
              align="center"
              width="130"
            >
            </el-table-column>
            <el-table-column label="操作" align="center" width="100">
              <template slot-scope="scope">
                <el-button @click="studentyubaomingFree(scope.row.id)"
                  >收费</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="购买教材" name="studentbooklist">
          <el-table
						:data="payForm.studentbooklist"
						border
						:key="key"
						stripe
						:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
						style="width: 100%">
            <el-table-column
              label="流水号"
              prop="id"
              align="center"
              width="100"
            ></el-table-column>
            <el-table-column
              label="学生姓名"
              prop="studentname"
              align="center"
              width="150"
            >
            </el-table-column>
            <el-table-column
              label="总数量"
              prop="totalquality"
              align="center"
              width="100"
            ></el-table-column>
            <el-table-column
              label="应收总金额"
              prop="totalmoney"
              align="center"
              width="100"
            ></el-table-column>
            <el-table-column
              label="实收金额"
              prop="recvmoney"
              align="center"
              width="100"
            >
            </el-table-column>
            <el-table-column
              label="优惠金额"
              prop="preferentialmoney"
              align="center"
              width="100"
            >
            </el-table-column>
            <el-table-column
              label="所属教学点"
              prop="schoolorgname"
              align="center"
              width="100"
            >
            </el-table-column>
            <el-table-column label="操作" align="center" width="100">
              <template slot-scope="scope">
                <el-button @click="purchaseFree(scope.row.id)">收费</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="学历辅导班" name="studentfudaolist">
          <el-table
						:key="key"
            :data="payForm.studentfudaolist"
            style="width: 100%"
						border
						stripe
						:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              label="流水号"
              prop="id"
              align="center"
              width="100"
            ></el-table-column>
            <el-table-column
              label="学生姓名"
              prop="studentname"
              align="center"
              width="150"
            >
            </el-table-column>
            <el-table-column
              label="年级"
              prop="grade"
              align="center"
              width="100"
            ></el-table-column>

            <el-table-column
              label="辅导班班别"
              prop="fudaoclasstypename"
              align="center"
              width="130"
            >
            </el-table-column>

            <el-table-column
              label="辅导费"
              prop="fudaofee"
              align="center"
              width="100"
            >
            </el-table-column>
            <el-table-column
              label="教材费"
              prop="bookfee"
              align="center"
              width="100"
            >
            </el-table-column>
            <el-table-column
              label="总金额"
              prop="totalmoney"
              align="center"
              width="100"
            >
            </el-table-column>

            <el-table-column
              label="优惠金额"
              prop="preferentialmoney"
              align="center"
              width="100"
            >
            </el-table-column>
            <el-table-column
              label="额外减免金额"
              prop="extrasubmoney"
              align="center"
              width="130"
            >
            </el-table-column>

            <el-table-column
              label="教学点"
              prop="schoolorgname"
              align="center"
              width="100"
            >
            </el-table-column>
            <el-table-column label="操作" align="center" width="100">
              <template slot-scope="scope">
                <el-button @click="studentfudaoFree(scope.row.id)"
                  >收费</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="技能培训" name="techfudaolist">
          <el-table
						:key="key"
            :data="payForm.techfudaolist"
            style="width: 100%"
            @selection-change="handleSelectionChange"
						border
						stripe
						:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
          >
            <el-table-column
              label="流水号"
              prop="id"
              align="center"
              width="100"
            ></el-table-column>
            <el-table-column
              label="学生姓名"
              prop="studentname"
              align="center"
              width="150"
            >
            </el-table-column>
            <el-table-column
              label="班别名称"
              prop="fudaoclasstypename"
              align="center"
              width="170"
            ></el-table-column>
            <el-table-column label="应收" align="center">
              <el-table-column
                label="培训费"
                prop="fudaofee"
                align="center"
                width="100"
              >
              </el-table-column>
              <el-table-column
                label="教材费"
                prop="bookfee"
                align="center"
                width="100"
              >
              </el-table-column>
            </el-table-column>
            <el-table-column
              label="额外减免金额"
              prop="extrasubmoney"
              align="center"
              width="120"
            >
            </el-table-column>

            <el-table-column
              label="退费金额"
              prop="feebackmoney"
              align="center"
              width="100"
            >
            </el-table-column>
            <el-table-column
              label="所属教学点"
              prop="schoolorgname"
              align="center"
              width="100"
            >
            </el-table-column>
            <el-table-column label="操作" align="center" width="100">
              <template slot-scope="scope">
                <el-button @click="techfudaoFree(scope.row.id)">收费</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>

    <!-- 学历继续教育，报读学历 收款 -->
    <el-dialog
      class="btn-2b5a95"
      width="45%"
			v-dialogDrag
			:close-on-click-modal='false'
      title="收款："
      :visible.sync="showStudentModal"
      v-if="showStudentModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="35%"
        class="demo-dynamic form-item-w-50"
        status-icon
        :rules="rules"
      >
        <el-form-item prop="companyorgname" label="所属分公司">
          <el-select
            v-model="dynamicValidateForm.companyorgname"
            placeholder="请选择"
            disabled
            clearable
            @change="onChangeCompany($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgid" label="所属教学点">
          <el-select
            v-model="dynamicValidateForm.schoolorgname"
            placeholder="请选择"
            clearable
            disabled
            @change="onChangeSchool($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="qianzainame" label="姓名:">
          <el-input v-model="dynamicValidateForm.qianzainame" disabled />
        </el-form-item>
        <el-form-item prop="shoujucode" label="收据编号:">
          <el-input v-model="dynamicValidateForm.shoujucode" disabled />
          <el-button
            type="primary"
            size="small"
            class="select-btn"
            @click="getshoujuinfo"
            >重新获取</el-button
          >
        </el-form-item>
        <el-form-item prop="bankorcash" label="银行/现金:">
          <el-input
            v-model="bankorcash[dynamicValidateForm.bankorcash]"
            disabled
          ></el-input>
          <el-button
            type="primary"
            size="small"
            class="select-btn"
            @click="
              onSelectData(account_dialogresult, 'showSelectAccountModal', {
                schoolorgid: dynamicValidateForm.schoolorgid,
              })
            "
            >选择</el-button
          >
        </el-form-item>
        <el-form-item prop="accountname" label="账户名:">
          <el-input v-model="dynamicValidateForm.accountname" disabled />
        </el-form-item>
        <el-form-item prop="cardno" label="卡号:">
          <el-input v-model="dynamicValidateForm.cardno" disabled />
        </el-form-item>
        <el-form-item prop="shouldrecvmoney" label="应收金额:">
          <el-input v-model.trim="dynamicValidateForm.shouldrecvmoney" clearable/>
        </el-form-item>

        <el-form-item prop="realrecvmoney" label="实收学位费:">
          <el-input v-model.trim="dynamicValidateForm.realrecvmoney" clearable/>
        </el-form-item>
				<el-form-item prop="discountfee" label="助学金(优惠):">
				  <el-input v-model.trim="dynamicValidateForm.discountfee" clearable/>
				</el-form-item>
        <el-form-item prop="typem2oneyintypename" label="收入二级类别:">
          <el-input
            v-model="dynamicValidateForm.typem2oneyintypename"
            disabled
          />
          <el-button
            type="primary"
            size="small"
            class="select-btn"
            @click="
              onSelectData(moneyintype2_dialogresult, 'showSelectTypeModal')
            "
            >选择</el-button
          >
        </el-form-item>
        <el-form-item
          prop="typem1oneyintypename"
          label="收入一级类别:"
          :rules="[
            {
              trigger: 'blur',
              message: '必填字段',
              required: true,
            },
          ]"
        >
          <el-input
            v-model="dynamicValidateForm.typem1oneyintypename"
            disabled
          />
        </el-form-item>
        <el-form-item prop="typemoneyintypename" label="收入类别:">
          <el-input
            v-model="dynamicValidateForm.typemoneyintypename"
            disabled
          />
        </el-form-item>
        <el-form-item prop="itemmoneyinitemname" label="收入明细:">
          <el-input
            v-model="dynamicValidateForm.itemmoneyinitemname"
            disabled
          />
          <el-button
            type="primary"
            size="small"
            class="select-btn"
            @click="
              onSelectData(moneyinitem_dialogresult, 'showSelectItemModal')
            "
            >选择</el-button
          >
        </el-form-item>

        <el-form-item prop="shoukuanrenark" label="开票说明:">
          <el-input v-model.trim="dynamicValidateForm.shoukuanrenark" clearable/>
        </el-form-item>
        <el-form-item prop="discountremarks" label="优惠说明1112:">
          <el-input v-model.trim="dynamicValidateForm.discountremarks" clearable/>
        </el-form-item>
          <el-form-item label="是否抵扣教材费" prop="isbookfee">
              <el-select v-model="dynamicValidateForm.isbookfee">
                  <el-option label="是" value="1" />
                  <el-option label="否" value="0" />
              </el-select>
          </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="
            onSubmitFormStudent(
              'dynamicValidateForm',
              student_savecollect,
              'showStudentModal'
            )
          "
          >提交
        </el-button>
      </span>
    </el-dialog>
    <!-- 代预报名 收款 -->
    <el-dialog
      class="btn-2b5a95"
      width="45%"
      title="收款："
			v-dialogDrag
			:close-on-click-modal='false'
      :visible.sync="showStudentyubaomingModal"
      v-if="showStudentyubaomingModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="30%"
        class="demo-dynamic form-item-w-50"
        status-icon
        :rules="rules"
      >
        <el-form-item prop="companyorgname" label="所属分公司">
          <el-select
            v-model="dynamicValidateForm.companyorgname"
            disabled
            clearable
            @change="onChangeCompany($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgname" label="所属教学点">
          <el-select
            v-model="dynamicValidateForm.schoolorgname"
            clearable
            disabled
            @change="onChangeSchool($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="qianzainame" label="姓名:">
          <el-input v-model="dynamicValidateForm.qianzainame" disabled />
        </el-form-item>
        <el-form-item prop="shoujucode" label="收据编号:">
          <el-input v-model="dynamicValidateForm.shoujucode" disabled />
          <el-button
            type="primary"
            size="small"
            class="select-btn"
            @click="getshoujuinfo"
            >重新获取</el-button
          >
        </el-form-item>
        <el-form-item prop="bankorcash" label="银行/现金:">
          <el-input
            v-model="bankorcash[dynamicValidateForm.bankorcash]"
            disabled
          ></el-input>
          <el-button
            type="primary"
            size="small"
            class="select-btn"
            @click="
              onSelectData(account_dialogresult, 'showSelectAccountModal', {
                schoolorgid: dynamicValidateForm.schoolorgid,
              })
            "
            >选择</el-button
          >
        </el-form-item>
        <el-form-item prop="accountname" label="账户名:">
          <el-input v-model="dynamicValidateForm.accountname" disabled />
        </el-form-item>
        <el-form-item prop="cardno" label="卡号:">
          <el-input v-model="dynamicValidateForm.cardno" disabled />
        </el-form-item>
        <el-form-item prop="totalmoney" label="应收金额:">
          <el-input v-model="dynamicValidateForm.totalmoney" clearable/>
        </el-form-item>
        <el-form-item prop="recvmoney" label="实收预报名费:">
          <el-input v-model="dynamicValidateForm.recvmoney" clearable/>
        </el-form-item>
        <el-form-item prop="typem2oneyintypename" label="收入二级类别:">
          <el-input
            v-model="dynamicValidateForm.typem2oneyintypename"
            disabled
          />
          <el-button
            type="primary"
            size="small"
            class="select-btn"
            @click="
              onSelectData(moneyintype2_dialogresult, 'showSelectTypeModal')
            "
            >选择</el-button
          >
        </el-form-item>
        <el-form-item prop="typem1oneyintypename" label="收入一级类别:">
          <el-input
            v-model="dynamicValidateForm.typem1oneyintypename"
            disabled
          />
        </el-form-item>
        <el-form-item prop="typemoneyintypename" label="收入类别:">
          <el-input
            v-model="dynamicValidateForm.typemoneyintypename"
            disabled
          />
        </el-form-item>
        <el-form-item prop="itemmoneyinitemname" label="收入明细:">
          <el-input
            v-model="dynamicValidateForm.itemmoneyinitemname"
            disabled
          />
          <el-button
            type="primary"
            size="small"
            class="select-btn"
            @click="
              onSelectData(moneyinitem_dialogresult, 'showSelectItemModal')
            "
            >选择</el-button
          >
        </el-form-item>
        <el-form-item prop="shoukuanremark" label="开票说明:">
          <el-input v-model="dynamicValidateForm.shoukuanremark" clearable/>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="
            onSubmitFormYubaoming(
              'dynamicValidateForm',
              studentyubaoming_savecollect,
              'showStudentyubaomingModal'
            )
          "
          >提交
        </el-button>
      </span>
    </el-dialog>
    <!-- 学历辅导班 收款 -->
    <el-dialog
      class="btn-2b5a95"
      width="850px"
			v-dialogDrag
			:close-on-click-modal='false'
      title="收款："
      :visible.sync="showStudentfudaoModal"
      v-if="showStudentfudaoModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="130px"
        class="demo-dynamic form-item-w-50"
        status-icon
        :rules="rules"
      >
        <el-form-item prop="companyorgname" label="所属分公司">
          <el-select
            v-model="dynamicValidateForm.companyorgname"
            placeholder="请选择"
            :disabled="!$store.state.login.isMaxAuth"
            clearable
            @change="onChangeCompany($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgname" label="所属教学点">
          <el-select
            v-model="dynamicValidateForm.schoolorgname"
            placeholder="请选择"
            clearable
            @change="onChangeSchool($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="studentname" label="姓名:">
          <el-input v-model="dynamicValidateForm.studentname" disabled />
        </el-form-item>

        <el-form-item prop="shoujucode" label="收据编号:">
          <el-input v-model="dynamicValidateForm.shoujucode" disabled />
          <el-button
            type="primary"
            size="small"
            class="select-btn"
            @click="getshoujuinfo"
            >重新获取</el-button
          >
        </el-form-item>
        <el-form-item prop="bankorcash" label="银行/现金:">
          <el-input
            v-model="bankorcash[dynamicValidateForm.bankorcash]"
            disabled
          ></el-input>

          <el-button
            type="primary"
            size="small"
            class="select-btn"
            @click="
              onSelectData(account_dialogresult, 'showSelectAccountModal', {
                schoolorgid: dynamicValidateForm.schoolorgid,
              })
            "
            >选择</el-button
          >
        </el-form-item>
        <el-form-item prop="accountname" label="账户名:">
          <el-input v-model="dynamicValidateForm.accountname" disabled />
        </el-form-item>
        <el-form-item prop="cardno" label="卡号:">
          <el-input v-model="dynamicValidateForm.cardno" disabled />
        </el-form-item>
        <el-form-item prop="totalmoney" label="应收总金额:">
          <el-input v-model="dynamicValidateForm.totalmoney" disabled />
        </el-form-item>
        <el-form-item prop="extrasubmoney" label="额外减免金额:">
          <el-input v-model="dynamicValidateForm.extrasubmoney" disabled />
        </el-form-item>
        <el-form-item prop="preferentialmoney" label="优惠金额:">
          <el-input v-model="dynamicValidateForm.preferentialmoney" disabled />
        </el-form-item>
        <el-form-item prop="fudaofee" label="应收辅导费:">
          <el-input v-model="dynamicValidateForm.fudaofee" clearable/>
        </el-form-item>
        <el-form-item prop="recvfudaofee" label="实收辅导费:">
          <el-input v-model="dynamicValidateForm.recvfudaofee" clearable/>
        </el-form-item>
        <el-form-item prop="bookfee" label="应收教材费:">
          <el-input v-model="dynamicValidateForm.bookfee" clearable/>
        </el-form-item>
        <el-form-item prop="recvbookfee" label="实收教材费:">
          <el-input v-model="dynamicValidateForm.recvbookfee" clearable/>
        </el-form-item>
        <el-form-item prop="itemname1" label="辅导费收入明细:">
          <el-input v-model="dynamicValidateForm.itemname1" disabled />
          <el-button
            type="primary"
            size="small"
            class="select-btn"
            @click="
              onSelectData(moneyinitem_dialogresult, 'showSelectItem1Modal')
            "
            >选择</el-button
          >
        </el-form-item>
        <el-form-item prop="itemmoneyinitemname" label="教材费收入明细:">
          <el-input
            v-model="dynamicValidateForm.itemmoneyinitemname"
            disabled
          />
          <el-button
            type="primary"
            size="small"
            class="select-btn"
            @click="
              onSelectData(moneyinitem_dialogresult, 'showSelectItemModal')
            "
            >选择</el-button
          >
        </el-form-item>
        <el-form-item prop="typem2oneyintypename" label="收入二级类别:">
          <el-input
            v-model="dynamicValidateForm.typem2oneyintypename"
            disabled
          />
          <el-button
            type="primary"
            size="small"
            class="select-btn"
            @click="
              onSelectData(moneyintype2_dialogresult, 'showSelectTypeModal')
            "
            >选择</el-button
          >
        </el-form-item>
        <el-form-item prop="typem1oneyintypename" label="收入一级类别:">
          <el-input
            v-model="dynamicValidateForm.typem1oneyintypename"
            disabled
          />
        </el-form-item>
        <el-form-item prop="typemoneyintypename" label="收入类别:">
          <el-input
            v-model="dynamicValidateForm.typemoneyintypename"
            disabled
          />
        </el-form-item>
        <el-form-item prop="shoukuanremark" label="开票说明:">
          <el-input v-model="dynamicValidateForm.shoukuanremark" clearable/>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="
           	onSubmitFudao(
              'dynamicValidateForm',
              studentfudao_saveenrol,
              'showStudentfudaoModal'
            )
          "
          >提交
        </el-button>
      </span>
    </el-dialog>
    <!-- 技能培训 - 教师资格 收款 -->
    <el-dialog
      class="btn-2b5a95"
      width="50%"
      title="收款："
			v-dialogDrag
			:close-on-click-modal='false'
      :visible.sync="showTechfudaoModal"
      v-if="showTechfudaoModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="35%"
        class="demo-dynamic form-item-w-50"
        status-icon
        :rules="rules"
      >
        <el-form-item prop="companyorgname" label="所属分公司">
          <el-select
            v-model="dynamicValidateForm.companyorgname"
            disabled
            placeholder="请选择"
            clearable
            @change="onChangeCompany($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgname" label="所属教学点">
          <el-select
            v-model="dynamicValidateForm.schoolorgname"
            placeholder="请选择"
            clearable
            disabled
            @change="onChangeSchool($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="studentname" label="姓名:">
          <el-input v-model="dynamicValidateForm.studentname" disabled />
        </el-form-item>
        <el-form-item prop="shoujucode" label="收据编号:">
          <el-input v-model="dynamicValidateForm.shoujucode" disabled />
          <el-button
            type="primary"
            size="small"
            class="select-btn"
            @click="getshoujuinfo"
            >重新获取</el-button
          >
        </el-form-item>
        <el-form-item prop="fudaofee" label="应收培训费:">
          <el-input v-model="dynamicValidateForm.fudaofee" disabled />
        </el-form-item>
        <el-form-item prop="recvfudaofee" label="实收培训费:">
          <el-input v-model="dynamicValidateForm.recvfudaofee" clearable/>
        </el-form-item>
        <el-form-item prop="bookfee" label="应收教材费:">
          <el-input v-model="dynamicValidateForm.bookfee" disabled />
        </el-form-item>
        <el-form-item prop="recvbookfee" label="实收教材费:">
          <el-input v-model="dynamicValidateForm.recvbookfee" clearable/>
        </el-form-item>
        <el-form-item prop="totalmoney" label="应收总金额:">
          <el-input v-model="dynamicValidateForm.totalmoney" disabled />
        </el-form-item>
        <el-form-item prop="preferentialmoney" label="优惠金额:">
          <el-input v-model="dynamicValidateForm.preferentialmoney" disabled />
        </el-form-item>
        <el-form-item prop="extrasubmoney" label="额外减免金额:">
          <el-input v-model="dynamicValidateForm.extrasubmoney" clearable/>
        </el-form-item>
        <el-form-item prop="itemname1" label="培训费收入明细:">
          <el-input v-model="dynamicValidateForm.itemname1" disabled />
          <el-button
            type="primary"
            size="small"
            class="select-btn"
            @click="
              onSelectData(moneyinitem_dialogresult, 'showSelectItem1Modal')
            "
            >选择</el-button
          >
        </el-form-item>
        <el-form-item prop="itemmoneyinitemname" label="教材费收入明细:">
          <el-input
            v-model="dynamicValidateForm.itemmoneyinitemname"
            disabled
          />
          <el-button
            type="primary"
            size="small"
            class="select-btn"
            @click="
              onSelectData(moneyinitem_dialogresult, 'showSelectItemModal')
            "
            >选择</el-button
          >
        </el-form-item>

        <el-form-item prop="bankorcash" label="银行/现金:">
          <el-input
            v-model="bankorcash[dynamicValidateForm.bankorcash]"
            disabled
          ></el-input>
          <el-button
            type="primary"
            size="small"
            class="select-btn"
            @click="
              onSelectData(account_dialogresult, 'showSelectAccountModal', {
                schoolorgid: dynamicValidateForm.schoolorgid,
              })
            "
            >选择</el-button
          >
        </el-form-item>
        <el-form-item prop="accountname" label="账户名:">
          <el-input v-model="dynamicValidateForm.accountname" disabled />
        </el-form-item>
        <el-form-item label="卡号:">
          <el-input v-model="dynamicValidateForm.cardno" disabled />
        </el-form-item>
        <el-form-item prop="typem2oneyintypename" label="收入二级类别名称:">
          <el-input
            v-model="dynamicValidateForm.typem2oneyintypename"
            disabled
          />
          <el-button
            type="primary"
            size="small"
            class="select-btn"
            @click="
              onSelectData(moneyintype2_dialogresult, 'showSelectTypeModal')
            "
            >选择</el-button
          >
        </el-form-item>
        <el-form-item prop="typem1oneyintypename" label="收入一级类别名称:">
          <el-input
            v-model="dynamicValidateForm.typem1oneyintypename"
            disabled
          />
        </el-form-item>
        <el-form-item prop="typemoneyintypename" label="收入类别名称:">
          <el-input
            v-model="dynamicValidateForm.typemoneyintypename"
            disabled
          />
        </el-form-item>
        <el-form-item prop="shoukuanremark" label="开票说明:">
          <el-input v-model="dynamicValidateForm.shoukuanremark" clearable/>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="
            onSubmitFormTechfudao(
              'dynamicValidateForm',
              techfudao_saveenrol,
              'showTechfudaoModal'
            )
          "
          >提交
        </el-button>
      </span>
    </el-dialog>
    <!-- 购买教材 收款-->
    <el-dialog
      class="btn-2b5a95"
      width="45%"
			v-dialogDrag
      title="收款："
			:close-on-click-modal='false'
      :visible.sync="showPurchaseModal"
      v-if="showPurchaseModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="30%"
        class="demo-dynamic form-item-w-50"
        status-icon
        :rules="rules"
      >
        <el-form-item prop="companyorgname" label="所属分公司">
          <el-select
            v-model="dynamicValidateForm.companyorgname"
            disabled
            clearable
            @change="onChangeCompany($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgname" label="所属教学点">
          <el-select
            v-model="dynamicValidateForm.schoolorgname"
            disabled
            clearable
            @change="onChangeSchool($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="totalquality" label="总数量:">
          <el-input v-model="dynamicValidateForm.totalquality" disabled />
        </el-form-item>
        <el-form-item prop="totalmoney" label="应收总金额:">
          <el-input v-model="dynamicValidateForm.totalmoney" disabled />
        </el-form-item>
        <el-form-item prop="preferentialmoney" label="优惠金额:">
          <el-input v-model="dynamicValidateForm.preferentialmoney" clearable/>
        </el-form-item>
        <el-form-item prop="recvmoney" label="实收金额:">
          <el-input v-model="dynamicValidateForm.recvmoney" clearable/>
        </el-form-item>
        <el-form-item prop="shoujucode" label="收据编号:">
          <el-input v-model="dynamicValidateForm.shoujucode" disabled />
          <el-button
            type="primary"
            size="small"
            class="select-btn"
            @click="getshoujuinfo"
            >重新获取</el-button
          >
        </el-form-item>
        <el-form-item prop="bankorcash" label="银行/现金:">
          <el-select
            v-model="bankorcash[dynamicValidateForm.bankorcash]"
            placeholder="请选择"
            clearable
            disabled
          >
            <el-option
              v-for="item in bankorcash"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-button
            type="primary"
            size="small"
            class="select-btn"
            @click="
              onSelectData(account_dialogresult, 'showSelectAccountModal', {
                schoolorgid: dynamicValidateForm.schoolorgid,
              })
            "
            >选择</el-button
          >
        </el-form-item>
        <el-form-item prop="accountname" label="账户名:">
          <el-input v-model="dynamicValidateForm.accountname" disabled />
        </el-form-item>
        <el-form-item prop="cardno" label="卡号:">
          <el-input v-model="dynamicValidateForm.cardno" disabled />
        </el-form-item>
        <el-form-item prop="typem2oneyintypename" label="收入二级类别:">
          <el-input
            v-model="dynamicValidateForm.typem2oneyintypename"
            disabled
          />
          <el-button
            type="primary"
            size="small"
            class="select-btn"
            @click="
              onSelectData(moneyintype2_dialogresult, 'showSelectTypeModal')
            "
            >选择</el-button
          >
        </el-form-item>
        <el-form-item
          prop="typem1oneyintypename"
          label="收入一级类别:"
          :rules="[
            {
              trigger: 'blur',
              message: '必填字段',
              required: true,
            },
          ]"
        >
          <el-input
            v-model="dynamicValidateForm.typem1oneyintypename"
            disabled
          />
        </el-form-item>
        <el-form-item prop="typemoneyintypename" label="收入类别:">
          <el-input
            v-model="dynamicValidateForm.typemoneyintypename"
            disabled
          />
        </el-form-item>
        <el-form-item prop="itemmoneyinitemname" label="收入明细:">
          <el-input
            v-model="dynamicValidateForm.itemmoneyinitemname"
            disabled
          />
          <el-button
            type="primary"
            size="small"
            class="select-btn"
            @click="
              onSelectData(moneyinitem_dialogresult, 'showSelectItemModal')
            "
            >选择</el-button
          >
        </el-form-item>
        <el-form-item prop="shoukuanremark" label="开票说明:">
          <el-input v-model="dynamicValidateForm.shoukuanremark" clearable/>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="
            onSubmitForm(
              'dynamicValidateForm',
              studentbook_savebuy,
              'showPurchaseModal'
            )
          "
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 选择账户 -->
    <el-dialog
      class="btn-2b5a95"
      width="1200px"
			v-dialogDrag
      @close="onCloseSelect"
      title="选择账户："
			:close-on-click-modal='false'
      :visible.sync="showSelectAccountModal"
      v-if="showSelectAccountModal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmAccount">确定</el-button>

      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="开户名称:" prop="accountname">
              <el-input
								v-model.trim="selectSearchForm.accountname"
								@keyup.enter.native="onSelectSearchClick(selectTableUrl, null, selectSearchForm)"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="onSelectSearchClick(selectTableUrl, null, selectSearchForm)"
            >搜索</el-button
          >
					<el-button type="primary" @clik="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>

      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				:key="key"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          label="流水号"
          prop="id"
          align="center"
          width="200px"
        />
        <el-table-column
          label="开户名称"
          prop="accountname"
          align="center"
          width="250px"
        />
        <el-table-column label="现金/银行" align="center" width="100px">
          <template slot-scope="scope">
            {{ bankorcash[scope.row.bankorcash] }}
          </template>
        </el-table-column>
        <el-table-column
          label="卡号"
          prop="cardno"
          align="center"
          width="200px"
        />
        <el-table-column
          label="所属教学点"
          prop="schoolorgname"
          align="center"
          width="200px"
        />
      </el-table>

      <el-pagination
        background
        @current-change="
          (val, data, prop, page) =>
            handleSelectChange(
              val,
              Object.assign(
                {
                  schoolorgid: dynamicValidateForm.schoolorgid,
                },
                selectSearchForm
              )
            )
        "
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>
    <!-- 选择跟进人 -->
    <el-dialog
      class="btn-2b5a95"
      width="50%"
			v-dialogDrag
      @close="onHandleCloseSelect"
      title="选择跟进人："
			:close-on-click-modal='false'
      :visible.sync="showFollowDataModal"
      v-if="showFollowDataModal"
      append-to-body
    >
      <el-button type="primary" class="confirm" @click="followDataComfirm"
        >确 定</el-button
      >
      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="真实姓名:" prop="realname">
              <el-input
								v-model.trim="selectSearchForm.realname"
								@keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign(
                  {
                    schoolorgid: dynamicValidateForm.schoolorgid,
                    leaveflag: 0,
                  },
                  selectSearchForm
                )
              )"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign(
                  {
                    schoolorgid: dynamicValidateForm.schoolorgid,
                    leaveflag: 0,
                  },
                  selectSearchForm
                )
              )
            "
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>
      <!-- 表格 -->
      <el-table
        :data="selectTable.list"
        style="width: 100%"
				border
				:key="key"
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @select="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="姓名" prop="realname" align="center" />
        <el-table-column label="岗位" prop="positionname" align="center" />
        <el-table-column
          label="所属教学点"
          prop="schoolorgname"
          align="center"
        />
      </el-table>
      <!-- 分页 -->
      <el-pagination
        background
        @current-change="
          (val, data, prop, page) =>
            handleSelectChange(
              val,
              Object.assign(
                {
                  schoolorgid: dynamicValidateForm.schoolorgid,
                  leaveflag: 0,
                },
                selectSearchForm
              )
            )
        "
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>
    <!-- 选择收入二级类别 -->
    <el-dialog
      class="btn-2b5a95"
      width="50%"
			v-dialogDrag
      @close="onCloseSelect"
			:close-on-click-modal='false'
      title="选择收入二级类别："
      :visible.sync="showSelectTypeModal"
      v-if="showSelectTypeModal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmType">确定</el-button>
      <el-form label-width="50px"
				:model="selectSearchForm"
				@submit.native.prevent>
        <el-input
          v-model.trim="selectSearchForm.name"
          placeholder="收入二级项目"
					@keyup.enter.native="
					onSelectData(
					  selectTableUrl,
					  null,
					  Object.assign({}, selectSearchForm)
					)
					"
          clearable
        />
        <el-input
          v-model.trim="selectSearchForm.type1name"
          placeholder="收入一级项目"
					@keyup.enter.native="
					onSelectData(
					  selectTableUrl,
					  null,
					  Object.assign({}, selectSearchForm)
					)
					"
          clearable
        />
        <el-input
          v-model.trim="selectSearchForm.typename"
					@keyup.enter.native="
					onSelectData(
					  selectTableUrl,
					  null,
					  Object.assign({}, selectSearchForm)
					)
					"
          placeholder="收入类别"
          clearable
        />
        <el-button
          type="primary"
          icon="el-icon-search"
          class="seachBtn"
          @click="
            onSelectData(
              selectTableUrl,
              null,
              Object.assign({}, selectSearchForm)
            )
          "
          >搜索</el-button
        >
				<el-button type="primary" @click="handleQuery">重置</el-button>
      </el-form>
      <el-table
        :data="selectTable.list"
        style="width: 100%"
				border
				stripe
				:key="key"
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
          label="流水号"
          prop="id"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          label="收入类别"
          prop="typename"
          align="center"
          width="150"
        ></el-table-column>
        <el-table-column
          label="收入一级项目"
          prop="type1name"
          align="center"
          width="150"
        ></el-table-column>
        <el-table-column
          label="收入二级项目"
          prop="name"
          align="center"
          width="150"
        ></el-table-column>
        <el-table-column
          label="关联院校"
          prop="collegename"
          align="center"
          width="150"
        ></el-table-column>
      </el-table>
      <el-pagination
        background
        @current-change="
          (val, data, prop, page) =>
            handleSelectChange(
              val,
              Object.assign(
                {
                  schoolorgid: dynamicValidateForm.schoolorgid,
                  leaveflag: 1,
                },
                selectSearchForm
              )
            )
        "
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>
    <!--选择收入明细 || 教材费-->
    <el-dialog
      class="btn-2b5a95"
      width="50%"
			v-dialogDrag
			:close-on-click-modal='false'
      @close="onCloseSelect"
      title="选择收入明细："
      :visible.sync="showSelectItemModal"
      v-if="showSelectItemModal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmMoneyinitem">确定</el-button>
      <el-form
				label-width="50px"
				:model="selectSearchForm"
				@submit.native.prevent
				>
        <el-input
          v-model.trim="selectSearchForm.name"
          placeholder="名称"
					@keyup.enter.native="
						onSelectData(
						  selectTableUrl,
						  null,
						  Object.assign({}, selectSearchForm)
						)
					"
          clearable
        />
        <el-button
          type="primary"
          icon="el-icon-search"
          class="seachBtn"
          @click="
            onSelectData(
              selectTableUrl,
              null,
              Object.assign({}, selectSearchForm)
            )
          "
          >搜索</el-button
        >
				<el-button type="primary" @click="handleQuery">重置</el-button>
      </el-form>
      <el-table
        :data="selectTable.list"
        style="width: 100%"
				border
				:key="key"
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
          label="流水号"
          prop="id"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          label="名称"
          prop="name"
          align="center"
          width="100"
        ></el-table-column>
      </el-table>
      <el-pagination
        background
        @current-change="
          (val, data) =>
            handleSelectChange(val, Object.assign({}, selectSearchForm))
        "
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>
    <!-- 选择收入明细 - 辅导费 -->
    <el-dialog
      class="btn-2b5a95"
      width="50%"
			v-dialogDrag
			:close-on-click-modal='false'
      @close="onCloseSelect"
      title="选择收入明细项："
      :visible.sync="showSelectItem1Modal"
      v-if="showSelectItem1Modal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmMoneyinitem1">确定</el-button>
      <el-form label-width="50px" :model="selectSearchForm">
        <el-input
          v-model="selectSearchForm.name"
          placeholder="名称"
          clearable
        />
        <el-button
          type="primary"
          icon="el-icon-search"
          class="seachBtn"
          @click="
            onSelectData(
              selectTableUrl,
              null,
              Object.assign({}, selectSearchForm)
            )
          "
          >搜索</el-button
        >
      </el-form>
      <el-table
        :data="selectTable.list"
        style="width: 100%"
        @selection-change="handleSelectionChange"
				border
				:key="key"
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
          label="流水号"
          prop="id"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          label="名称"
          prop="name"
          align="center"
          width="100"
        ></el-table-column>
      </el-table>
      <el-pagination
        background
        @current-change="
          (val, data) =>
            handleSelectChange(val, Object.assign({}, selectSearchForm))
        "
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>
    <!-- 选择技能数据 -->
    <el-dialog
      class="btn-2b5a95"
      @close="onCloseSelect"
			v-dialogDrag
			:close-on-click-modal='false'
      title="选择技能数据"
      :visible.sync="skillDataModal"
      v-if="skillDataModal"
      width="66%"
      append-to-body
    >
      <el-button type="primary" class="confirm" @click="skillDataComfirm"
        >确 定</el-button
      >

      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="班别名称:" prop="fudaoclasstypename">
              <el-input
                v-model.trim="selectSearchForm.fudaoclasstypename"
								@keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign(
                  { schoolorgid: dynamicValidateForm.schoolorgid },
                  selectSearchForm
                )
              )"
                clearable
              />
            </el-form-item>
            <el-form-item label="班别类型:" prop="fudaotypeval">
              <el-input
								v-model.trim="selectSearchForm.fudaotypeval"
								@keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign(
								    { schoolorgid: dynamicValidateForm.schoolorgid },
								    selectSearchForm
								  )
								)"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign(
                  { schoolorgid: dynamicValidateForm.schoolorgid,
                           projecttypeval: '技能培训'
                  },
                  selectSearchForm
                )
              )
            "
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>

      <el-table
        :data="selectTable.stufudaofeestandardlist"
        style="width: 100%"
        @select="handleSelectionChange"
				border
				:key="key"
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column
          label="班别名称"
          prop="fudaoclasstypename"
          width="147"
          align="center"
        />
        <el-table-column
          label="班别类型"
          prop="fudaotypeval"
          align="center"
        />
        <el-table-column
          label="项目类型"
          prop="projecttypeval"
          align="center"
        />
        <el-table-column label="辅导费" prop="fudaofee" align="center" />
        <el-table-column label="教材费" prop="bookfee" align="center" />
        <el-table-column label="审核标识" align="center">
          <template slot-scope="scope">
            {{ scope.row.approveflag == 1 ? "是" : "否" }}
          </template>
        </el-table-column>
        <el-table-column
          label="所属教学点"
          prop="schoolorgname"
          align="center"
        />
      </el-table>
      <el-pagination
        background
        :current-page="selectPageInfo.pageindex"
        :current-size="selectPageInfo.pagesize"
        layout="total, prev, pager, next, jumper"
        :total="
          selectTable.paginationutil ? selectTable.paginationutil.totalcount : 0
        "
        @current-change="
          (val, data, prop, page) =>
            handleSelectChange(
              val,
              Object.assign(
                {
                  schoolorgid: dynamicValidateForm.schoolorgid,
                  projecttypeval: '技能培训',
                },
                selectSearchForm
              )
            )
        "
      />
    </el-dialog>
    <!-- 选择辅导班别 -->
    <el-dialog
      class="btn-2b5a95"
      @close="onCloseSelect"
			:close-on-click-modal='false'
      title="选择辅导班别"
			v-dialogDrag
      :visible.sync="reportClassDataModal"
      v-if="reportClassDataModal"
      width="65%"
      append-to-body
    >
      <el-button type="primary" @click="reportClassDataComfirm"
        >确 定</el-button
      >

      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="辅导班班别名称:" prop="fudaoclasstypename">
              <el-input
                v-model.trim="selectSearchForm.fudaoclasstypename"
								@keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign(
                  { schoolorgid: dynamicValidateForm.schoolorgid },
                  selectSearchForm
                )
              )"
                clearable
              />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign(
                  { schoolorgid: dynamicValidateForm.schoolorgid },
                  selectSearchForm
                )
              )
            "
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>

      <el-table
        :data="selectTable.stufudaofeestandardlist"
        style="width: 100%"
				border
				:key="key"
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @select="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" width="100px"/>
        <el-table-column
          label="辅导班班别名称"
          prop="fudaoclasstypename"
          width="250px"
          align="center"
        />
        <el-table-column
          label="辅导班类型"
          prop="fudaotypeval"
          align="center"
        />
        <el-table-column
          label="项目类型"
          prop="projecttypeval"
          align="center"
        />
        <el-table-column label="辅导费" prop="fudaofee" align="center" />
        <el-table-column label="教材费" prop="bookfee" align="center" />
        <el-table-column label="审核标识" align="center">
          <template slot-scope="scope">
            {{ scope.row.approveflag == 1 ? "是" : "否" }}
          </template>
        </el-table-column>
        <el-table-column
          label="所属教学点"
          prop="schoolorgname"
          align="center"
					width="130px"
        />
      </el-table>
      <el-pagination
        background
        :current-page="selectPageInfo.pageindex"
        :current-size="selectPageInfo.pagesize"
        layout="total, prev, pager, next, jumper"
        :total="
          selectTable.paginationutil ? selectTable.paginationutil.totalcount : 0
        "
        @current-change="
          (val, data, prop, page) =>
            handleSelectChange(
              val,
              Object.assign(
                {
                  schoolorgid: dynamicValidateForm.schoolorgid,
                  projecttypeval: '成人高考',
                },
                selectSearchForm
              )
            )
        "
      />
      <div slot="footer" class="dialog-footer"></div>
    </el-dialog>
    <!-- 报读学历-选择院校专业 -->
    <el-dialog
      class="btn-2b5a95"
      @close="onCloseSelect"
      title="选择院校专业"
			v-dialogDrag
			:close-on-click-modal='false'
      :visible.sync="showCollegemajorModal"
      v-if="showCollegemajorModal"
      width="1200px"
      append-to-body
    >
      <el-button type="primary" class="confirm" @click="reportDataComfirm"
        >确 定</el-button
      >
      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="院校:" prop="collegename">
              <el-input
								v-model.trim="selectSearchForm.collegename"
								@keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign(
                  {
                    schoolorgid: dynamicValidateForm.schoolorgid,
                    grade: dynamicValidateForm.grade,
                    majorlevelval: dynamicValidateForm.levelval,
                    projectname: dynamicValidateForm.projecttype,
                  	studytypeval: dynamicValidateForm.studytypekey,
                  },
                  selectSearchForm
                )
              )"
								clearable />
            </el-form-item>
            <el-form-item label="专业:" prop="collegemajorname">
              <el-input
								v-model.trim="selectSearchForm.collegemajorname"
								@keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign(
								    {
								      schoolorgid: dynamicValidateForm.schoolorgid,
								      grade: dynamicValidateForm.grade,
								      majorlevelval: dynamicValidateForm.levelval,
								      projectname: dynamicValidateForm.projecttype,
								    	studytypeval: dynamicValidateForm.studytypekey,
								    },
								    selectSearchForm
								  )
								)"
								clearable />
            </el-form-item>
            <el-form-item label="专业层次:" prop="majorlevelval">
              <el-input
								v-model.trim="selectSearchForm.majorlevelval"
								@keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign(
								    {
								      schoolorgid: dynamicValidateForm.schoolorgid,
								      grade: dynamicValidateForm.grade,
								      majorlevelval: dynamicValidateForm.levelval,
								      projectname: dynamicValidateForm.projecttype,
								    	studytypeval: dynamicValidateForm.studytypekey,
								    },
								    selectSearchForm
								  )
								)"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign(
                  {
                    schoolorgid: dynamicValidateForm.schoolorgid,
                    grade: dynamicValidateForm.grade,
                    majorlevelval: dynamicValidateForm.levelval,
                    projectname: dynamicValidateForm.projecttype,
                  	studytypeval: dynamicValidateForm.studytypekey,
                  },
                  selectSearchForm
                )
              )
            "
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>

      <el-table
        :data="selectTable.list"
        style="width: 100%"
        @select="handleSelectionChange"
				border
				stripe
				:key="key"
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="年级" prop="grade" align="center" />
        <el-table-column
          label="院校"
          prop="collegename"
          align="center"
          width="180"
        />
        <el-table-column label="专业" prop="collegemajorname" align="center" />
        <el-table-column label="层次" prop="majorlevelval" align="center" />
        <el-table-column
          label="所属教学点"
          prop="schoolorgname"
          align="center"
        />
      </el-table>
      <el-pagination
        background
        :current-page="selectPageInfo.pageindex"
        :current-size="selectPageInfo.pagesize"
        layout="total, prev, pager, next, jumper"
        :total="
          selectTable.paginationutil ? selectTable.paginationutil.totalcount : 0
        "
        @current-change="
          (val, data, prop, page) =>
            handleSelectChange(
              val,
              Object.assign(
                {
                  schoolorgid: dynamicValidateForm.schoolorgid,
                  grade: dynamicValidateForm.grade,
                  majorlevelval: dynamicValidateForm.levelval,
                  projectname: dynamicValidateForm.projecttype,
                	studytypeval: dynamicValidateForm.studytypekey,
                },
                selectSearchForm
              )
            )
        "
      />
    </el-dialog>
    <!-- 报读学历-选择校外教学点 -->
    <el-dialog
      class="btn-2b5a95"
      @close="onCloseSelect"
			:close-on-click-modal='false'
      title="选择校外教学点"
			v-dialogDrag
      :visible.sync="showCollegecoporg"
      v-if="showCollegecoporg"
      width="1200px"
      append-to-body
    >
      <el-button type="primary" class="confirm" @click="onComfirmCollegecoporg"
        >确 定</el-button
      >
      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="函授站名称:" prop="schoolorgname">
              <el-input
								v-model.trim="selectSearchForm.schoolorgname"
									@keyup.enter.native="onSelectSearchClick(
									  selectTableUrl,
										null,
									  Object.assign(
									    {
									      collegename: dynamicValidateForm.collegename1,
									    },
									    selectSearchForm
									  )
									)"
								 clearable />
            </el-form-item>
            <el-form-item label="上课地点:" prop="classname">
              <el-input
								v-model.trim="selectSearchForm.classname"
								@keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
								null,
                Object.assign(
                  {
                    collegename: dynamicValidateForm.collegename1,
                  },
                  selectSearchForm
                )
              )"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="
              onSelectSearchClick(
                selectTableUrl,
                Object.assign(
                  {
                    collegename: dynamicValidateForm.collegename1,
                  },
                  selectSearchForm
                )
              )
            "
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>
      <el-table
        :data="selectTable.list"
        ref="multipleTable"
        style="width: 100%"
				border
				:key="key"
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="院校名称" prop="collegename" align="center" />
        <el-table-column label="函授站名称" prop="schoolorgname" align="center" />
        <el-table-column label="上课点名称" prop="classname" align="center" />
      </el-table>
      <el-pagination
        background
        :current-page="selectPageInfo.pageindex"
        :current-size="selectPageInfo.pagesize"
        layout="total, prev, pager, next, jumper"
        :total="
					selectTable.totalcount
        "
        @current-change="
          (val, data, prop, page) =>
            handleSelectChange(
              val,
              Object.assign(
                {
                  collegename: dynamicValidateForm.collegename1,
                },
                selectSearchForm
              )
            )
        "
      /></el-pagination>
    </el-dialog>
		<el-dialog
		  class="btn-2b5a95"
		  :title="dialogTitle"
			v-dialogDrag
			:close-on-click-modal='false'
		  :visible.sync="showDetailList"
		  v-if="showDetailList"
		  append-to-body
		  width="80%"
		  :fullscreen="$store.state.global.isFullscreen"
		>
		  <span slot="title" class="el-dialog__title">
		    <span>基本信息：</span>
		    <span
		      :class="
		        $store.state.global.isFullscreen
		          ? 'el-icon-copy-document'
		          : 'el-icon-full-screen'
		      "
		      @click="isExpand($store.state.global.isFullscreen)"
		    ></span>
		  </span>
			<div class="abase">
				<div class="top">
						基本信息
				</div>
				<el-form
					:label-position="labelPosition"
					label-width="110px"
					:model="dynamicValidateForm"
					ref="dynamicValidateForm"
					:rules="rules">
					<table class="tlist" style="width: 100%;" cellpadding="5px;">
						<tr>
							<td colspan="2">
								<div class="rig heigsuperrig">
									<div>
										<el-form-item prop="name" label="姓名:">
											<el-input v-model.trim="dynamicValidateForm.name" clearable/>
										</el-form-item>
									</div>
									<div>
									</div>
								</div>
							</td>
							<td colspan="4">
								<div class="rig heigsuper">
									<el-form-item prop="sexkey" label="性别:" class="sex">
										<el-radio-group v-model="dynamicValidateForm.sexkey">
										  <el-radio label="1">男</el-radio>
										  <el-radio label="2">女</el-radio>
										</el-radio-group>
									</el-form-item>
								</div>
							</td>
						</tr>
						<tr>
							<td colspan="2">
								<div class="rig flex-xl heigsuper">
									<div>
										<el-form-item label="密码:">
											<el-input v-model.trim="dynamicValidateForm.pwd" clearable />
										</el-form-item>
									</div>
									<div class="title-font">(8位数字和字母)</div>
								</div>
							</td>
							<td colspan="4">
								<div class="heigsuperrig">
									<el-form-item label="民族:">
										<el-select
										v-model="dynamicValidateForm.nationkey"
										clearable
										>
										  <el-option
										    v-for="(item, index) in nationList"
										    :key="index"
										    :label="item.val"
										    :value="item.key"
												clearable
										  />
										</el-select>
									</el-form-item>
								</div>
							</td>
						</tr>
						<tr>
							<td colspan="2">
								<div class="rig flex-xl heigsuper brithday--ww">
									<div>
										<el-form-item label="出生日期:" style="width: 250px;">
										  <el-date-picker
										    type="date"
										    format="yyyy-MM-dd"
										    value-format="yyyy-MM-dd 04:00:00"
										    v-model="dynamicValidateForm.birthday"
										    placeholder="请选择"
												clearable
										  />
										</el-form-item>
									</div>
									<div class="title-font">
										(格式:1990-01-01)
									</div>
								</div>
							</td>
							<td colspan="4">
								<div class="heigsuper" style="margin-top: -26px;">
								<el-form-item label="政治面貌:" >
									<el-select
									v-model="dynamicValidateForm.polikey"
									clearable
									>
									  <el-option
									    v-for="item in policyList"
									    :key="item.key"
									    :value="item.key"
									    :label="item.val"
											clearable
									  />
									</el-select>
								</el-form-item>
								</div>
							</td>
						</tr>
						<tr>
							<td colspan="3">
								<div style="height: 55px;">
									<el-form-item label="考试语种:">
										<el-select v-model="dynamicValidateForm.languagecode"
											@change="changeCode"
											clearable
										 >
										  <el-option
										    v-for="(code,index) in languageTypeList"
										    :key="index"
										    :value="code"
										    :label="code.val"
												clearable
										  />
										</el-select>
										<font color="red">(专升本考生只能选报英语)</font>
									</el-form-item>
								</div>
							</td>
						</tr>
						<tr>
							<td colspan="2">
								<div class="flex-xl heigsuper">
									<div>
										<el-form-item label="考试类型：">
											<el-select
												v-model="dynamicValidateForm.examinationType"
												@change="changexamineeCategory"
												clearable
												>
											  <el-option
											    v-for="item in examtype2List"
											    :key="item.key"
											    :value="item"
											    :label="item.val"
											  />
											</el-select>
										</el-form-item>
									</div>
									<div class="title-font">
											<font color="red">(免试生请选择免试入学)</font>
									</div>
								</div>
							</td>
							<td class="zgjf "
								v-show="dynamicValidateForm.examinationType =='免试入学'"
								colspan="2">
								<div class="flex-xl heigsuper" style="visibility: hidden;">
									<div>
										<el-form-item prop="points" label="照顾加分：">
											<el-checkbox v-model="dynamicValidateForm.points" true-label="1" false-label="0">
											  是
											</el-checkbox>
										</el-form-item>
									</div>
									<div class="title-font">
										<font color="red">(山区县和25岁不用勾选)</font>
									</div>
								</div>
							</td>
							<td class="zgjf "
								v-show="dynamicValidateForm.examinationType !=='免试入学'"
								colspan="2"
							>
								<div class="flex-xl heigsuper">
									<div>
										<el-form-item prop="points" label="照顾加分：">
											<el-checkbox v-model="dynamicValidateForm.points" true-label="1" false-label="0" style="width: 100%;">
											  是
											</el-checkbox>
										</el-form-item>
									</div>
									<div class="title-font">
										<font color="red">(山区县和25岁不用勾选)</font>
									</div>
								</div>
							</td>
						</tr>
						<tr>
							<td width="300">
								<div class="rig heigsuperrig">
									<el-form-item prop="levelval" label="考生类别：">
										<el-select
											v-model="dynamicValidateForm.levelval"
											clearable
											@change="changeSelect"

											>
											<el-option
												v-for="(item, index) in arr_province"
												:key="index"
												:label="item"
												:value="item"
												clearable
											/>
										</el-select>
									</el-form-item>

									</select>
								</div>
							</td>
							<td class="kelei_pro" colspan="3">
								<div class="rig flex-xl heigsuper">
									<div>
										<el-form-item prop="registeredSubjects" label="报考科类：">
											<el-select
												v-model="dynamicValidateForm.registeredsubjects"
												@change="registereChange"
												clearable
											>
												<el-option
													v-for="(obj, index) in baokaokeleiList"
													:key="index"
													:label="obj.val"
													:value="obj"
													clearable
												/>
											</el-select>
										</el-form-item>
									</div>
									<div class="title-font">
											<font color="red">(报考专升本的外语类专业请选择"文史类")</font>
									</div>
								</div>
							</td>
						</tr>
						<tr>
							<td colspan="3">
								<div class="rig ke-w" style="height: 53px;margin-top: -14px;">
									<div>
										<el-form-item prop="course" label="考试科目组：">
											<el-select v-model="dynamicValidateForm.course" clearable>
												<el-option
													v-for="(item, index) in typeOptions"
													:key="index"
													:label="item"
													:value="item"
													clearable
												/>
											</el-select>
										</el-form-item>
									</div>
									<div>
									</div>
								</div>
							</td>
						</tr>
						<tr>
							<td style="height: 38px;">
								<div class="rig ex-col">
									<div>
										<el-form-item label=" 考试县区：">
											<el-select
												v-model="dynamicValidateForm.examination"
												clearable
											 >
												<el-option
													v-for="(areaflag, index) in areaflags"
													:key="index"
													:label="areaflag.label"
													:value="areaflag.label"
													clearable
												/>
											</el-select>
										</el-form-item>
									</div>
									<div>
									</div>
								</div>
							</td>
							<td style="height: 38px;" colspan="3">
								<div class="rig ex-col">
									<div>
										<el-form-item label=" 报名点：">
											<el-select
													v-model="dynamicValidateForm.registrationpointval"
													clearable
											  	@change="changeRegistra"
											 >
												<el-option
													v-for="(registraval, index) in registrationPointList"
													:key="index"
													:label="registraval.val"
													:value="registraval"
													clearable
												/>
											</el-select>
										</el-form-item>
									</div>
									<div>
									</div>

								</div>
							</td>
						</tr>
						<tr>
							<td style="height: 38px;">
								<div class="rig ex-col">
									<div>
										<el-form-item  label="考前学历:">
											<el-select
												v-model="dynamicValidateForm.eduval"
												@change="changeedual"
												clearable
											>
												<el-option
													v-for="(edualForm, index) in preExaminationList"
													:key="index"
													:label="edualForm.val"
													:value="edualForm"
													clearable
												/>
											</el-select>
										</el-form-item>
									</div>
									<div>
									</div>

								</div>
							</td>
							<td colspan="3">
								<div class="rig ex-col">
									<div>
										<el-form-item  label=" 户口所在地：">
											<el-select
												v-model="dynamicValidateForm.hukouaddr"
												@change="changeHukouaddr"
												clearable
											 >
												<el-option
													v-for="(hukou, index) in registeredResidenceList"
													:key="index"
													:label="hukou.val"
													:value="hukou"
													clearable
												/>
											</el-select>
										</el-form-item>
									</div>
									<div>
									</div>

								</div>
							</td>
						</tr>
						<tr>
							<td style="height:38px;">
								<div class="ex-col">
									<el-form-item  label="职 业：">
										<el-select
										v-model="dynamicValidateForm.job"
										 @change="changeJob"
										 clearable
										 >
											<el-option
												v-for="(objob, index) in occupationList"
												:key="index"
												:label="objob.val"
												:value="objob"
												clearable

											/>
										</el-select>
									</el-form-item>
								</div>
							</td>
								<td colspan="3" class="ex-col">
									<div class="rig heigsuperrig">
										<el-form-item prop="nowaddr" label=" 通讯地址：">
										  <el-input v-model.trim="dynamicValidateForm.nowaddr" clearable />
										</el-form-item>
									</div>
								</td>
						</tr>
						<tr>
							<td>
								<div class="heigsuperrig">
									<el-form-item prop="idcardtypeval" label=" 证件类型：">
										<el-select
										v-model="dynamicValidateForm.idcardtypeval"
										@change="changeIdcardtypeval"
										clearable
										>
											<el-option
												v-for="(idcardtype, index) in idcardtypeList"
												:key="index"
												:label="idcardtype.val"
												:value="idcardtype"
												clearable
											/>
										</el-select>
									</el-form-item>
								</div>
							</td>
							<td colspan="3">
								<div class="rig flex-xl heigsuper">
									<div>
										<el-form-item prop="idcard" label=" 证  件  号：">
											<el-input
												v-model.trim="dynamicValidateForm.idcard"
												@blur="getIdcard(dynamicValidateForm.idcard)"
												clearable/>
										</el-form-item>
									</div>
									<div class="title-font">
										<font color="red">证件号码一经注册不能修改</font>
									</div>
								</div>
							</td>
						</tr>
						<tr>
							<td  class="ex-col">
								<div class="heigsuperrig">
										<el-form-item prop="graduateschool" label="毕业学校：">
											<el-input v-model.trim="dynamicValidateForm.graduateschool" clearable/>
										</el-form-item>
								</div>
							</td>
							<td colspan="3">
								<div class="rig flex-xl heigsuper">
									<div>
										<el-form-item prop="graduatetime" label=" 毕业年月日：">
										  <el-date-picker
										    type="date"
										    v-model="dynamicValidateForm.graduatetime"
										    placeholder="请选择"
												clearable
										  />
										</el-form-item>
									</div>
									<div class="title-font">
										(格式:2010-09)
									</div>
								</div>
							</td>
						</tr>
						<tr class="zkdisplay">
							<td colspan="3" class="ex-col">
								<div class="rig heigsuperrig">
									<el-form-item prop="zhuankemajor" label=" 毕业专业：">
										<el-input v-model.trim="dynamicValidateForm.zhuankemajor" clearable />
									</el-form-item>
								</div>
							</td>
						</tr>
						<tr class="zkdisplay">
							<td colspan="3">
								<div class="rig flex-xl heigsuper">
									<div>
										<el-form-item prop="zhuankeno" label=" 毕业证书号：">
										  <el-input v-model.trim="dynamicValidateForm.zhuankeno" clearable />
										</el-form-item>
									</div>
									<div class="title-font">
										<font color="red">(未取得专科毕业证的专科考生请输“待定”)</font>
									</div>
								</div>
							</td>
						</tr>
						<tr>
							<td class="ex-col">
								<div class="rig heigsuperrig">
									<el-form-item label="邮政编码：">
									  <el-input v-model.trim="dynamicValidateForm.homezipcode" clearable />
									</el-form-item>
								</div>
							</td>
							<td colspan="3">
								<div class="rig heigsuperrig">
								<el-form-item label="固定电话：">
								  <el-input v-model.trim="dynamicValidateForm.phone" clearable />
								</el-form-item>
								</div>
							</td>
						</tr>
						<tr>
							<td colspan="3">
								<div class="rig flex-xl heigsuper">
									<div>
										<el-form-item prop="mobile" label="移动电话：">
										  <el-input v-model.trim="dynamicValidateForm.mobile" disabled/>
										</el-form-item>
									</div>
									<div class="title-font">
										<font color="red">(此手机号码用于绑定您的网上报名号)</font>
									</div>
								</div>
							</td>
						</tr>
				</table>
				</el-form>
			</div>
			<div class="abase" style="margin-top: 30px; width: 800px;">
				<div class="top">报考志愿</div>
				<el-form
					:model="dynamicValidateForm"
					ref="dynamicValidateForm"
					:rules="rules">
				<table class="tlist" style="width: 100%; text-align: center; background-color: #87ceeb;">
					<thead>
						<tr>
							<th style="font-size: 14px;">
								报考院校
							</th>
							<th colspan="8" style="font-size: 14px;">
								报考专业
							</th>
						</tr>
						<tr>
							<th>
								<div class="ex-col_el">
									<el-form-item prop="grade" label="年级:">
										<el-select
											v-model="dynamicValidateForm.grade"
											@change="onChangeGrade($event, 'dynamicValidateForm')"
											clearable
										 >
											<el-option
												v-for="(item, index) in gradeList"
												:key="index"
												:label="item.val"
												:value="item.val"
												clearable
											/>
										</el-select>
									</el-form-item>
								</div>
							</th>
							<th>
								<div class="ex-col_el">
									<el-form-item prop="projecttype" label="项目类型：">
									  <el-select
											v-model="dynamicValidateForm.projecttype"
											style="width: 100px;"
											@change="proChange"
											clearable
										 >
									  	<el-option
									  		v-for="(project, index) in educationList"
									  		:key="index"
									  		:label="project.val"
									  		:value="project"
									  		clearable
									  	/>
									  </el-select>
									</el-form-item>
								</div>
							</th>
							<th>
								<div class="ex-col_el">
									<el-form-item
									  prop="studytypekey"
										label="学习形式:"
									>
									  <el-select
											v-model="dynamicValidateForm.studytypekey"
											clearable
											@change="studeyChange"
											clearable
											>
									    <el-option
									      v-for="(study,index) in studyTypeList"
									      :key="index"
									      :label="study.val"
									      :value="study"
									    ></el-option>
									  </el-select>
									</el-form-item>
								</div>
							</th>
						</tr>
					</thead>
					<tbody id="zsb" style="background-color: rgb(209, 238, 238);">
							<tr id="zsb">
								<td>
									<div class="ex-col_el">
										<el-form-item prop="collegename1"label="报考院校1:" >
										  <el-input disabled v-model="dynamicValidateForm.collegename1" />
										</el-form-item>
									</div>
								</td>
								<td>
									<div class="ex-col_el">
										<el-form-item prop="majorname1" label="报考专业1:">
										  <el-input disabled v-model="dynamicValidateForm.majorname1" style="width: 180px;" />
										  <el-button
										    type="primary"
										    class="select-btn"
										    @click="
										      reportSelectBtn(
										        collegefeestandard_dialogresult,
										        'showCollegemajorModal',
										        {
										          schoolorgid: dynamicValidateForm.schoolorgid,
										          grade: dynamicValidateForm.grade,
										          majorlevelval: dynamicValidateForm.levelval,
										          projectname: dynamicValidateForm.projecttype,
															studytypeval: dynamicValidateForm.studytypekey,
										        }
										      )
										    "
										    >选择</el-button
										  >
										</el-form-item>
									</div>
								</td>
								<td>
									<div class="ex-col_el">
										<el-form-item prop="majorname2" label="专业2：">
											<el-input v-model.trim="dynamicValidateForm.majorname2" clearable  style="width: 130px;" />
										</el-form-item>
									</div>
								</td>
							</tr>
							<tr id="zsb">
								<td>
									<div class="ex-col_el">
										<el-form-item label="第一志愿函授站:" prop="guakaoschoolorgname" >
										  <el-input v-model="dynamicValidateForm.guakaoschoolorgname" disabled style="130px"/>
										  <el-button
										    type="primary"
										    class="select-btn"
										    @click="
										      onCollegecoporgSelectData(
										        collegecoporg_dialogresult,
										        'showCollegecoporg',
										        {
										          collegename: dynamicValidateForm.collegename1,
										        }
										      )
										    "
										    >选择</el-button
										  >
										</el-form-item>
									</div>
								</td>
								<td>
									<div class="ex-col_el">
										<el-form-item label="函授站:" prop="guakaocompanyorgname">
										  <el-input v-model.trim="dynamicValidateForm.guakaocompanyorgname" disabled/>
										</el-form-item>
									</div>
								</td>
								<td>
									<div class="ex-col_el">
										<el-form-item label="第一志愿上课点:" prop="classaddressname">
										  <el-input v-model.trim="dynamicValidateForm.classaddressname" disabled/>
										</el-form-item>
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<div class="ex-col_el">
										<el-form-item prop="collegename2" label="院校2：">
											<el-input v-model.trim="dynamicValidateForm.collegename2" clearable />
										</el-form-item>
									</div>
								</td>
								<td>
									<div class="ex-col_el">
										<el-form-item prop="majorname21" label="专业1：">
											<el-input v-model.trim="dynamicValidateForm.majorname21" clearable style="width: 130px;" />
										</el-form-item>
									</div>
								</td>
								<td>
									<div class="ex-col_el">
										<el-form-item prop="majorname22" label="专业2：">
											<el-input v-model.trim="dynamicValidateForm.majorname22" clearable style="width: 130px;" />
										</el-form-item>
									</div>
								</td>
							</tr>
					</tbody>
				</table>
			</el-form>
			</div>
			<center style="padding-top: 30px;">
				<el-button type="primary"
				 @click="submitConent('dynamicValidateForm')">保存资料</el-button>
			</center>
		</el-dialog>
		<!--报其他  -->
		<el-dialog
		  class="btn-2b5a95"
		  width="81%"
		  title="报其他："
			v-dialogDrag
		  :visible.sync="showOthersModal"
		  v-if="showOthersModal"
			:close-on-click-modal='false'
		  append-to-body
		>
		  <el-form
		    :model="dynamicValidateForm"
		    ref="dynamicValidateForm"
		    label-width="150px"
		    class="demo-dynamic form-item-w-50"
		    status-icon
		    :rules="rules"
				@submit.native.prevent
		  >
				<div class="title">基本信息</div>
				<el-form-item prop="name" label="姓名:">
				  <el-input v-model="dynamicValidateForm.name" disabled />
				</el-form-item>
				<el-form-item label="身份证号:" prop="idcard">
				  <el-input v-model.trim="dynamicValidateForm.idcard" clearable />
				</el-form-item>
				<el-form-item prop="companyorgname" label="所属分公司">
				  <el-select
				    v-model="dynamicValidateForm.companyorgname"
				    placeholder="请选择"
				    :disabled="!$store.state.login.isMaxAuth"
				    clearable
				    @change="onChangeCompany($event, 'dynamicValidateForm')"
				  >
				    <el-option
				      v-for="item in $store.state.selectData.companyList"
				      :key="item.key"
				      :label="item.val"
				      :value="item.key"
				    >
				    </el-option>
				  </el-select>
				</el-form-item>
				<el-form-item prop="schoolorgid" label="所属教学点">
				  <el-select
				    v-model="dynamicValidateForm.schoolorgname"
				    placeholder="请选择"
				    clearable
				    @change="onChangeSchool($event, 'dynamicValidateForm')"
				  >
				    <el-option
				      v-for="item in $store.state.selectData.schoolList"
				      :key="item.key"
				      :label="item.val"
				      :value="item.key"
				    >
				    </el-option>
				  </el-select>
				</el-form-item>
		    <p style="margin:0 0 10px 0;">收入详情：</p>
				<div
				  v-for="(item, i) in dynamicValidateForm.moreItems"
				  :key="i"
					class="more-form-www more-moreItems-any"
				>
		      <el-form-item
		        label="收入二级类别："
		        :prop="'moreItems.' + i + '.type2name'"
		        :rules="{
		          required: true,
		          message: '必填字段',
		          trigger: 'blur',
		        }"
		      >
		        <el-input v-model.trim="item.type2name" disabled></el-input>
		        <el-button
		          type="primary"
		          class="select-btn"
		          @click="
		            onSelectData(moneyintype2_dialogresult, 'alltype2moneyModal', {
		              index: i,
		            })
		          "
		          >选择</el-button
		        >
		      </el-form-item>
		      <el-form-item
		        label="收入明细："
		        :prop="'moreItems.' + i + '.itemname'"
		        :rules="{
		          required: true,
		          message: '必填字段',
		          trigger: 'blur',
		        }"
		      >
		        <el-input v-model="item.itemname" disabled></el-input>
		        <el-button
		          type="primary"
		          class="select-btn"
		          @click="
		            onSelectData(moneyinitem_dialogresult, 'intomoneyModal', {
		              index: i,
		            })
		          "
		          >选择</el-button
		        >
		      </el-form-item>
		      <el-form-item
		        label="金额："
		        :prop="'moreItems.' + i + '.amount'"
		        :rules="{
		          required: true,
		          message: '必填字段',
		          trigger: 'blur',
		        }"
		      >
		        <el-input v-model.trim="item.amount" clearable></el-input>
		      </el-form-item>
		      <el-form-item
		        label="摘要："
		        :prop="'moreItems.' + i + '.zhaiyao'"
		        :rules="{
		          required: true,
		          message: '必填字段',
		          trigger: 'blur',
		        }"
		      >
		        <el-input v-model.trim="item.zhaiyao"></el-input>
		      </el-form-item>
		      <el-button
		        v-if="i !== 0"
		        @click="onRemoveMoreOther(i)"
		        style="margin: 0 10px; height: 40px"
		        >删除</el-button
		      >
		    </div>
		   <!-- <el-form-item>
		      <el-button @click="addOthers">添加一行</el-button>
		    </el-form-item> -->
		  </el-form>
		  <span slot="footer" class="dialog-footer">
		    <el-button type="primary" @click="onOthersave('dynamicValidateForm')"
		      >保存
		    </el-button>
		  </span>
		</el-dialog>
		<!-- 快速登记--选择收入明细 -->
		<el-dialog
		  class="btn-2b5a95"
		  width="50%"
			v-dialogDrag
			:close-on-click-modal='false'
		  @close="onCloseSelect"
		  title="选择收入明细"
		  :visible.sync="intomoneyModal"
		  v-if="intomoneyModal"
		  append-to-body
		>
		  <el-button type="primary" @click="onConfirmintomoney">确定</el-button>

		  <div class="index_2 btn-2b5a95">
		    <div
		      class="l-w"
		      :style="{
		        height: $store.state.global.isSearchExtend_2
		          ? ''
		          : $store.state.global.searchFormHeight_2 + 10 + 'px',
		      }"
		    >
		      <el-form
		        label-position="right"
		        :class="
		          $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
		        "
		        :model="selectSearchForm"
						@submit.native.prevent
		      >
		        <el-form-item label="名称:" prop="name">
		          <el-input
								v-model="selectSearchForm.name"
								clearable
								@keyup.enter.native="onSelectSearchClick(selectTableUrl, null, Object.assign(
									{index:	dynamicValidateForm.index },
								  selectSearchForm
								))"
							/>
		        </el-form-item>
		      </el-form>
		    </div>
		    <div class="r-w">
		      <el-button
		        type="primary"
		        icon="el-icon-search"
		        :loading="!$store.state.global.isEndRequest"
		        class="btn-2b5a95"
		        @click="onSelectSearchClick(selectTableUrl, null, Object.assign(
							{index:	dynamicValidateForm.index },
						  selectSearchForm
						))"
		        >搜索</el-button
		      >
					<el-button type="primary" @click="handleQuery">重置</el-button>
		      <el-button
		        type="primary"
		        :icon="
		          $store.state.global.isSearchExtend_2
		            ? 'el-icon-arrow-up'
		            : 'el-icon-arrow-down'
		        "
		        v-if="$store.state.global.searchExtend_2"
		        class="btn-2b5a95"
		        @click="onExtendClick('isSearchExtend_2')"
		        >{{ $store.state.global.searchExtendText_2 }}</el-button
		      >
		    </div>
		  </div>

		  <el-table
		    :data="selectTable.list"
		    style="width: 100%"
		    ref="moneyTable"
				border
				:key="key"
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
		    @selection-change="handleSelectionChange"
		  >
		    <el-table-column type="selection" width="55" />
		    <el-table-column label="流水号" prop="id" align="center" />
		    <el-table-column label="名称" prop="name" align="center" />
		  </el-table>
		  <el-pagination
		    background
		    @current-change="
		     (val, data) =>
		         handleSelectChange(val, Object.assign({index:	dynamicValidateForm.index }, selectSearchForm))
		     "
		    :current-page="selectPageInfo.pageindex"
		    :page-size="selectPageInfo.pagesize"
		    :page-sizes="[10, 20, 30, 40, 50]"
		    layout="total, prev, pager, next, jumper"
		    :total="
		      selectTable.paginationutil
		        ? selectTable.paginationutil.totalcount
		        : selectTable.totalcount
		    "
		  ></el-pagination>
		</el-dialog>
		<!-- 快速登记--选择收入二级类别 -->
		<el-dialog
		  class="btn-2b5a95"
		  width="80%"
			v-dialogDrag
			:close-on-click-modal='false'
		  @close="onCloseSelect"
		  title="选择收入二级类型"
		  :visible.sync="alltype2moneyModal"
		  v-if="alltype2moneyModal"
		  append-to-body
		>
		  <el-button type="primary" @click="onConfirmAllType2moneyModal"
		    >确定</el-button
		  >

		  <div class="index_2 btn-2b5a95">
		    <div
		      class="l-w"
		      :style="{
		        height: $store.state.global.isSearchExtend_2
		          ? ''
		          : $store.state.global.searchFormHeight_2 + 10 + 'px',
		      }"
		    >
		      <el-form
		        label-position="right"
		        :class="
		          $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
		        "
		        :model="selectSearchForm"
						@submit.native.prevent
		      >
						<el-form-item label="收入二级项目:" prop="name">
						  <el-input v-model="selectSearchForm.name"
							clearable
							@keyup.enter.native="onSelectSearchClick(selectTableUrl, null, Object.assign(
								{index:	dynamicValidateForm.index },
							  selectSearchForm
							))"
							/>
						</el-form-item>
						<el-form-item label="关联院校:" prop="collegename">
						  <el-input v-model="selectSearchForm.collegename"
							clearable
							@keyup.enter.native="onSelectSearchClick(selectTableUrl, null, Object.assign(
								{index:	dynamicValidateForm.index },
							  selectSearchForm
							))"
							/>
						</el-form-item>
		        <el-form-item label="收入类别:" prop="typename">
		          <el-input v-model="selectSearchForm.typename"
							 clearable
							 @keyup.enter.native="onSelectSearchClick(selectTableUrl, null, Object.assign(
							 	{index:	dynamicValidateForm.index },
							   selectSearchForm
							 ))"
							 />
		        </el-form-item>
		        <el-form-item label="收入一级项目:" prop="type1name">
		          <el-input v-model="selectSearchForm.type1name"
							clearable
							@keyup.enter.native="onSelectSearchClick(selectTableUrl, null, Object.assign(
								{index:	dynamicValidateForm.index },
							  selectSearchForm
							))"
							/>
		        </el-form-item>
		      </el-form>
		    </div>
		    <div class="r-w">
		      <el-button
		        type="primary"
		        icon="el-icon-search"
		        :loading="!$store.state.global.isEndRequest"
		        class="btn-2b5a95"
		        @click="onSelectSearchClick(selectTableUrl, null, Object.assign(
					 		{index:	dynamicValidateForm.index },
					 	  selectSearchForm
					 	))"
		        >搜索</el-button
		      >
					<el-button type="primary" @click="handleQuery">重置</el-button>
		      <el-button
		        type="primary"
		        :icon="
		          $store.state.global.isSearchExtend_2
		            ? 'el-icon-arrow-up'
		            : 'el-icon-arrow-down'
		        "
		        v-if="$store.state.global.searchExtend_2"
		        class="btn-2b5a95"
		        @click="onExtendClick('isSearchExtend_2')"
		        >{{ $store.state.global.searchExtendText_2 }}</el-button
		      >
		    </div>
		  </div>

		  <el-table
		    :data="selectTable.list"
		    style="width: 100%"
		    ref="moneyTable"
				border
				:key="key"
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
		    @selection-change="handleSelectionChange"
		  >
		    <el-table-column type="selection" width="55" />
		    <el-table-column
		      label="流水号"
		      prop="id"
		      align="center"
		      width="100px"
		    />
		    <el-table-column
		      label="收入类别"
		      align="center"
		      prop="typename"
		      width="150px"
		    />
		    <el-table-column
		      label="收入一级项目"
		      prop="type1name"
		      align="center"
		      width="240px"
		    />
		    <el-table-column
		      label="收入二级项目"
		      prop="name"
		      align="center"
		      width="250px"
		    />
		    <el-table-column
		      label="关联院校"
		      prop="collegename"
		      align="center"
		      width="200px"
		    />
		  </el-table>
		  <el-pagination
		    background
		    @current-change="
		     (val, data) =>
		         handleSelectChange(val, Object.assign({index:	dynamicValidateForm.index }, selectSearchForm))
		     "
		    :current-page="selectPageInfo.pageindex"
		    :page-size="selectPageInfo.pagesize"
		    :page-sizes="[10, 20, 30, 40, 50]"
		    layout="total, prev, pager, next, jumper"
		    :total="
		      selectTable.paginationutil
		        ? selectTable.paginationutil.totalcount
		        : selectTable.totalcount
		    "
		  ></el-pagination>
		</el-dialog>

		<!-- 打印收据 -->
		<el-dialog
			class="btn-2b5a95"
			width="1000px"
			v-dialogDrag
			:close-on-click-modal='false'
			@close="onCloseSelect"
			title="打印收据:"
			:visible.sync="shoujuPaper"
			v-if="shoujuPaper"
			append-to-body
		>
			<object class="aaa" id="ReportViewer" name="ReportViewer" type="application/x-grplugin-printviewer" width="900px" height="500px" style="margin: auto;">
			    <param name="ReportURL" :value="this.$url.upload + '/report/shouju.grf'">
			    <param name="DataURL" value="" >
			    <param name="AutoRun" value=true >
			    <param name="SerialNo" value="4DFB949E066NYS7W11L8KAT53SA177391Q9LZQ094WUT9C9J3813SX8PTQC4ALPB9UAQN6TMA55Q3BN8E5726Z5A839QAD9P6E76TKNK5">
			    <param name="UserName" value="锐浪报表插件本机开发测试注册" >
			</object>
		</el-dialog>

	<!-- 	查看详情 -->
		<div id="RightOffside" class="RightOffside" v-show="showDetailModal">
			<!-- 按钮组 -->
			<div class="Offside_btnBox">
				<div class="Offside_Btn Offside_CloseBtn" @click="handleShow"></div>
				<div class="Offside_Btn"
					v-for="(item,index) in tabList"
					:key="index"
					:class="{active:currentClass === index}"
					@click="toggleTab(index)"
				>
					{{item}}
				</div>
			</div>
			<div class="OrderStatusTips">
				<span class="pull-left text-blue">人员信息</span>
			</div>
			<!-- 切换栏 -->
			<div>
				<div class="OrderContentBox" v-show="currentTab === 0">
					<div class="order-card x-basic abase-card" style="width:800px;background-color: #D1EEEE; margin-bottom: 30px;">
						<div class="top_el">
							基本信息
						</div>
						<el-form>
							<table class="tlist_el" style="width: 100%;" bgcolor="#D1EEEE" cellpadding="5px">
								<tbody>
									<tr>
										<td class="ltd_el">分公司：</td>
										<td class="ltd_tag">{{ vstudentpotential.companyorgname }}</td>
										<td class="ltd_el">教学点：</td>
										<td class="ltd_tag">{{ vstudentpotential.schoolorgname }}</td>
										<td class="ltd_el">流水号：</td>
										<td class="ltd_tag">{{ vstudentpotential.id }}</td>
										<td class="ltd_el">姓名：</td>
										<td class="ltd_tag">{{ vstudentpotential.name }}</td>
									</tr>
									<tr>
										<td class="ltd_el">性别：</td>
										<td class="ltd_tag">{{ vstudentpotential.sexval }}</td>
										<td class="ltd_el">婚否：</td>
										<td class="ltd_tag">{{ vstudentpotential.marryval }}</td>
										<td class="ltd_el">手机：</td>
										<td class="ltd_tag">
											<template>
											  {{ vstudentpotential.mobile | phoneFilter()}}
											</template>
										</td>
										<td class="ltd_el">手机2：</td>
										<td class="ltd_tag">{{ vstudentpotential.mobile2 }}</td>
									</tr>
									<tr>
										<td class="ltd_el">QQ：</td>
										<td class="ltd_tag">{{ vstudentpotential.qq }}</td>
										<td class="ltd_el">电子邮箱：</td>
										<td class="ltd_tag">{{ vstudentpotential.email }}</td>
										<td class="ltd_el">出生日期：</td>
										<td class="ltd_tag">{{ vstudentpotential.birthday | formatDate("YYYY-MM-DD")}}</td>
										<td class="ltd_el">证件类型：</td>
										<td class="ltd_tag">{{ vstudentpotential.idcardtypeval }}</td>
									</tr>
									<tr>
										<td class="ltd_el">证件号码：</td>
										<td class="ltd_tag">{{ vstudentpotential.idcard }}</td>
										<td class="ltd_el">固定电话：</td>
										<td class="ltd_tag">{{ vstudentpotential.phone }}</td>
										<td class="ltd_el">政治面貌：</td>
										<td class="ltd_tag">{{ vstudentpotential.polival }}</td>
										<td class="ltd_el">民族：</td>
										<td class="ltd_tag">{{ vstudentpotential.nationval }}</td>
									</tr>
									<tr>
										<td class="ltd_el">籍贯：</td>
										<td class="ltd_tag">{{ vstudentpotential.comefrom }}</td>
										<td class="ltd_el">总部给予：</td>
										<td class="ltd_tag">{{ available[vstudentpotential.hqsend] }}</td>
										<td class="ltd_el">意向程度：</td>
										<td class="ltd_tag">{{ vstudentpotential.intentionval }}</td>
										<td class="ltd_el">意向院校：</td>
										<td class="ltd_tag">{{ vstudentpotential.wantcollegename }}</td>
									</tr>
									<tr>
										<td class="ltd_el">意向专业：</td>
										<td class="ltd_tag">{{ vstudentpotential.comefrom }}</td>
										<td class="ltd_el">层次：</td>
										<td class="ltd_tag">{{ vstudentpotential.levelval }}</td>
										<td class="ltd_el">生源来源：</td>
										<td class="ltd_tag">{{ vstudentpotential.fromtypeval }}</td>
										<td class="ltd_el">正式学生：</td>
										<td class="ltd_tag">{{ available[vstudentpotential.studentflag] }}</td>
									</tr>
									<tr>
										<td class="ltd_el">项目类型：</td>
										<td class="ltd_tag">{{ vstudentpotential.projecttypeval }}</td>
										<td class="ltd_el">信息来源：</td>
										<td class="ltd_tag">{{ vstudentpotential.messagefromval }}</td>
										<td class="ltd_el">住址：</td>
										<td class="ltd_tag">{{ vstudentpotential.homeaddr }}</td>
										<td class="ltd_el">邮编：</td>
										<td class="ltd_tag">{{ vstudentpotential.homezipcode }}</td>
									</tr>
									<tr>
										<td class="ltd_el">户口所在地：</td>
										<td class="ltd_tag">{{ vstudentpotential.hukouaddr }}</td>
										<td class="ltd_el">毕业院校：</td>
										<td class="ltd_tag">{{ vstudentpotential.graduateschool }}</td>
										<td class="ltd_el">毕业时间：</td>
										<td class="ltd_tag">
											{{
									      vstudentpotential.graduatetime
									        | formatDate("YYYY-MM-DD HH:mm:ss")
									    }}</td>
										<td class="ltd_el">学历：</td>
										<td class="ltd_tag">{{ vstudentpotential.eduval }}</td>
									</tr>
									<tr>
										<td class="ltd_el">跟进次数：</td>
										<td class="ltd_tag">{{ vstudentpotential.followcount }}</td>
										<td class="ltd_el">工作类型：</td>
										<td class="ltd_tag">{{ vstudentpotential.jobtype }}</td>
										<td class="ltd_el">专科毕业专业：</td>
										<td class="ltd_tag">{{ vstudentpotential.zhuankemajor }}</td>
										<td class="ltd_el">专科毕业证书号：</td>
										<td class="ltd_tag">{{ vstudentpotential.zhuankeno }}</td>
									</tr>
									<tr>
										<td class="ltd_el">跟进人：</td>
										<td class="ltd_tag">{{ vstudentpotential.followusername }}</td>
										<td class="ltd_el">职业：</td>
										<td class="ltd_tag">{{ vstudentpotential.job }}</td>
										<td class="ltd_el"> 参加工作时间：</td>
										<td class="ltd_tag">
											{{
											  vstudentpotential.jobtime | formatDate("YYYY-MM-DD HH:mm:ss")
											}}
										</td>
										<td class="ltd_el">工作单位：</td>
										<td class="ltd_tag">{{ vstudentpotential.companyname }}</td>
									</tr>
									<tr>
										<td class="ltd_el">客服：</td>
										<td class="ltd_tag">{{ vstudentpotential.kefuusername }}</td>
										<td class="ltd_el">操作人：</td>
										<td class="ltd_tag">{{ vstudentpotential.opusername }}</td>
										<td class="ltd_el"> 录入时间：</td>
										<td class="ltd_tag">
										{{
										  vstudentpotential.inserttime
										    | formatDate("YYYY-MM-DD HH:mm:ss")
										}}
										</td>
										<td class="ltd_el"> 最后操作时间：</td>
										<td class="ltd_tag">
											{{
									      vstudentpotential.lastinserttime
									        | formatDate("YYYY-MM-DD HH:mm:ss")
									    }}</td>
									</tr>
									<tr>
										<td class="ltd_el">区域：</td>
										<td class="ltd_tag">{{ vstudentpotential.regionname }}</td>
										<td class="ltd_el">城市：</td>
										<td class="ltd_tag">{{ vstudentpotential.cityval }}</td>
										<td class="ltd_el"> 微信：</td>
										<td class="ltd_tag">
												{{ vstudentpotential.weixin }}
										</td>
										<td class="ltd_el">最后操作人：</td>
										<td class="ltd_tag">{{ vstudentpotential.lastopusername }}</td>
									</tr>
									<tr>
										<td class="ltd_el">放弃跟进原因：</td>
										<td class="ltd_tag">{{ vstudentpotential.followstatename }}</td>
										<td class="ltd_el">咨询情况：</td>
										<td class="ltd_tag">{{ vstudentpotential.question }}</td>
										<td class="ltd_el"> 转介绍人：</td>
										<td class="ltd_tag">
											{{ vstudentpotential.linkman }}
										</td>
										<td class="ltd_el">合作企业人：</td>
										<td class="ltd_tag">{{ vstudentpotential.enterprisename }}</td>
									</tr>
								</tbody>
							</table>
						</el-form>
					</div>
				</div>
				<div class="OrderContentBox" v-show="currentTab === 1">
					<el-table
						:data="watchDetailList.studentlist"
						border
						stripe
						:key="key"
						:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
						style="width: 100%">
					  <el-table-column
					    label="流水号"
					    prop="id"
					    align="center"
					    width="100"
					  ></el-table-column>
					  <el-table-column
					    label="学生姓名"
					    prop="qianzainame"
					    align="center"
					    width="150"
					  >
					  </el-table-column>
					  <el-table-column
					    label="手机号"
					    prop="mobile"
					    align="center"
					    width="150"
					  >
							<template slot-scope="scope">
							  {{ scope.row.mobile | hideMobile(scope.row.followuserid)}}
							</template>
					  </el-table-column>
					  <el-table-column
					    label="报读年级"
					    prop="grade"
					    align="center"
					    width="100"
					  ></el-table-column>
						<el-table-column
						  label="院校"
						  prop="collegename1"
						  align="center"
						></el-table-column>
						<el-table-column
						  label="专业"
						  prop="majorname1"
						  align="center"
						></el-table-column>
					  <el-table-column
					    label="层次"
					    prop="levelval"
					    align="center"
					    width="100"
					  ></el-table-column>
					  <el-table-column label="总部给予" align="center" width="100">
					    <template slot-scope="scope">
					      {{ available[scope.row.hqsend] }}
					    </template>
					  </el-table-column>
					  <el-table-column
					    label="学习形式"
					    prop="studytypeval"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="类型"
					    prop="baomingtypeval"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="应收"
					    prop="shouldrecvmoney"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="实收"
					    prop="realrecvmoney"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="退费"
					    prop="feebackmoney"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="收据编号"
					    prop="shoujucode"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="跟进人"
					    prop="followusername"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column label="报名" align="center" width="100">
					    <template slot-scope="scope">
					      {{ scope.row.inserttime | formatDate("YYYY-MM-DD hh:mm:ss") }}
					    </template>
					  </el-table-column>
					  <el-table-column
					    label="项目类型"
					    prop="projecttype"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="合作机构"
					    prop="organization"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="所属教学点"
					    prop="schoolorgname"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					</el-table>
				</div>
				<div class="OrderContentBox" v-show="currentTab === 2">
					<el-table
					  :data="watchDetailList.studentmoneyrecvlist"
					  style="width: 100%"
						border
						:key="key"
						stripe
						:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
					>
					  <el-table-column
					    label="流水号"
					    prop="id"
					    align="center"
					    width="100"
					  />
					  <el-table-column
					    label="学生姓名"
					    prop="studentname"
					    align="center"
					    width="100"
					  />
					  <el-table-column
					    label="手机号码"
					    prop="mobile"
					    align="center"
					    width="120"
					  >
							<template slot-scope="scope">
							  {{ scope.row.mobile | hideMobile(scope.row.followuserid)}}
							</template>
						</el-table-column>
					  <el-table-column
					    label="身份证"
					    prop="idcard"
					    align="center"
					    width="150"
					  />
					  <el-table-column
					    label="费用类型"
					    prop="feetypeval"
					    align="center"
					  />
					  <el-table-column
					    label="账号名称"
					    prop="accountname"
					    align="center"
					  />
					  <el-table-column label="金额" prop="recvmoney" align="center" />
					  <el-table-column label="缴费时间" align="center" width="180">
					    <template slot-scope="scope">
					      {{ scope.row.recvtime | formatDate("YYYY-MM-DD HH:mm:ss") }}
					    </template>
					  </el-table-column>
					  <el-table-column
					    label="收据编号"
					    prop="shoujucode"
					    align="center"
					    width="120"
					  />
					  <el-table-column
					    label="经手人"
					    prop="insertusername"
					    align="center"
					    width="120"
					  />
					  <el-table-column
					    label="教学点"
					    prop="schoolorgname"
					    align="center"
					  />
					  <el-table-column label="录入时间" align="center" width="180">
					    <template slot-scope="scope">
					      {{ scope.row.inserttime | formatDate("YYYY-MM-DD HH:mm:ss") }}
					    </template>
					  </el-table-column>
					  <el-table-column label="冲账标志" align="center">
					    <template slot-scope="scope">
					      {{ chongzhanflag[scope.row.invertflag] }}
					    </template>
					  </el-table-column>
					  <el-table-column label="被冲流水" prop="invertid" align="center" />
					  <el-table-column label="冲账时间" align="center" width="180">
					    <template slot-scope="scope">
					      {{ scope.row.inverttime | formatDate("YYYY-MM-DD HH:mm:ss") }}
					    </template>
					  </el-table-column>
					</el-table>
				</div>
				<div class="OrderContentBox" v-show="currentTab === 3">
					<el-table
						:data="watchDetailList.techfudaolist"
						border
						:key="key"
						stripe
						:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
						style="width: 100%">
					  <el-table-column
					    label="流水号"
					    prop="id"
					    align="center"
					    width="100"
					  ></el-table-column>
					  <el-table-column
					    label="学生姓名"
					    prop="studentname"
					    align="center"
					    width="150"
					  >
					  </el-table-column>
					  <el-table-column
					    label="手机号"
					    prop="mobile"
					    align="center"
					    width="150"
					  >
							<template slot-scope="scope">
							  {{ scope.row.mobile | hideMobile(scope.row.followuserid)}}
							</template>
					  </el-table-column>
					  <el-table-column
					    label="班别名称"
					    prop="fudaoclasstypename"
					    align="center"
					    width="100"
					  ></el-table-column>
					  <el-table-column
					    label="培训类型"
					    prop="fudaotypeval"
					    align="center"
					    width="100"
					  ></el-table-column>
					  <el-table-column label="报读时间" align="center" width="100">
					    <template slot-scope="scope">
					      {{ scope.row.regdate | formatDate("YYYY-MM-DD hh:mm:ss") }}
					    </template>
					  </el-table-column>
					  <el-table-column
					    label="收款标识"
					    prop="shoukuanremark"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="应收"
					    prop="totalmoney"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="减免金额"
					    prop="extrasubmoney"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="银行/现金"
					    prop="bankorcash"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="实收"
					    prop="recvmoney"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="退费金额"
					    prop="feebackmoney"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="收据编号"
					    prop="shoujucode"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="跟进人"
					    prop="followusername"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="所属教学点"
					    prop="schoolorgname"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					</el-table>
				</div>
				<div class="OrderContentBox" v-show="currentTab === 4">
					<el-table
					  :data="watchDetailList.studentregisterlist"
					  style="width: 100%"
						border
						:key="key"
						stripe
						:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
					>
					  <el-table-column
					    label="流水号"
					    prop="id"
					    align="center"
					    width="100"
					  ></el-table-column>
					  <el-table-column
					    label="姓名"
					    prop="studentname"
					    align="center"
					    width="150"
					  >
					  </el-table-column>
					  <el-table-column
					    label="手机"
					    prop="mobile"
					    align="center"
					    width="150"
					  >
							<template slot-scope="scope">
								{{ scope.row.mobile | hideMobile(scope.row.followuserid)}}
							</template>
					  </el-table-column>
					  <el-table-column
					    label="学号"
					    prop="code"
					    align="center"
					    width="100"
					  ></el-table-column>
					  <el-table-column
					    label="身份证"
					    prop="studentidcard"
					    align="center"
					    width="100"
					  ></el-table-column>
					  <el-table-column
					    label="院校"
					    prop="collegename"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="专业"
					    prop="majorname"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="学年"
					    prop="iyear"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="年级"
					    prop="grade"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="层次"
					    prop="levelval"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="班主任"
					    prop="followusername"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="函授站"
					    prop="guakaoschoolorgname"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="状态"
					    prop="xuejistatusval"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="项目类型"
					    prop="projecttype"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="教学点"
					    prop="schoolorgname"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					</el-table>
				</div>
				<div class="OrderContentBox" v-show="currentTab === 5">
					<el-table
					  :data="watchDetailList.studentgenzonglist"
					  style="width: 100%"
						border
						:key="key"
						stripe
						:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
					>
					  <el-table-column label="流水号" prop="id" align="center" />
					  <el-table-column
					    label="潜在学生"
					    prop="qianzainame"
					    align="center"
					  />
					  <el-table-column label="学生手机" prop="mobile" align="center">
							<template slot-scope="scope">
							  {{ scope.row.mobile | hideMobile(scope.row.followuserid)}}
							</template>
						</el-table-column>
					  <el-table-column label="QQ" prop="qq" align="center" />
					  <el-table-column
					    label="跟进内容"
					    prop="followcontent"
					    align="center"
					  />
					  <el-table-column label="跟进时间" prop="inserttime" align="center">
					    <template slot-scope="scope">
					      {{ scope.row.inserttime | formatDate("YYYY-MM-DD HH:mm:ss") }}
					    </template>
					  </el-table-column>
					  <el-table-column label="跟进人" prop="username" align="center" />
					  <el-table-column
					    label="所属教学点"
					    prop="schoolorgname"
					    align="center"
					  />
					</el-table>
				</div>
				<div class="OrderContentBox" v-show="currentTab === 6">
					<div class="demo-image__preview demo-image__error">
						<el-card shadow="hover" class="el-card_image_next">
							<p class="demonstration text-align text-blue">身份证正面</p>
							<div class="image_pic_el">
								<el-image
									class="adaptation"
									:src="vstudentpotential.idphoto ? $url.upload + vstudentpotential.idphoto : ''"
									@click="vbs(vstudentpotential.idphoto ? $url.upload + vstudentpotential.idphoto : '')"
									:preview-src-list="srcList"
									:z-index="99999"
								>
									<div slot="error" class="image-slot el-image__error">
										<i class="el-icon-picture-outline"></i>
										<span>暂无图片</span>
									</div>
								</el-image>
							</div>
						</el-card>
						<el-card shadow="hover" class="el-card_image_next">
							<p class="demonstration text-align text-blue">身份证反面</p>
							<div class="image_pic_el">
								<el-image
									class="adaptation"
									:src="vstudentpotential.idphoto2 ? $url.upload + vstudentpotential.idphoto2 : ''"
									@click="vbs(vstudentpotential.idphoto2 ? $url.upload + vstudentpotential.idphoto2 : '')"
									:preview-src-list="srcList"
									:z-index="99999"
								>
									<div slot="error" class="image-slot el-image__error">
										<i class="el-icon-picture-outline"></i>
										<span>暂无图片</span>
									</div>
								</el-image>
							</div>
						</el-card>
						<el-card shadow="hover" class="el-card_image_next">
							<p class="demonstration text-align text-blue">照片</p>
							<div class="image_pic_el">
								<el-image
									class="adaptation"
									:src=" vstudentpotential.photo ? $url.upload + vstudentpotential.photo : ''"
									@click="vbs(vstudentpotential.photo ? $url.upload + vstudentpotential.photo : '')"
									:preview-src-list="srcList"
									:z-index="99999"
								>
									<div slot="error" class="image-slot el-image__error">
										<i class="el-icon-picture-outline"></i>
										<span>暂无图片</span>
									</div>
								</el-image>
							</div>
						</el-card>
						<el-card shadow="hover" class="el-card_image_next">
							<p class="demonstration text-align text-blue">户口本首页</p>
							<div class="image_pic_el">
								<el-image
									class="adaptation"
									:src=" vstudentpotential.registerphoto ? $url.upload + vstudentpotential.registerphoto : ''"
									@click="vbs(vstudentpotential.registerphoto ? $url.upload + vstudentpotential.registerphoto : '')"
									:preview-src-list="srcList"
									:z-index="99999"
								>
									<div slot="error" class="image-slot el-image__error">
										<i class="el-icon-picture-outline"></i>
										<span>暂无图片</span>
									</div>
								</el-image>
							</div>
						</el-card>
						<el-card shadow="hover" class="el-card_image_next">
							<p class="demonstration text-align text-blue">户口本人页</p>
							<div class="image_pic_el">
								<el-image
									class="adaptation"
									:src=" vstudentpotential.registerphoto2 ? $url.upload + vstudentpotential.registerphoto2 : ''"
									@click="vbs(vstudentpotential.registerphoto2 ? $url.upload + vstudentpotential.registerphoto2 : '')"
									:preview-src-list="srcList"
									:z-index="99999"
								>
									<div slot="error" class="image-slot el-image__error">
										<i class="el-icon-picture-outline"></i>
										<span>暂无图片</span>
									</div>
								</el-image>
							</div>
						</el-card>
						<el-card shadow="hover" class="el-card_image_next">
							<p class="demonstration text-align text-blue">毕业证</p>
							<div class="image_pic_el">
								<el-image
									class="adaptation"
									:src="vstudentpotential.schoolphoto ? $url.upload + vstudentpotential.schoolphoto : ''"
									@click="vbs(vstudentpotential.schoolphoto ? $url.upload + vstudentpotential.schoolphoto : '')"
									:preview-src-list="srcList"
									:z-index="99999"
								>
									<div slot="error" class="image-slot el-image__error">
										<i class="el-icon-picture-outline"></i>
										<span>暂无图片</span>
									</div>
								</el-image>
							</div>
						</el-card>
						<el-card shadow="hover" class="el-card_image_next">
							<p class="demonstration text-align text-blue">注册备案表</p>
							<div class="image_pic_el">
								<el-image
									class="adaptation"
									:src=" vstudentpotential.regphoto ? $url.upload + vstudentpotential.regphoto : ''"
									@click="vbs(vstudentpotential.regphoto ? $url.upload + vstudentpotential.regphoto : '')"
									:preview-src-list="srcList"
									:z-index="99999"
								>
									<div slot="error" class="image-slot el-image__error">
										<i class="el-icon-picture-outline"></i>
										<span>暂无图片</span>
									</div>
								</el-image>
							</div>
						</el-card>
						<el-card shadow="hover" class="el-card_image_next">
							<p class="demonstration text-align text-blue">居住证明</p>
							<div class="image_pic_el">
								<el-image
									class="adaptation"
									:src=" vstudentpotential.residence ? $url.upload + vstudentpotential.residence : ''"
									@click="vbs(vstudentpotential.residence ? $url.upload + vstudentpotential.residence : '')"
									:preview-src-list="srcList"
									:z-index="99999"
								>
									<div slot="error" class="image-slot el-image__error">
										<i class="el-icon-picture-outline"></i>
										<span>暂无图片</span>
									</div>
								</el-image>
							</div>
						</el-card>
					</div>
				</div>
			</div>
		</div>
		<Import
		  :importDialog="importDialog"
		  :importSaveUrl="importSaveUrl"
		  @onCloseImport="onCloseImport"
		  @getDataList="getDataList"
		  :downloadUrl="downloadUrl"
		></Import>
  </div>
</template>
<script src="../../../utils/createControl.js?r=${r}"></script>
<script>
import {
	CreateReport,
	CreatePrintViewerEx2,
	CreateDisplayViewerEx2,
	CreateDesignerEx,
	CreatePrintViewerEx,
	CreateDisplayViewerEx,
	CreateDisplayViewer,
	CreatePrintViewer,
	CreateDesigner,
	} from '@/utils/createControl';
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import {
	studentregisterfeedetail_printshoujuPaper,
	studentregisterfeedetail_saveregisterfee,
	techfudao_printshoujuPaper,
	studentyubaoming_printshoujuPaper,
	studentbook_printshoujuPaper,
	techfudao_print,
	studentyubaoming_printshouju,
	studentbook_print,
	studentfudao_print,
	studentfudao_printshoujuPaper,
	student_printshouju,
	student_printshoujuPaper,
  studentgenzong_input,
	studentpotential_importsave,
  studentbook_buy,
  studentnal_list,
  studentpotential_input,
  studentpotential_save,
  studentgenzong_save,
  studentgenzong_list,
  student_save,
  studentfudao_save,
  studentbook_saveall,
  studentyubaoming_save,
  techfudao_save,
  techfudao_input,
  studentpotential_show,
  studentpotential_export,
	student_saveinfo,
  student_addstudentinput,
  studentfudao_input,
  studentyubaoming_input,
  studentbook_inputall,
  studentpotential_changefollow,
  studentpotential_savechangefollow,
  generalmoneyiecv_moneyiecvlist,
  studentbook_savebuy,
  student_incollect,
  student_savecollect,
  studentyubaoming_incollect,
  studentyubaoming_savecollect,
  studentfudao_enrol,
  studentfudao_saveenrol,
  techfudao_enrol,
  techfudao_saveenrol,
  studentregisterfeedetail_registerfee,
	discount_loaddiscount,
	studentregister_getclassname,
	generalmoneyiecv_input,
	generalmonyiecv_save,
	stufudaofeestandarddetail_gettpe
} from "@/request/api/allChildrenProject";
import { myRequest } from "@/request/index";
import {
  _projectTypes,
  _baomingtag,
  _available,
  _bankorcash,
  _formalStudent,
  _approveflag,
  _paidType,
  _projectType,
  _chongzhanflag,
	_isAppselect,
	areaflag
} from "@/assets/js/filedValueFlag";
import {
  _vuser_dialogresult,
	_user_dialogresult,
  dic_combox,
  _stufudaofeestandard_dialogresult,
  _vstockbook_dialogresult2,
  _moneyinitem_dialogresult,
  _moneyintype2_dialogresult,
  _account_dialogresult,
  _shouju_getshoujuinfo,
  _collegefeestandard_dialogresult,
  _fileupload_save,
  _collegecoporg_dialogresult,
} from "@/request/api/public";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
  getSession,
} from "@/utils/util";
import Import from "@/components/Import/index.vue";
export default {
  inject: ["reload"],
  mixins: [part],
  name: "studentpotential",
  components: {Import },
  props: {
    vstockbook_dialogresult2: {
      default: () => _vstockbook_dialogresult2,
    },
    moneyinitem_dialogresult: {
      default: () => _moneyinitem_dialogresult,
    },
    moneyintype2_dialogresult: {
      default: () => _moneyintype2_dialogresult,
    },
    account_dialogresult: {
      default: () => _account_dialogresult,
    },
    studentbook_savebuy: {
      default: () => studentbook_savebuy,
    },
    student_savecollect: {
      default: () => student_savecollect,
    },
    studentyubaoming_savecollect: {
      default: () => studentyubaoming_savecollect,
    },
    studentfudao_saveenrol: {
      default: () => studentfudao_saveenrol,
    },
    techfudao_saveenrol: {
      default: () => techfudao_saveenrol,
    },
    vuser_dialogresult: {
      default: () => _vuser_dialogresult,
    },
		user_dialogresult:{
      default: () => _user_dialogresult,
    },
    stufudaofeestandard_dialogresult: {
      default: () => _stufudaofeestandard_dialogresult,
    },
    collegefeestandard_dialogresult: {
      default: () => _collegefeestandard_dialogresult,
    },
    collegecoporg_dialogresult: {
      default: () => _collegecoporg_dialogresult,
    },
		stufudaofeestandarddetail_gettpe: {
		  default: () => stufudaofeestandarddetail_gettpe,
		},

  },
  data() {
    // 手机验证
    var validatorPhone = function (rule, value, callback) {
      if (!(value + "")) {
        callback(new Error("手机不能为空"));
      } else if (!/^1\d{10}$/.test(value)) {
        callback(new Error("手机号格式错误且为11位"));
      } else {
        callback();
      }
    };
		// 省份证校验
		var validatorCard = function (rule, value, callback) {
			if(!/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(value) && !/^([A-Z]\d{6,10}(\(\w{1}\))?)$/.test(value) && !/^[1|5|7][0-9]{6}\([0-9Aa]\)/.test(value) && !/^[a-zA-Z][0-9]{9}$/.test(value)) {
				callback(new Error("身份证号码错误"));
			} else {
				callback();
			}
		};
		var validateName = (rule, value, callback) => {
			if(this.dynamicValidateForm.introducer === "" && this.isHaveTo) {
				callback(new Error('请输入转介绍人'));
			} else {
				callback();
			}
		};
		var validateFee = (rule, value, callback) => {
			if(this.dynamicValidateForm.introducerfee === "" && this.isHavefee) {
				callback(new Error('请输入转介绍费'));
			} else {
				callback();
			}
		};
    return {
      showflag: 0,
			tableHeight: 500,
			intomoneyModal: false,
			alltype2moneyModal: false,
			labelPosition: 'left',//右对齐
			shoujuPaper:false,//打印收据
			discoundtList: [],
			downloadUrl: "",
			importDialog: false, //导入框
			importSaveUrl: "",
			type: "",
			showRegisterModal: false, // 注册收费
			followuserid: null,
			currentTab: 0,
			srcList: [],
			currentClass: 0,
			tabList:['档案','学历报名','收费','培训','学籍 ','跟进', '证件信息'],
			studytypeval:'',
			report_dynamicValidateForm:{
				moreItems1: [
				    {
							duration: "",
							appoint: "",
							post: "",
							xuezhival: "",
							workingyears: "",
							graduation: "",
							resumetype:1,
				    },
				  ],
				moreItems2: [
				    {
							resumename: "",
							appellation: "",
							age: "",
							occupation: "",
							membertype:1,
				    },
				  ],
			},
			arr_province: ['中专','高升专','高升本','专升本','研究生'],
			brandObj: _isAppselect,
			dynamicValidateForm: {
			 examineeCategory:'',
				course:''
			},
			typeOptions:[],
			areaflags: areaflag,
			showDetailList:false,//报读学历
      chongzhanflag: _chongzhanflag,
      bankorcash: _bankorcash,
      available: _available,
      approveflag: _approveflag,
      paidType: _paidType,
      studentTypeList:[],//考生类别
			languageTypeList:[],//语言种类
      rowFollow: {}, // 确定值
      nationList: [], // 民族
      gradeList: [], // 年级
      studyTypeList: [], // 学习形式
      policyList: [], // 政治面貌
      studentfromtypeList: [], // 生源类别
      projecttypeList: _projectTypes, // 项目类型数组
      projectType: _projectType, // 项目类型对象
      intentionList: [], // 意向程度
      schoolList: [], // 所属教学点
      companylist: [], // 所属分公司
      leveltypeList: [], // 层次
      enrollyearList: [], // 招生年份
      baomingtag: _baomingtag,
      // pulic
      rowItem: null, //表格项i
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      searchForm: {}, // 首页搜索对象
			key: '',
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: {
        bookname: [],
        price: [],
        quality: [],
        discount: [],
      }, // 添加修改等框对象
      selectTable: {}, // 选择的对象
      selectTableUrl: null, // 选择的路径

      selectSchoolorgid: null, // modal框
      selectSearchForm: {}, // 选择搜索
      selectPageInfo: {
        pageindex: 1,
        pagesize: 10,
      }, // 选择框分页
      // 增加修改
      dialogTitle: "", // 弹框标题
			showOthersModal: false,//报其他
      addEditDisable: false, // 禁用与启用
			followDisabled: false,//跟进人禁用与启用
      showAddModal: false, //显示表单框
      // 增加修改表单框里面的选择跟进人
      showFollowDataModal: false, //显示表单框(选择跟进人)
      showFollowModal: false, // 显示表单框(跟进数据)
      detailData: {}, // 对象(跟进详情)
      showFollowDetailModals: false, // 显示表单框(跟进详情)
      showReportModal: false, // 显示表单框(报读学历)
      showCollegemajorModal: false, // 选择跟进人表单框(选择院校专业)
      reportClassModal: false, // 显示表单框(报读辅导班)
      reportClassDataModal: false, // 选择数据弹框(报读辅导班选择数据)

      moreItemForm: {
        moreItems: [
          {
            bookname: "",
            price: "",
            quality: "",
            discount: "",
          },
        ],
      }, //购买教材
      purchaseModal: false, //购买教材

      //收费
      showPurchaseModal: false, // 购买教材 收费
      showStudentModal: false, // 学历继续教育
      showStudentyubaomingModal: false, // 代预报名
      showStudentfudaoModal: false, // 学历辅导班
      showTechfudaoModal: false, // 教师资格
      selectType: null,
      showSelectItemModal: false, // 收入明细框
      showSelectItem1Modal: false, // 收入明细框 - 培训费、辅导费
      showSelectTypeModal: false, // 二级弹框
      showSelectAccountModal: false, // 银行弹框
      showCollegecoporg:false,//校外教学点

      // 代预报名
      preModal: false, // 弹框
      highSubjects: [], //高升专/本
      speciallySubjects: [], //专升本

      // 报读技能
      skillModal: false,
      skillDataForm: {},
      skillDataPage: {},
      skillDataModal: false,

      // 分配班主任
      distributionModal: false,
      distributionDataForm: {},
      distributionDataPage: {},

      distributionDataSelectForm: {},

      // 查看详情
      showDetailModal: false,
      vstudentpotential: {},
      watchDetailList: {},
      activeName: "vstudentpotential",
      tabId: null,

      // 收费
      payModal: false,
      activeName: "studentlist",
      formalStudent: _formalStudent,

      payForm: {},

      // 规则校验
      rules: {
				discountfee: [
					{ required: true, message: "必填字段", trigger: "blur" },
				],
				studentfromtypeval: [
					{ required: true, message: "必填字段", trigger: "blur" },
				],
				introducer: [{ validator: validateName }],
				introducerfee: [{ validator: validateFee }],
				type: [{ required: true, message: "必填字段", trigger: "blur" }], // 是否渠道
				idcard: [
					{ required: true, message: '请输入身份证号', trigger: 'blur' },
					{ min: 2, max: 18, message: '请如实填写18位号码，以供相关人员核对', trigger: 'blur' },
					{
					 validator: validatorCard, trigger: 'blur'
					}
				],
				pwd:[
          { required: true, message: "必填字段", trigger: "blur" },
        ], // 学历继续教育 应收
        shouldrecvmoney: [
          { required: true, message: "必填字段", trigger: "blur" },
        ], // 学历继续教育 应收
				homezipcode:[
					{ required: true, message: "必填字段", trigger: "blur" },
				],
				examinationType:[
					{ required: true, message: "必填字段", trigger: "blur" },
				],
				examinationCounty:[
					{ required: true, message: "必填字段", trigger: "blur" },
				],
        name: [
					{ required: true, message: '请输入姓名', trigger: 'blur' },
					{ min: 2, max: 10, message: '长度在 2 到 10 个字', trigger: 'blur' },
					{
						required: true,
						pattern: /^[\u4e00-\u9fa5_a-zA-Z0-9.·-]+$/,
						message: '姓名不支持特殊字符',
						trigger: 'blur'
					}
				], // 姓名
				phone:[
						{ required: true, message: "必填字段", trigger: "blur" },
				],
				mobile: [
          {
            required: true,
            trigger: "blur",
          },
					{
						pattern: /^1(3|4|5|6|7|8|9)\d{9}$/,
						 message: "请输入正确的手机号码"
					}

        ], // 手机
				recvitemmoney:[
				  { required: true, message: "必填字段", trigger: "blur" },
				],
				course: [{ required: true, message: "必填字段", trigger: "blur" }], // 民族
				nationkey: [
					{ required: true, message: "必填字段", trigger: "blur" },
				],
				registeredSubjects:[
					{ required: true, message: "必填字段", trigger: "blur" },
				],
				introducer: [
				  { required: true, message: "必填字段", trigger: "blur" },
				],
				introducerfee: [
				  { required: true, message: "必填字段", trigger: "blur" },
				],
				registrationpointval:[
					 { required: true, message: "必填字段", trigger: "blur" },
				],
        followusername: [
          { required: true, message: "必填字段", trigger: "blur" },
        ], // 跟进人
        nationkey: [{ required: true, message: "必填字段", trigger: "blur" }], // 民族
        polikey: [{ required: true, message: "必填字段", trigger: "blur" }], // 政治面貌
        studytypekey: [
          { required: true, message: "必填字段", trigger: "blur" },
        ], // 学习形式
        inserttime: [{ required: true, message: "必填字段", trigger: "blur" }], // 报读时间
        year: [{ required: true, message: "必填字段", trigger: "blur" }], // 招生年份
        projecttype: [{ required: true, message: "必填字段", trigger: "blur" }], // 报读类型
        fudaoclasstypename: [
          { required: true, message: "必填字段", trigger: "blur" },
        ], // 辅导班班别
				birthday:[
					{ required: true, message: "必填字段", trigger: "blur" },
				],
        fudaotypeval: [
          { required: true, message: "必填字段", trigger: "blur" },
        ], // 辅导班类型
        fudaofee: [{ required: true, message: "必填字段", trigger: "blur" }], // 辅导费
        bookfee: [{ required: true, message: "必填字段", trigger: "blur" }], // 教材费
        levelkey: [{ required: true, message: "必填字段", trigger: "blur" }], // 层次
        regdate: [{ required: true, message: "必填字段", trigger: "blur" }], // 报读时间
				languagecode: [{ required: true, message: "必填字段", trigger: "blur" }],
        wantcollegename1: [
          { required: true, message: "必填字段", trigger: "blur" },
        ], // 院校1
        wantmarjorname11: [
          { required: true, message: "必填字段", trigger: "blur" },
        ], // 专业11
        companyorgname: [
          { required: true, message: "必填字段", trigger: "blur" },
        ], // 分公司
        schoolorgname: [
          { required: true, message: "必填字段", trigger: "blur" },
        ], // 教学点
        sexkey: [{ trigger: "blur", message: "必填字段", required: true }],
        homeaddr: [{ trigger: "blur", message: "必填字段", required: true }],
        job: [{ trigger: "blur", message: "必填字段", required: true }],
        companyorgid: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        levelval: [{ trigger: "blur", message: "必填字段", required: true }],

        leveltype1: [{ trigger: "blur", message: "必填字段", required: true }],
        schoolorgid: [{ trigger: "blur", message: "必填字段", required: true }],
        recvfudaofee: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
				nationkey:[{ trigger: "blur", message: "必填字段", required: true }],
        recvbookfee: [{ trigger: "blur", message: "必填字段", required: true }],
        totalmoney: [{ trigger: "blur", message: "必填字段", required: true }],
        itemname1: [{ trigger: "blur", message: "必填字段", required: true }],
        shoujucode: [{ trigger: "blur", message: "必填字段", required: true }],
        itemmoneyinitemname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        bankorcash: [{ trigger: "blur", message: "必填字段", required: true }],
        typem1oneyintypename: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        accountname: [{ trigger: "blur", message: "必填字段", required: true }],
        typem2oneyintypename: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        typemoneyintypename: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        followcontent: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        grade: [{ trigger: "blur", message: "必填字段", required: true }], //年级
        collegename1: [
          { trigger: "blur", message: "必填字段", required: true },
        ], //报考院校1
        majorname1: [{ trigger: "blur", message: "必填字段", required: true }], //报考专业1
        realrecvmoney: [
          { trigger: "blur", message: "必填字段", required: true },
        ], //报考专业1
        buytime: [{ trigger: "blur", message: "必填字段", required: true }], //购买时间
        guakaoschoolorgname: [{ trigger: "blur", message: "必填字段", required: true }],
				eduval:[{ trigger: "blur", message: "必填字段", required: true }],
				hukouaddr:[{ trigger: "blur", message: "必填字段", required: true }],
				idcardtypeval:[{ trigger: "blur", message: "必填字段", required: true }],
          isbookfee:[{ trigger: "blur", message: "必填字段", required: true }],
      },
      companyorgidTag: null,
      fds: new FormData(),
			//快速登记表单
			dynamicValidateForm: {
			  moreItems: [
			    {
			      itemid: "",
			      amount: "",
			      zhaiyao: "",
			      type2id: "",
			      type1id: "",
			      typeid: "",
			    },
			  ],
			},
    };
  },
  created() {
    // 获取所有分公司
    this.org_combox();
    this.org_comboxchild(this.dynamicValidateForm.companyorgid);
    // 获取列表
    this.getDataList();
    // 获取民族
    this.getPulicList("nation", "nationList");
    // 获取学习形式
    this.getPulicList("studytype", "studyTypeList");
    // 获取层次
    this.getPulicList("leveltype", "leveltypeList");
    // 获取政治面貌
    this.getPulicList("policy", "policyList");
    // 获取生源类别
    this.getPulicList("studentfromtype", "studentfromtypeList");
    // 获取意向程度
    this.getPulicList("intention", "intentionList");
    // 招生年份
    this.getPulicList("enrollyear", "enrollyearList");
    // 年级
    this.getPulicList("grade", "gradeList");
		//考试语种
		this.getPulicList("language","languageTypeList");
		//考前学历
		this.getPulicList("preExamination","preExaminationList");
		//证件类型
		this.getPulicList("idcardtype","idcardtypeList");
		//职业
		this.getPulicList("occupation","occupationList");
		//户口所在地
		this.getPulicList("registeredResidence","registeredResidenceList");
		//考试类型
		this.getPulicList("examtype2","examtype2List");
		//报考科类
		this.getPulicList("baokaokelei","baokaokeleiList");
		//项目类型
		this.getPulicList("education","educationList");
		//报名点
		this.getPulicList("registrationPoint", "registrationPointList");
		//生源来源
		this.getPulicList("fromtype", "fromtypeList");
		//优惠卷
		this.discoundtlist();
		window.addEventListener("keyup", this.enterSelect)
  },
	mounted () {
	},
	computed: {
		isHaveTo: function() {
			return this.dynamicValidateForm.studentfromtypeval !== `个人跟踪`;
		},
		isHavefee: function() {
			return this.dynamicValidateForm.studentfromtypeval !== `个人跟踪`;
		},
	},
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    // 获取分公司和教学点下拉数据
    ...mapActions("selectData", [
      "org_combox",
      "org_comboxchild",
      "dic_combox",
    ]),
		enterSelect(e) {
			if(e.keyCode === 13) {
				this.getDataList();
			}
		},
		visibleType(e) {
			if(!e) {
				this.$refs.hqsend.blur();
			}
		},
		selectQuery() {
			this.selectSearchForm = {
				collegename: null,
				collegemajorname: null,
				majorlevelval: null,
				schoolorgname: null,
				classname: null,
				name: null,
				typename: null,
				type1name: null,
				accountname: null,
				realname: null,
				fudaoclasstypename: null,
				fudaotypeval: null,
			}
		},
		handleQuery() {
			this.selectQuery();
			this.onSelectData(
			  this.selectTableUrl,
			  null,
			  null,
			  null,
			  null,
			  null
			);
		},
		reset() {
			this.searchForm = {
				name: null,
				followusername: null,
				mobile: null,
				qq: null,
				followcount: null,
				idcard: null,
				comefrom: null,
				schoolorgname: null,
				phone: null,
				job: null,
				hqsend: null,
				cityval: null,
				t1: null,
				t2: null
			}
		},
		//重置
		resetQuery() {
			this.reset();
			this.getDataList();
		},
		vbs(val) {
			this.srcList = []
			this.srcList.push(val)
		},
		//改变函授站 change
		onGuakaoschoolorChange(val) {
			this.dynamicValidateForm.guakaoschoolorgid = val;
			this.collegecoporglist();
		},
		//改变上课开班地点 change
		onClassaddressChange(val) {
		  this.dynamicValidateForm.classaddressid = val;
		},
		//加载上课地点 api
		collegecoporglist() {
		  myRequest({
		    url: studentregister_getclassname,
		    data: {
					collegeid:this.multipleSelection[0].collegeid,
					schoolorgid:this.dynamicValidateForm.guakaoschoolorgid,
		      pagesize:20
		    },
		  }).then(res=>{
		    if(res.data.code==='200'){
		     this.collegecoporgList = res.data.data.list
		    }
		  })
		},
      //导出click
    btnExport() {
      confirmCallBack({
        title: "提示",
        content: "根据搜索条件进行导出。",
        success: () => {
          window.location.href =
            this.$url.Freemarker +
            studentpotential_export +
            `?${createObjParams(this.searchForm)}&showflag=${
          this.showflag
        }`;
        },
        fail: () => {
          messageTip({
            message: "取消操作",
            type: "info",
          });
        },
      });
    },
		//保存
		onOthersave(formName) {
		  const initMorefield = [
		    "typeid",
		    "type1id",
		    "type2id",
		    "type2name",
		    "itemid",
		    "itemname",
		    "amount",
		    "zhaiyao",
		  ];
		  initMorefield.map((i) => (this.dynamicValidateForm[i] = []));
		  for (let i in this.dynamicValidateForm.moreItems) {
		    for (let key in this.dynamicValidateForm.moreItems[i]) {
		      if (this.dynamicValidateForm.moreItems[i][key]) {
		        //有值才push，否则是空字符串
		       this.dynamicValidateForm[key].push(
		          this.dynamicValidateForm.moreItems[i][key]
		        );
		      }
		    }
		  }
		  this.$refs[formName].validate((valid) => {
		    if (valid) {
					this.hanldeSave();
		    }
		  });
		},
		hanldeSave() {
			myRequest(
			  {
			    method: "post",
			    url: generalmonyiecv_save,
			    data: this.$qs.stringify(this.dynamicValidateForm, {
			      arrayFormat: "repeat",
			    }),
			  },
			  {
			    that: this,
					modal: "showOthersModal",
			    isRefTableList: true,
			  }
			);
		},
		//添加一行 click
		/* addOthers() {
		  this.dynamicValidateForm.moreItems.push({
		    type2id: "",
		    itemid: "",
		    amount: "",
		    zhaiyao: "",
		  });
		}, */
		//确定收入二级类型 click
		onConfirmAllType2moneyModal() {
		  selectCheck(this.multipleSelection, "收入二级类别", false, () => {
		    this.$set(
		      this.dynamicValidateForm.moreItems[this.selectIndex],
		      "type2id",
		      this.multipleSelection[0].id
		    );
		    this.$set(
		      this.dynamicValidateForm.moreItems[this.selectIndex],
		      "type2name",
		      this.multipleSelection[0].name
		    );
		    this.$set(
		      this.dynamicValidateForm.moreItems[this.selectIndex],
		      "type1id",
		      this.multipleSelection[0].type1id
		    );
		    this.$set(
		      this.dynamicValidateForm.moreItems[this.selectIndex],
		      "typeid",
		      this.multipleSelection[0].typeid
		    );
		    this.alltype2moneyModal = false;
				this.selectQuery();
		  });
		},
		//快速登记的确认收入明细 click
		onConfirmintomoney() {
		  selectCheck(this.multipleSelection, "确认收入明细", false, () => {
			 this.$set(
		      this.dynamicValidateForm.moreItems[this.selectIndex],
		      "itemid",
		      this.multipleSelection[0].id
		    );
				this.$set(
		      this.dynamicValidateForm.moreItems[this.selectIndex],
		      "itemname",
		      this.multipleSelection[0].name
		    );
		    this.intomoneyModal = false;
				this.selectQuery();
		  });
		},
		//隐藏和显示
		handleShow(){
			this.showDetailModal = false;
		},
		//关闭导入框
		onCloseImport(bool) {
		  this.importDialog = bool;
		},
		//导出
		btnImport() {
			this.importSaveUrl = studentpotential_importsave;
			this.downloadUrl = "/download/业务管理/潜在学生导入.xls";
			this.importDialog = true;
		},
		//改变切换栏
		toggleTab(current) {
			this.currentTab = current;
			this.currentClass = current;
		},
    //上传文件前 before
    beforeUpload(file) {
      this.fds.append("upfile", file); // 传文件
    },
    //上传文件 upload
    uploadFile(prop) {
      myRequest({
        method: "post",
        url: _fileupload_save,
        data: this.fds,
      }).then((res) => {
        if (res.data.code === "200") {
          this.fds = new FormData();
          this.dynamicValidateForm[prop] = res.data.url;
        }
      });
    },
		upload(prop) {
		  myRequest({
		    method: "post",
		    url: _fileupload_save,
		    data: this.fds,
		  }).then((res) => {
		    if (res.data.code === "200") {
		      this.fds = new FormData();
		      this.report_dynamicValidateForm[prop] = res.data.url;
		    }
		  });
		},
		fromtypevalChange(studently) {
			this.dynamicValidateForm.fromtypeval = studently.val;
			this.dynamicValidateForm.fromtypekey = studently.key;
		},
		studentChange(student){
			this.dynamicValidateForm.studentfromtypeval = student.val;
			this.dynamicValidateForm.studentfromtypekey = student.key;
		},
		studeyChange (study){
			this.dynamicValidateForm.studytypekey = study.val;
			this.studytypeval = study.key;
		},
		proChange(project){
			this.dynamicValidateForm.projecttype = project.val;
		},
		// 项目类型 change
		projectChange(event, obj) {
		  this[obj].key = event;
		},
		changexamineeCategory(item){
      this.dynamicValidateForm.examinationType = item.val;
		},

		//语种
		changeCode(code){
			this.dynamicValidateForm.languagecode = code.val;
		},
		//报考科类
		registereChange(obj){
			this.dynamicValidateForm.registeredsubjects = obj.val;
		},
		//考试县区
		areaflagChange(areaflag){
			this.dynamicValidateForm.examinationCounty = areaflag.label;
		},
		changeedual(edualForm){
			this.dynamicValidateForm.eduval = edualForm.val;
		},
		changeRegistra(registraval){
			this.dynamicValidateForm.registrationpointval = registraval.val;
		},
		changeHukouaddr(hukou){
			this.dynamicValidateForm.hukouaddr= hukou.val;
		},

		changeJob(objob){
			this.dynamicValidateForm.job = objob.val;
		},
		changeIdcardtypeval(idcardtype){
			this.dynamicValidateForm.idcardtypeval = idcardtype.val;
		},
		changeSelect() {
			for(const k in this.arr_province) {
				if(this.dynamicValidateForm.levelval === this.arr_province[k]) {
					this.typeOptions = this.brandObj[this.dynamicValidateForm.levelval]
				} else {
					this.dynamicValidateForm.course = "";
				}
			}
		},
    //改变选择框 change
    onChangeSelect(val, prop) {
			console.log(val,prop)
      this.dynamicValidateForm[prop] = val;
    },

    changeSex(event, obj) {
      this[obj].key = event;
    },

    //改变分公司 change
    onChangeCompany(val, form) {
      this[form].schoolorgname = "";
      this[form].companyorgid = val;
      this[form].shoujucode = "";
      this.org_comboxchild(val);
    },
    // 改变教学点
    onChangeSchool(val, form) {
      this[form].schoolorgid = val;
      this[form].year = "";
      this[form].grade = "";
      this[form].collegename1 = "";
      this[form].majorname1 = "";
      this[form].levelval = "";
    },
    // 招生年份 change
    ChangeYear() {
      this.dynamicValidateForm.grade = "";
      this.dynamicValidateForm.collegename1 = "";
      this.dynamicValidateForm.majorname1 = "";
    },
		// 改变年级
		onChangeGrade(val, form) {
			this[form].grade = val;
			this[form].collegename1 = '';
			this[form].majorname1 = '';
		},
    // 获取公共数组方法
    getPulicList(params, list) {
      myRequest({
        url: dic_combox,
        data: {
          typecode: params,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this[list] = res.data.data.list;
        }
      });
    },
    //搜索 click
		seach() {
		  this.pageinfo.pageindex = 1;
		  this.getDataList();
		},
    //列表查询 api
    getDataList() {
      myRequest({
        url: studentnal_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
		//切换当前页码
		handleSizeChange(val) {
		  this.pageinfo.pagesize = val;
			this.getDataList();
		},
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
    //添加click
    btnAdd() {
      this.dialogTitle = "添加:";
      this.addEditDisable = false; // 禁用与启用
			this.followDisabled = false; //禁用与启用
      this.dynamicValidateForm = {
        schoolorgid: this.$store.state.login.userinfo.schoolorgid,
        companyorgid: this.$store.state.login.userinfo.companyorgid,
        followusername: this.$store.state.login.userinfo.realname,
        followuserid: this.$store.state.login.userinfo.id,
        introducerpz: "",
        photo: "",
      };
     this.$set(
       this.dynamicValidateForm,
       "companyorgname",
       this.$store.state.login.userinfo.companyorgname
     );
     this.$set(
       this.dynamicValidateForm,
       "schoolorgname",
       this.$store.state.login.userinfo.schoolorgname
     );
      this.org_comboxchild(this.dynamicValidateForm.companyorgid);
      this.showAddModal = true;
    },
    //修改click
    btnEdit() {
      selectCheck(this.multipleSelection, "修改", false, () => {
				this.dialogTitle = "修改:";
				this.followDisabled = true;
        this.editRoutePage(this.multipleSelection[0].id);
				if(!this.$store.state.login.isMobileAuth) {
					this.addEditDisable = true; // 禁用与启用
				}else {
					this.addEditDisable = false; // 禁用与启用
				}
      });
    },
		getIdcard(idCard) {
			// console.log(idCard,'这个是身份证号码---');
			var birthday = "";
			var idCard = this.dynamicValidateForm.idcard;
			if (idCard != null && idCard != "") {
			  if (idCard.length == 15) {
			    birthday = "19" + idCard.substr(6, 6);
					console.log(birthday,'这个数局是几位啊---');
			  } else if (idCard.length == 18) {
			    birthday = idCard.substr(6, 8);
					console.log(birthday,'18位--');
			  }
			  this.dynamicValidateForm.birthday = birthday.replace(/(.{4})(.{2})(.{2})/, "$1-$2-$3 04:00:00");
				console.log(this.dynamicValidateForm.birthday,'这个是出生年月日----')
			}
			return birthday;
		},
    // 增加-跟进人确定
    followDataComfirm() {
      this.dynamicValidateForm.followusername =
        this.multipleSelection[0].realname;
      this.dynamicValidateForm.followuserid = this.multipleSelection[0].id;
      this.showFollowDataModal = false;
      this.multipleSelection = [];
			this.selectQuery();
    },
    //修改路由页 api
    editRoutePage(id) {
      myRequest({
        url: studentpotential_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.dialogTitle = "修改:";
          this.showAddModal = true;
          this.dynamicValidateForm = res.data.data.vstudentpotential;
					this.org_comboxchild(this.dynamicValidateForm.companyorgid);
          this.dynamicValidateForm.joinuptime = formatDare(
            this.dynamicValidateForm.joinuptime,
            "YYYY-MM-DD hh:mm:ss"
          );
          this.dynamicValidateForm.joinouttime = formatDare(
            this.dynamicValidateForm.joinouttime,
            "YYYY-MM-DD hh:mm:ss"
          );
					this.dynamicValidateForm.birthday = formatDare(
					  this.dynamicValidateForm.birthday,
					  "YYYY-MM-DD hh:mm:ss"
					);
					this.dynamicValidateForm.graduatetime = formatDare(
					  this.dynamicValidateForm.graduatetime,
					  "YYYY-MM-DD hh:mm:ss"
					);
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
          delete this.dynamicValidateForm.lastfollowtime; //lastfollowtime 该字段不用传
          delete this.dynamicValidateForm.isoktime; //isoktime 该字段不用传
          delete this.dynamicValidateForm.lastinserttime; //lastinserttime 该字段不用传
					 delete this.dynamicValidateForm.jobtime; //lastinserttime 该字段不用传
        }
      });
    },
    //提交-（添加，修改） api
    submitAddOrEditForm(dynamicValidateForm, callbackName) {
      this.$refs[dynamicValidateForm].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: studentpotential_save,
              data: this.dynamicValidateForm,
            },
            {
              isRefTableList: true,
              that: this,
              modal: "showAddModal",
            }
          ).then((res) => {
            if (res.data.code === "200") {
							if (!callbackName) return;
              this[callbackName](res.data.data.id);
            }
          });
        }
      });
    },
		// 保存资料student_save
		submitConent(dynamicValidateForm){
			 this.$refs[dynamicValidateForm].validate((valid) => {
				if(valid) {
					myRequest(
					  {
							url: student_save,
							data:{
								qianzaiid: this.dynamicValidateForm.qianzaiid,
								name:this.dynamicValidateForm.name,
								sexkey: this.dynamicValidateForm.sexkey,
								sexval:	this.dynamicValidateForm.sexval,
								pwd: this.dynamicValidateForm.pwd,
								nationkey: this.dynamicValidateForm.nationkey,
								birthday: this.dynamicValidateForm.birthday, //出生日期
								polikey: this.dynamicValidateForm.polikey,
								languagecode: this.dynamicValidateForm.languagecode,
								examinationType: this.dynamicValidateForm.examinationType,
								points:this.dynamicValidateForm.points,
								levelval:this.dynamicValidateForm.levelval,
								registeredsubjects:this.dynamicValidateForm.registeredsubjects,
								course: this.dynamicValidateForm.course,
								examination:this.dynamicValidateForm.examination,
								registrationpointval: this.dynamicValidateForm.registrationpointval,
								eduval: this.dynamicValidateForm.eduval,
								hukouaddr: this.dynamicValidateForm.hukouaddr,
								job:this.dynamicValidateForm.job,
								idcardtypeval: this.dynamicValidateForm.idcardtypeval,
								idcard:this.dynamicValidateForm.idcard, //证件号码
								graduateschool:this.dynamicValidateForm.graduateschool, //毕业院校
								graduatetime : this.$set(
									this.dynamicValidateForm,
									"graduatetime",
									formatDare(
										this.dynamicValidateForm.graduatetime,
										"YYYY-MM-DD HH:mm:ss"
									)
								),
								zhuankemajor: this.dynamicValidateForm.zhuankemajor,
								zhuankeno : this.dynamicValidateForm.zhuankeno,
								homezipcode:this.dynamicValidateForm.homezipcode,
								phone:this.dynamicValidateForm.phone, //固定电话
								mobile: this.dynamicValidateForm.mobile, //移动电话
								nowaddr: this.dynamicValidateForm.nowaddr,//通讯地址
								collegename1: this.dynamicValidateForm.collegename1, //院校1
								majorname1:this.dynamicValidateForm.majorname1,
								majorname2:this.dynamicValidateForm.majorname2,
								collegename2:this.dynamicValidateForm.collegename2,
								majorname21:this.dynamicValidateForm.majorname21,
								majorname22:this.dynamicValidateForm.majorname22,
								studytypekey: this.studytypeval,
								year: this.dynamicValidateForm.year,
								schoolorgid:this.dynamicValidateForm.schoolorgid,
								companyorgid:this.dynamicValidateForm.companyorgid,
								grade:this.dynamicValidateForm.grade,
								projecttype: this.dynamicValidateForm.projecttype,
								studentinfoid: this.dynamicValidateForm.studentinfoid,
								guakaoschoolorgid: this.dynamicValidateForm.guakaoschoolorgid,
								guakaocompanyorgid: this.dynamicValidateForm.guakaocompanyorgid,
								classaddressid: this.dynamicValidateForm.classaddressid,
							}
					  },
						{
							isRefTableList:true,
							that: this,
							modal: "showDetailList"
						}
						).then((res) =>{
						if (res.data.code === "200") {
							this.id = res.data.data.id;
							this.education(this.id);
						}
					})
				}
			})
		},
    // 跟进 clik
    btnFollow() {
      selectCheck(this.multipleSelection, "跟进", false, () => {
        this.followRoutePage(this.multipleSelection[0].id);
      });
    },
    // 跟进路由页 api
    followRoutePage(id) {
      myRequest({
        url: studentpotential_input,
        data: {
          id: id,
          detailsinput: 1,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm = res.data.data.vstudentpotential;
          this.dynamicValidateForm.qianzaiid = id;
          delete this.dynamicValidateForm.joinuptime;
          delete this.dynamicValidateForm.id;
          delete this.dynamicValidateForm.joinouttime;
          delete this.dynamicValidateForm.lastfollowtime;
          this.showFollowModal = true;
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
        }
      });
    },
    // 跟进保存
    submitFollowForm(dynamicValidateForm) {
      this.dynamicValidateForm.followstateid = 0;
      delete this.dynamicValidateForm.inserttime;
      delete this.dynamicValidateForm.lastinserttime;
			delete this.dynamicValidateForm.remark;
      this.$refs[dynamicValidateForm].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: studentgenzong_save,
              data: this.dynamicValidateForm,
            },
            {
              isRefTableList: true,
              that: this,
              modal: "showFollowModal",
            }
          );
        }
      });
    },
		//报其他
		btnOthers() {
			selectCheck(this.multipleSelection, "报其他", false, () => {
				this.showOthersModal = true;
				this.dynamicValidateForm = {
						companyorgid: this.$store.state.login.userinfo.companyorgid,
						schoolorgid: this.$store.state.login.userinfo.schoolorgid,
						qianzaiid: this.multipleSelection[0].id,
					};
				this.$set(
				  this.dynamicValidateForm,
				  "companyorgname",
				  this.$store.state.login.userinfo.companyorgname
				);
				this.$set(
				  this.dynamicValidateForm,
				  "schoolorgname",
				  this.$store.state.login.userinfo.schoolorgname
				);
				this.org_comboxchild(this.dynamicValidateForm.companyorgid);
				this.generalmoneyiecvRoutePage(this.multipleSelection[0].id);
			});
		},
		//新增路由页
		generalmoneyiecvRoutePage(reportId){
			  myRequest({
			    url: generalmoneyiecv_input,
			    data: {
			      qianzaiid: reportId,
			    },
			  }).then((res) => {
			    if (res.data.code === "200") {
			      this.dynamicValidateForm = Object.assign(
			        res.data.data.vstudentpotential,
							this.dynamicValidateForm = {
								moreItems: [
								  {
								    itemid: "",
								    amount: "",
								    zhaiyao: "",
								    type2id: "",
								    type1id: "",
								    typeid: "",
								  },
								],
							}
			      );
					this.dynamicValidateForm.qianzaiid = res.data.data.vstudentpotential.id;
					delete this.dynamicValidateForm.id;
			    }
			  })
		},
    // 跟进详情 click
    btnFollowDetail() {
      selectCheck(this.multipleSelection, "跟进", false, () => {
        this.getFollowDetailRouter({ qianzaiid: this.multipleSelection[0].id });
      });
    },
    // 跟进详情分页 click
    followDetailHandleCurrentChange(pageindex) {
      this.detailData.pageindex = pageindex;
      this.getFollowDetailRouter(this.detailData);
    },
    // 跟进详情 api
    getFollowDetailRouter(form) {
      myRequest(
        {
          url: studentgenzong_list,
          data: form,
        },
        {
          isRefTableList: true,
          that: this,
        }
      ).then((res) => {
        if (res.data.code === "200") {
          this.detailData = res.data.data;
          this.showFollowDetailModals = true;
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
        }
      });
    },
		//报读学历
	btnReport(){
			this.dialogTitle = "报读学历:";
			selectCheck(this.multipleSelection, "报读学历", false, () => {
			  this.rowItem = this.multipleSelection[0];
			  this.reportRoutePage(this.multipleSelection[0].id);
			});
		},
		//报读学历路由
		reportRoutePage(reportId){
			  myRequest({
			    url: student_addstudentinput,
			    data: {
			      id: reportId,
			    },
			  }).then((res) => {
			    if (res.data.code === "200") {
			      this.dynamicValidateForm = Object.assign(
			        res.data.data.vstudent,
			        res.data.data.vstudentpotential || {}
			      );
						this.dynamicValidateForm.levelval = "";
						this.dynamicValidateForm.course = "";
			      this.dynamicValidateForm.qianzaiid = reportId;
			      delete this.dynamicValidateForm.lastfollowtime;
			      delete this.dynamicValidateForm.lastinserttime;
			      delete this.dynamicValidateForm.id;
			      this.dynamicValidateForm.year = new Date().getFullYear() + "";
			      this.$set(
			        this.dynamicValidateForm,
			        "inserttime",
			        formatDare(
			          this.dynamicValidateForm.inserttime,
			          "YYYY-MM-DD HH:mm:ss"
			        )
			      );
			     this.$set(
			        this.dynamicValidateForm,
			        "joinuptime",
			        formatDare(
			          this.dynamicValidateForm.joinuptime,
			          "YYYY-MM-DD HH:mm:ss"
			        )
			      );
			      this.$set(
			        this.dynamicValidateForm,
			        "joinouttime",
			        formatDare(
			          this.dynamicValidateForm.joinouttime,
			          "YYYY-MM-DD HH:mm:ss"
			        )
			      );
						this.$set(
						  this.dynamicValidateForm,
						  "birthday",
						  formatDare(
						    this.dynamicValidateForm.birthday,
						    "YYYY-MM-DD HH:mm:ss"
						  )
						);

						this.$set(
						  this.dynamicValidateForm,
						  "graduatetime",
						  formatDare(
						    this.dynamicValidateForm.graduatetime,
						    "YYYY-MM-DD HH:mm:ss"
						  )
						);

						this.dynamicValidateForm.joinuptime = formatDare(
						  this.dynamicValidateForm.joinuptime,
						  "YYYY-MM-DD hh:mm:ss"
						);
			      this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
						this.getIdcard();
						this.showDetailList = true;
			    }
			  })
		},
		education(id){
			this.showReportModal = true;
			console.log(this.report_dynamicValidateForm,'这个是报读页面------嗯啊')
			this.report_dynamicValidateForm = {
				moreItems1: [
				    {
							duration: "",
							appoint: "",
							post: "",
							xuezhival: "",
							workingyears: "",
							graduation: ""
				    },
				  ],
				moreItems2: [
				    {
							resumename: "",
							appellation: "",
							age: "",
							occupation: ""
				    },
				  ],
				idphoto : this.dynamicValidateForm.idphoto,
				idphoto2: this.dynamicValidateForm.idphoto2,
				photo : this.dynamicValidateForm.photo,
				registerphoto : this.dynamicValidateForm.registerphoto,
				schoolphoto :this.dynamicValidateForm.schoolphoto,
				regphoto: this.dynamicValidateForm.regphoto,
				registerphoto2: this.dynamicValidateForm.registerphoto2,
				residence: this.dynamicValidateForm.residence
			};
			this.$set(
			  this.report_dynamicValidateForm,
			  "id",
				id
			);
		},
    // 报读学历 - 提交 api
    onComfirm(report_dynamicValidateForm) {
			const moreItemKey = [
			  "duration",
			  "appoint",
			  "post",
			  "xuezhival",
			  "workingyears",
			  "graduation",
			  "resumename",
			  "appellation",
			  "age",
			  "occupation",
			];
			moreItemKey.map((i) => (this.report_dynamicValidateForm[i] = []));
			const moreDataKey = ["moreItems1", "moreItems2"];

			for (let moreKey in moreDataKey){
			  for(let j in moreItemKey){
			    for(let i in this.report_dynamicValidateForm[moreDataKey[moreKey]]){
			      for (let key in this.report_dynamicValidateForm[moreDataKey[moreKey]][i]){
			        if (this.report_dynamicValidateForm[moreDataKey[moreKey]][i][key] && key === moreItemKey[j]) {
			          //有值才push，否则是空字符串
			          this.report_dynamicValidateForm[key].push(this.report_dynamicValidateForm[moreDataKey[moreKey]][i][key]);
			        }
			      }
			    }
			  }
			}
      this.$refs[report_dynamicValidateForm].validate((valid) => {
        if (valid) {
          myRequest(
					{
            method: "post",
            url: student_saveinfo,
            data: this.$qs.stringify(
							this.report_dynamicValidateForm,
						{
              arrayFormat: "repeat",
            }),
          },
					{
						isRefTableList:true,
						that: this,
						modal: "showReportModal"
					}
					).then((res) => {
            if (res.data.code === "200") {
							this.studentFree(this.id);
            }
          });
        }
      });
    },
    //添加一行 - （本人简历） click
    report_addFormItem1() {
      this.report_dynamicValidateForm.moreItems1.push({
        duration: "",
        appoint: "",
        post: "",
        xuezhival: "",
        workingyears: "",
        graduation: ""
      });
    },
    //添加一行 - （家庭成员） click
    report_addFormItem2() {
      this.report_dynamicValidateForm.moreItems2.push({
        resumename: "",
        appellation: "",
        age: "",
        occupation: ""
      });
    },
    //删除一行 click
    report_onRemoveMoreForm(index, prop) {
      this.report_dynamicValidateForm[prop].splice(index, 1);
    },
    //选择第一志愿函授站 click
    onCollegecoporgSelectData(url, modal, data){
      if(!this.dynamicValidateForm.collegename1) return messageTip({
            type: "warning",
            message: '请先选择报考院校',
          });
      this.onSelectData(url, modal, data);
    },
    //确定第一志愿函授站 click
    onComfirmCollegecoporg(){
      selectCheck(this.multipleSelection, "操作", false, () => {
        this.dynamicValidateForm.guakaoschoolorgid = this.multipleSelection[0].schoolorgid;
        this.dynamicValidateForm.guakaocompanyorgid = this.multipleSelection[0].companyorgid;
        this.dynamicValidateForm.classaddressid = this.multipleSelection[0].classid;
        this.$set(this.dynamicValidateForm,'guakaoschoolorgname',this.multipleSelection[0].schoolorgname)
        this.$set(this.dynamicValidateForm,'guakaocompanyorgname',this.multipleSelection[0].companyorgname)
        this.$set(this.dynamicValidateForm,'classaddressname',this.multipleSelection[0].classname)
        this.showCollegecoporg = false;
				this.selectQuery();
        //this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
      });
    },

    // 选择报读学历跟进人 click
    reportSelectBtn(url, modal, data) {

      const requireField = [
				{
				  prop: "sexkey",
				  message: "请先选择考生性别",
				},
				{
				  prop: "levelval",
				  message: "请先选择考生类别",
				},
				{
				  prop: "registeredsubjects",
				  message: "请先选择考生报考科类",
				},
				{
				  prop: "course",
				  message: "请先选择考生考试科目组",
				},
				{
				  prop: "idcardtypeval",
				  message: "请先选择考生证件类型",
				},
				{
				  prop: "idcard",
				  message: "请先输入考生证件号",
				},
				{
				  prop: "grade",
				  message: "请先选择年级",
				},
        {
          prop: "projecttype",
          message: "请先选择项目类型",
        },
				{
				  prop: "studytypekey",
				  message: "请先选择学习形式",
				},
      ];
      for (let i in requireField) {
        if (!this.dynamicValidateForm[requireField[i].prop]) {
          return messageTip({
            type: "warning",
            message: requireField[i].message,
          });
        }
      }
      this.onSelectData(url, modal, data);
    },
    // 选择报读学历跟进人确定
    reportDataComfirm() {
			selectCheck(this.multipleSelection, "报读学历", false, ()=> {
				this.dynamicValidateForm.majorname1 =
				  this.multipleSelection[0].collegemajorname; // 报考专业1
				this.dynamicValidateForm.collegename1 =
				  this.multipleSelection[0].collegename; //院校1
				this.dynamicValidateForm.grade = this.multipleSelection[0].grade; //招生年级
				this.showCollegemajorModal = false;
				this.selectQuery();
				this.multipleSelection = [];
			});
    },

    // 报辅导班 click
    btnReportClass() {
      selectCheck(this.multipleSelection, "报辅导班", false, () => {
        this.rowItem = this.multipleSelection[0];
        this.reportClassRoutePage(this.rowItem.id);
      });
    },
    // 报辅导班路由页 api
    reportClassRoutePage(reportClassId) {
      myRequest({
        url: studentfudao_input,
        data: {
          qianzaiid: reportClassId,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm = Object.assign(
            res.data.data.tstudentfudao,
            res.data.data.tstudentpotential
          );
          this.dynamicValidateForm.qianzaiid = reportClassId;
          this.org_comboxchild(this.dynamicValidateForm.companyorgid);
					this.$set(
					  this.dynamicValidateForm,
					  "birthday",
					  formatDare(
					    this.dynamicValidateForm.birthday,
					    "YYYY-MM-DD HH:mm:ss"
					  )
					);

					this.$set(
					  this.dynamicValidateForm,
					  "graduatetime",
					  formatDare(
					    this.dynamicValidateForm.graduatetime,
					    "YYYY-MM-DD HH:mm:ss"
					  )
					);
          this.reportClassModal = true;
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          delete this.dynamicValidateForm.inserttime;
          delete this.dynamicValidateForm.lastfollowtime;
          delete this.dynamicValidateForm.lastinserttime;
          delete this.dynamicValidateForm.joinuptime;
          delete this.dynamicValidateForm.joinouttime;
          delete this.dynamicValidateForm.id;
        }
      });
    },
    // 报读辅导班确定 click
    reportClassDataComfirm() {
			selectCheck(this.multipleSelection, "报读辅导班", false, () => {
				this.dynamicValidateForm.bookfee = this.multipleSelection[0].bookfee;
				this.dynamicValidateForm.fudaofee = this.multipleSelection[0].fudaofee;
				this.dynamicValidateForm.fudaotypeval =
				  this.multipleSelection[0].fudaotypeval;
				this.dynamicValidateForm.fudaotypekey =
				  this.multipleSelection[0].fudaotypekey;
				this.dynamicValidateForm.classtypeid =
				  this.multipleSelection[0].fudaoclasstypeid;
				this.dynamicValidateForm.fudaoclasstypename =
				  this.multipleSelection[0].fudaoclasstypename;
				this.reportClassDataModal = false;
				this.multipleSelection = [];
				this.selectQuery();
			});
    },
    // 报辅导班 - 提交 api
    reportClassComfirm(dynamicValidateForm) {
      this.$refs[dynamicValidateForm].validate((valid) => {
        if (valid) {
          myRequest({
            url: studentfudao_save,
            data: this.dynamicValidateForm,
          }).then((res) => {
            if (res.data.code === "200") {
              this.reportClassModal = false;
              this.studentfudaoFree(res.data.data.id);
            }
          });
        }
      });
    },
		//注册收费路由页 api
		studentRegisterfee(id) {
			//console.log(id,'这个是idj')
			myRequest({
			  url: studentregisterfeedetail_registerfee,
			  data: {
			    id: id,
			  },
			}).then((res) => {
			  if (res.data.code === "200") {
			    this.dynamicValidateForm = res.data.data;
			    delete this.dynamicValidateForm.inserttime;
			    this.dynamicValidateForm.shoukuantime = formatDare(
			      this.dynamicValidateForm.shoukuantime,
			      "YYYY-MM-DD HH:mm:ss"
			    );
					this.dynamicValidateForm.discountid = res.data.data.discountid,
			    this.dynamicValidateForm.recvitemmoney = res.data.data.recvitemmoney || ''
			    this.showRegisterModal = true;
					this.discoundtlist();
			  }
			});
		},
		//加载优惠卷
		discoundtlist() {
			myRequest({
				url: discount_loaddiscount,
				data: {
					studentid: this.dynamicValidateForm.studentid,
					iyear:this.dynamicValidateForm.iyear,
					grade:this.dynamicValidateForm.grade
				}
			}).then((res) => {
				this.discoundtList = res.data.data.list;
				if(res.data.code === "200") {
					this.getCount = res.data.data.list;
					this.discountid = this.getCount[0].id;
					this.dynamicValidateForm.discountfee = res.data.data.discountfee || '';
				}
			})
		},

		// 注册收费保存 click api
		submitRegisterForm(dynamicValidateForm) {
		  this.$refs[dynamicValidateForm].validate((valid) => {
		    if (valid) {
		      myRequest({
		          url: studentregisterfeedetail_saveregisterfee,
		          data: this.dynamicValidateForm,
		        }).then((res) => {
							if(res.data.code === "200") {
								//this.showRegisterModal = false;
								this.shoujuPaper = true;
								this.showShoujuPage();
							}
						})
		    }
		  });
		},
		showShoujuPage() {
			myRequest({
			       url: studentregisterfeedetail_printshoujuPaper,
			       data:{
			         id: this.dynamicValidateForm.id,
							shoujuid: this.dynamicValidateForm.shoujuid
			         	}
			     }).then((res) => {
			       if (res.data.code === "200") {
								this.tshouju = res.data.data.tshouju;
			       		this.vshouju = res.data.data.vshouju;
								this.vmoneyin = res.data.data.vmoneyin;
								this.vstudentregisterfeedetail = res.data.data.vstudentregisterfeedetail;
								this.sessionuser = res.data.data.sessionuser;
			          var ReportViewer = document.getElementById("ReportViewer");
			          ReportViewer.Stop();    
			          var Report = ReportViewer.Report;
			          var Recordset = Report.DetailGrid.Recordset;

			          //获得参数
								var ppzh= ReportViewer.Report.ParameterByName("pzh");
								var pname= ReportViewer.Report.ParameterByName("name");
								var pinserttime= ReportViewer.Report.ParameterByName("inserttime");
								var pgrade= ReportViewer.Report.ParameterByName("grade");
								var plevel= ReportViewer.Report.ParameterByName("level");
								var pmajor= ReportViewer.Report.ParameterByName("major");
								var premark= ReportViewer.Report.ParameterByName("remark");
								var pshenheren= ReportViewer.Report.ParameterByName("shenheren");
								var pkuaiji= ReportViewer.Report.ParameterByName("kuaiji");
								var pshoukuairen= ReportViewer.Report.ParameterByName("shoukuairen");
								var pkaipiaoren= ReportViewer.Report.ParameterByName("kaipiaoren");
								var psfzhm= ReportViewer.Report.ParameterByName("sfzhm");
								var bankorcash= ReportViewer.Report.ParameterByName("bankorcash");
								//填充参数
								ppzh.AsString= this.tshouju.code;
								pname.AsString=	this.vstudentregisterfeedetail.studentname;
								pinserttime.AsString=	this.vstudentregisterfeedetail.shoukuantime;	//收款时间
								pgrade.AsString=	this.vstudentregisterfeedetail.grade;//年级
								plevel.AsString=	this.vstudentregisterfeedetail.levelval;
								premark.AsString=	this.vstudentregisterfeedetail.shoukuanremark + '* 德立教育集团投诉与建议: 020-89226488';
								pmajor.AsString=	this.vstudentregisterfeedetail.majorname;
								pshoukuairen.AsString= this.vmoneyin.inusername;
								pkaipiaoren.AsString=this.sessionuser.code;
								psfzhm.AsString=	this.vstudentregisterfeedetail.studentidcard == null ? '' : this.vstudentregisterfeedetail.studentidcard;
								if(this.vmoneyin.bankorcash ==1){
									bankorcash.AsString="（现金）";
								}else if(this.vmoneyin.bankorcash ==0){
									bankorcash.AsString="（转账）";
									}


								//获得字段
								var fcollege = ReportViewer.Report.FieldByName("college");
								var fitem = ReportViewer.Report.FieldByName("item");
								var fprice = ReportViewer.Report.FieldByName("price");

								var fitem0 = ReportViewer.Report.FieldByName("item0");
								var fprice0 = ReportViewer.Report.FieldByName("price0");
								var yuan0 = ReportViewer.Report.FieldByName("yuan0");
								var yuan = ReportViewer.Report.FieldByName("yuan");
								var maohao0 = ReportViewer.Report.FieldByName("maohao0");
								var maohao = ReportViewer.Report.FieldByName("maohao");

								//填充字段

								Report.PrepareRecordset();

								Recordset.Append();
								fitem0.AsString =	this.vstudentregisterfeedetail.itemname;
								fprice0.AsFloat =	this.vstudentregisterfeedetail.money; //应收金额
								maohao0.AsString ="：" ;
								yuan0.AsString ="元" ;
								fcollege.AsString = this.vstudentregisterfeedetail.collegename;
								fitem.AsString =	this.vmoneyin.itemmoneyinitemname;
								maohao.AsString ="："
								fprice.AsFloat =this.vmoneyin.amount;
								yuan.AsString ="元" ;
								Recordset.Post();

							 if(this.vstudentregisterfeedetail.discountfee !==0){
									Recordset.Append();
									fitem0.AsString ="优惠" ;
									maohao0.AsString ="："
									fprice0.AsFloat =0-this.vstudentregisterfeedetail.discountfee;
									yuan0.AsString ="元" ;
									Recordset.Post();
							   }

							   if(0-this.vstudentregisterfeedetail.extrasubmoney <0){
									Recordset.Append();
									fitem0.AsString ="减免" ;
									maohao0.AsString ="："
									fprice0.AsFloat =0-this.vstudentregisterfeedetail.extrasubmoney;
									yuan0.AsString ="元" ;
									Recordset.Post();
							   }
							   if(0-this.vstudentregisterfeedetail.studentfee <0){
									Recordset.Append();
									fitem0.AsString ="预交学位费" ;
									maohao0.AsString ="："
									fprice0.AsFloat =0-this.vstudentregisterfeedetail.studentfee;
									yuan0.AsString ="元" ;
									Recordset.Post();
							   }
							   if(0- this.vstudentregisterfeedetail.otherfee  <0){
									Recordset.Append();
									fitem0.AsString ="预交其它费" ;
									maohao0.AsString ="："
									fprice0.AsFloat = 0-this.vstudentregisterfeedetail.otherfee;
									yuan0.AsString ="元" ;
									Recordset.Post();
							   }
			          	ReportViewer.Start();
			       }
			  });
		},
    // 购买教材 click
    btnPurchase() {
      selectCheck(this.multipleSelection, "购买教材", false, () => {
        this.purchaseRoute(this.multipleSelection[0].id);
      });
    },
    //购买教材路由页 api
    purchaseRoute(purchaseId) {
      myRequest({
        url: studentbook_inputall,
        data: {
          qianzaiid: purchaseId,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          const { tstudentpotential } = res.data.data;
          this.moreItemForm = {
            schoolorgid: tstudentpotential.schoolorgid,
            companyorgid: tstudentpotential.companyorgid,
            schoolorgname: tstudentpotential.schoolorgname,
            companyorgname: tstudentpotential.companyorgname,
            name: tstudentpotential.name,
            mobile: tstudentpotential.mobile,
            qianzaiid: purchaseId,
          };
          //增加教材
          this.$set(this.moreItemForm, "moreItems", []);
          this.moreItemForm.moreItems.push({
            bookname: "",
            price: "",
            quality: "",
            discount: "1",
          });
          this.org_comboxchild(this.moreItemForm.companyorgid);
          this.purchaseModal = true;
        }
      });
    },
		//删除其他 click
		onRemoveMoreOther(index) {
		  this.dynamicValidateForm.moreItems.splice(index, 1);
		},
    //删除快速新增项 click
    onRemoveMoreForm(index) {
      this.moreItemForm.moreItems.splice(index, 1);
    },
    //添加一行 click
    addFormItem() {
      this.moreItemForm.moreItems.push({
        bookname: "",
        price: "",
        quality: "",
        discount: "1",
      });
    },

    //购买教材提交 click
    purchaseComfirm(formname) {
      this.moreItemForm.bookname = [];
      this.moreItemForm.price = [];
      this.moreItemForm.quality = [];
      this.moreItemForm.discount = [];
      for (let i in this.moreItemForm.moreItems) {
        for (let key in this.moreItemForm.moreItems[i]) {
          if (this.moreItemForm.moreItems[i][key]) {
            //有值才扔，否则是空字符串
            this.moreItemForm[key].push(this.moreItemForm.moreItems[i][key]);
          }
        }
      }
      this.$refs[formname].validate((valid) => {
        if (valid) {
          this.savePurch();
        }
      });
    },

    //购买教材确定 api
    savePurch() {
      myRequest({
        method: "post",
        url: studentbook_saveall,
        data: this.$qs.stringify(this.moreItemForm, {
          arrayFormat: "repeat",
        }),
      }).then((res) => {
        if (res.data.code === "200") {
          this.purchaseModal = false;
          this.payPurchase(res.data.object.id);
        }
      });
    },

    //购买教材交费路由 api
    payPurchase(id) {
      myRequest({
        url: studentbook_buy,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm = Object.assign(
            res.data.data.tmoneyin,
            res.data.data.tstudentbook
          );
          this.dynamicValidateForm.buytime = formatDare(
            this.dynamicValidateForm.buytime,
            "YYYY-MM-DD HH:mm:ss"
          );
          this.dynamicValidateForm.recvmoney =
            this.dynamicValidateForm.totalmoney;
          delete this.dynamicValidateForm.regdate;
          delete this.dynamicValidateForm.inserttime;
          delete this.dynamicValidateForm.finishdate;
          this.showPurchaseModal = true;
        }
      });
    },

    // 代预报名 click
    btnPre() {
      selectCheck(this.multipleSelection, "预报名", false, () => {
        this.preRoute(this.multipleSelection[0].id);
      });
    },

    //高升专/本 change
    onChangeHighSubjects(vals) {
      Array.from(Array(6), (k, v) => v).map((i) => {
        this.dynamicValidateForm["kaoshikemu1" + i] = 0;
      }); //后台字段名
      vals.map((i) => (this.dynamicValidateForm[i] = 1));
    },

    //专升本 change
    onChangeSpeciallySubjects(vals) {
      Array.from(Array(10), (k, v) => v).map((i) => {
        this.dynamicValidateForm["kaoshikemu2" + i] = 0;
      }); //后台字段名
      vals.map((i) => (this.dynamicValidateForm[i] = 1));
    },

    // 代预报名路由页 api
    preRoute(preId) {
      myRequest({
        url: studentyubaoming_input,
        data: {
          qianzaiid: preId,
        },
      }).then((res) => {
        if (res.data.code == "200") {
          const { vstudentpotential } = res.data.data;
          const { vstudentyubaoming } = res.data.data;
          this.dynamicValidateForm = Object.assign(
            vstudentyubaoming || {},
            vstudentpotential,
          );
          this.dynamicValidateForm.qianzaiid = preId;
					this.dynamicValidateForm.birthday = formatDare(
						  this.dynamicValidateForm.birthday,
						  "YYYY-MM-DD hh:mm:ss"
						);
					this.dynamicValidateForm.graduatetime = formatDare(
						  this.dynamicValidateForm.graduatetime,
						  "YYYY-MM-DD hh:mm:ss"
						);
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.org_comboxchild(this.dynamicValidateForm.companyorgid);
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
          delete this.dynamicValidateForm.lastfollowtime; //inserttime 该字段不用传
          delete this.dynamicValidateForm.isoktime; //isoktime 该字段不用传
          delete this.dynamicValidateForm.lastinserttime;
          delete this.dynamicValidateForm.joinuptime;
          delete this.dynamicValidateForm.joinouttime;
          delete this.dynamicValidateForm.shoukuantime;
          delete this.dynamicValidateForm.finishdate;
					delete this.dynamicValidateForm.jobtime;
          delete this.dynamicValidateForm.id;
					delete this.dynamicValidateForm.remark;

          this.preModal = true;
          Array.from(Array(6), (k, v) => v).forEach((i) => {
            if (this.dynamicValidateForm["kaoshikemu1" + i] === 1) {
              this.highSubjects.push("kaoshikemu1" + i);
            }
          });
          Array.from(Array(10), (k, v) => v).forEach((i) => {
            if (this.dynamicValidateForm["kaoshikemu2" + i] === 1) {
              this.speciallySubjects.push("kaoshikemu2" + i);
            }
          });
        }
      });
    },

    // 预报名确定 api
    preComfirm(dynamicValidateForm) {
      this.$refs[dynamicValidateForm].validate((valid) => {
        if (valid) {
          myRequest({
            url: studentyubaoming_save,
            data: this.dynamicValidateForm,
          }).then((res) => {
            if (res.data.code === "200") {
              this.preModal = false;
              this.studentyubaomingFree(res.data.data.id);
            }
          });
        }
      });
    },

    // 报读技能 click
    btnSkill() {
      selectCheck(this.multipleSelection, "报读技能", false, () => {
        this.skillRoute(this.multipleSelection[0].id);
      });
    },

    // 报读技能路由页 api
    skillRoute(skillId) {
      myRequest({
        url: techfudao_input,
        data: {
          qianzaiid: skillId,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm = Object.assign(
            res.data.data.tStudentinfo,
            res.data.data.vtechfudao
          );
          this.dynamicValidateForm.qianzaiid = skillId;
          this.org_comboxchild(this.dynamicValidateForm.companyorgid);
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
          delete this.dynamicValidateForm.lastfollowtime; //inserttime 该字段不用传
          delete this.dynamicValidateForm.isoktime; //isoktime 该字段不用传
          delete this.dynamicValidateForm.lastinserttime;
          delete this.dynamicValidateForm.joinuptime;
          delete this.dynamicValidateForm.joinouttime;
          delete this.dynamicValidateForm.shoukuantime;
          delete this.dynamicValidateForm.finishdate;
          this.skillModal = true;
        }
      });
    },

    // 选择技能数据确定 click
    skillDataComfirm() {
      this.dynamicValidateForm.bookfee = this.multipleSelection[0].bookfee;
      this.dynamicValidateForm.fudaofee = this.multipleSelection[0].fudaofee;
      this.dynamicValidateForm.fudaotypeval =
        this.multipleSelection[0].fudaotypeval;
      this.dynamicValidateForm.fudaoclasstypename =
        this.multipleSelection[0].fudaoclasstypename;
      this.dynamicValidateForm.classtypeid =
        this.multipleSelection[0].fudaoclasstypeid;
      this.dynamicValidateForm.fudaotypekey =
        this.multipleSelection[0].fudaotypekey;
      this.dynamicValidateForm.stufudaofeestandardid =
        this.multipleSelection[0].id;
				this.gettype();
      this.skillDataModal = false;
			this.selectQuery();
    },
		gettype() {
			myRequest({
			  url: stufudaofeestandarddetail_gettpe,
			  data: {
					stufudaofeestandardid:this.dynamicValidateForm.stufudaofeestandardid
				}
			}).then((res) => {
				console.log(res, 'resstufudaofeestandarddetail_gettpe')
			  if (res.data.code === "200") {
					this.type = res.data.data.type
					for(const key in this.type){
						console.log(key,'key')
						console.log('value',this.type[key])
					}
			  }
			});
		},

    // 报读技能确定 api
    skillComfirm(formName) {
      this.$refs[formName].validate((valid, object) => {
        if (valid) {
          myRequest({
            url: techfudao_save,
            data: this.dynamicValidateForm,
          }).then((res) => {
            if (res.data.code === "200") {
              this.skillModal = false;
              this.techfudaoFree(res.data.data.id);
            }
          });
        }
      });
    },

    // 分配班主任 click
    btnDistribution() {
      selectCheck(this.multipleSelection, "报读技能", true, () => {
        this.distributionRoute(this.multipleSelection.map((i) => i.id));
      });
    },

    // 分配班主任路由 api
    distributionRoute(distributionIds) {
      myRequest({
        method: "post",
        url: studentpotential_changefollow,
        data: this.$qs.stringify(
          {
            ids: distributionIds,
          },
          { arrayFormat: "repeat" }
        ),
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm = res.data.data.vstudentpotential;
          this.dynamicValidateForm.schoolorgid =
            this.multipleSelection[0].schoolorgid;
          this.dynamicValidateForm.companyorgname =
            this.multipleSelection[0].companyorgname;
          this.dynamicValidateForm.schoolorgname =
            this.multipleSelection[0].schoolorgname;
          this.dynamicValidateForm.companyorgid =
            this.multipleSelection[0].companyorgid;
          this.dynamicValidateForm.name = this.multipleSelection
            .map((i) => i.name)
            .toString();
          this.distributionModal = true;
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          // 存储主表传过来的ids
          this.dynamicValidateForm.ids = res.data.data.ids;
					this.org_comboxchild(this.dynamicValidateForm.companyorgid);
					if(this.$store.state.login.minRoleCodes) {
						this.disabled = false
					} else {
						this.disabled = true
					}

        }
      });
    },

    // 报读分配班主任确定 api
    distributionComfirm(dynamicValidateForm) {
      this.$refs[dynamicValidateForm].validate((valid) => {
        if (valid) {
          myRequest({
            url: studentpotential_savechangefollow,
            data: this.dynamicValidateForm,
          }).then((res) => {
            if (res.data.code === "200") {
              this.distributionModal = false;
              this.getDataList();
            }
          });
        }
      });
    },

    //  交费 tab切换 change
    handleClickPay() {
      this.payTableItem(this.tabId);
    },

    // 交费 click
    btnPay() {
      selectCheck(this.multipleSelection, "收费", false, () => {
        this.payTableItem(this.multipleSelection[0].id);
      });
    },

    // 交费 api
    payTableItem(id) {
      this.tabId = id;
      myRequest({
        url: generalmoneyiecv_moneyiecvlist,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.payForm = res.data.data;
          this.payModal = true;
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
        }
      });
    },

    //学历继续教育 收学位费路由页 api
    studentFree(id) {
      myRequest({
        url: student_incollect,
        data: {
          id: id,
        },
      }).then((res) => {
        this.dynamicValidateForm = Object.assign(
          res.data.data.tmoneyin,
          res.data.data.tstudent
        );
        delete this.dynamicValidateForm.inserttime;
        this.org_comboxchild(this.dynamicValidateForm.companyorgid);
        this.showStudentModal = true;
      });
    },

    //代预报名 收费路由页 api
    studentyubaomingFree(id) {
      myRequest({
        url: studentyubaoming_incollect,
        data: {
          id: id,
        },
      }).then((res) => {
        this.dynamicValidateForm = Object.assign(
          res.data.data.tmoneyin,
          res.data.data.vstudentyubaoming
        );
        this.dynamicValidateForm.recvmoney =
          this.dynamicValidateForm.totalmoney;
        delete this.dynamicValidateForm.inserttime;
        this.showStudentyubaomingModal = true;
      });
    },

    //学历辅导班 收费路由页 api
    studentfudaoFree(id) {
      myRequest({
        url: studentfudao_enrol,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          const { tmoneyin } = res.data.data;
          const { tstudentfudao } = res.data.data;
          this.dynamicValidateForm = Object.assign(tmoneyin, tstudentfudao);
          this.dynamicValidateForm.itemname1 = res.data.data.itemname1;
          this.dynamicValidateForm.itemid1 = res.data.data.itemid1;
          this.dynamicValidateForm.recvmoney =
            this.dynamicValidateForm.totalmoney;
          this.dynamicValidateForm.recvfudaofee =
            this.dynamicValidateForm.recvfudaofee || "";
          this.dynamicValidateForm.recvbookfee =
            this.dynamicValidateForm.recvbookfee || "";
          delete this.dynamicValidateForm.regdate;
          delete this.dynamicValidateForm.inserttime;
          delete this.dynamicValidateForm.finishdate;
          this.showStudentfudaoModal = true;
        }
      });
    },

    //技能培训 - 教师资格 收费路由页 api
    techfudaoFree(id) {
      myRequest({
        url: techfudao_enrol,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm = Object.assign(
            res.data.data.tmoneyin,
            res.data.data.ttechfudao
          );
          this.dynamicValidateForm.itemid = 3781600;//初始值 培训费id（原本是调用接口/techfudao/enrol返回的教材费id作为初始值）
          this.dynamicValidateForm.itemname1 = "培训费";
          this.dynamicValidateForm.itemmoneyinitemname = "教材费";
          this.dynamicValidateForm.recvmoney =
            this.dynamicValidateForm.totalmoney;

          this.dynamicValidateForm.recvfudaofee =
            this.dynamicValidateForm.fudaofee; //培训费
          this.dynamicValidateForm.recvbookfee =
            this.dynamicValidateForm.bookfee; //教材费

          delete this.dynamicValidateForm.regdate;
          delete this.dynamicValidateForm.inserttime;
          delete this.dynamicValidateForm.finishdate;
          this.getshoujuinfo();
          this.showTechfudaoModal = true;
        }
      });
    },

    //购买教材 收学位费路由页 api
    purchaseFree(id) {
      myRequest({
        url: studentbook_buy,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm = Object.assign(
            res.data.data.tmoneyin,
            res.data.data.tstudentbook
          );
          this.dynamicValidateForm.recvmoney =
            this.dynamicValidateForm.totalmoney;
          this.dynamicValidateForm.buytime = formatDare(
            this.dynamicValidateForm.buytime,
            "YYYY-MM-DD HH:mm:ss"
          );
          delete this.dynamicValidateForm.regdate;
          delete this.dynamicValidateForm.inserttime;
          delete this.dynamicValidateForm.finishdate;
          this.showPurchaseModal = true;
        }
      });
    },

    //获取收据编号  api
    getshoujuinfo() {
      myRequest({
        url: _shouju_getshoujuinfo,
        data: {
          schoolorgid: this.dynamicValidateForm.schoolorgid,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm.shoujuid = res.data.data.tshouju.id;
          this.dynamicValidateForm.shoujucode = res.data.data.tshouju.code;
        }
      });
    },

    //切换选项表格页
    handleSelectChange(val, data, prop, page) {
      this.selectPageInfo.pageindex = val;
      this.onSelectData(
        this.selectTableUrl,
        null,
        data,
        this.itemRow,
        prop,
        page
      );
    },

    //选中表格数据改变时 change
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    //选项表格 api
    onSelectData(url, modal, data, row, prop, page) {
			this.selectIndex = data?.index;
      this.selectTableUrl = url;
      myRequest({
        url: this.selectTableUrl,
        data: Object.assign(data || {}, this.selectPageInfo),
      }).then((res) => {
        if (res.data.code === "200") {
					this.dynamicValidateForm.index = res.data.data.index;
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.selectTable = res.data.data;
					this.key = Math.random();
          this[modal] = true;
        }
      });
    },
    //选项表格 - 搜索 click
    onSelectSearchClick(url, modal, data) {
      this.selectPageInfo.pageindex = 1;
      this.onSelectData(url, modal, data);
    },
    onHandleCloseSelect() {
      this.onCloseSelect();
      this.handleQuery();
    },
    //关闭选项框 click
    onCloseSelect() {
      this.selectPageInfo.pageindex = 1;
    },
    //确定 - 辅导 click
    onConfirmStufudaofees() {
      selectCheck(this.multipleSelection, "辅导费用", false, () => {
        this.$set(
          this.dynamicValidateForm,
          "fudaoclasstypename",
          this.multipleSelection[0].fudaoclasstypename
        );
        this.$set(
          this.dynamicValidateForm,
          "fudaotypeval",
          this.multipleSelection[0].fudaotypeval
        );
        this.$set(
          this.dynamicValidateForm,
          "fudaofee",
          this.multipleSelection[0].fudaofee
        );
        this.$set(
          this.dynamicValidateForm,
          "bookfee",
          this.multipleSelection[0].bookfee
        );
        this.dynamicValidateForm.classtypeid =
          this.multipleSelection[0].fudaoclasstypeid;
        this.dynamicValidateForm.fudaotypekey =
          this.multipleSelection[0].fudaotypekey;
        this.showStufudaofeesModal = false;
        this.multipleSelection = [];
				this.selectQuery();
      });
    },

    // 确定 - 报读学历收入明细、教材费收入明细 click
    onConfirmMoneyinitem() {
      selectCheck(this.multipleSelection, "教材费收入明细", false, () => {
        this.$set(
          this.dynamicValidateForm,
          "itemmoneyinitemname",
          this.multipleSelection[0].name
        );
        this.dynamicValidateForm.itemid1 = this.multipleSelection[0].id;
        this.dynamicValidateForm.itemid = this.multipleSelection[0].id;//报读学历需要
        this.showSelectItemModal = false;
        this.multipleSelection = [];
				this.selectQuery();
      });
    },


    // 确定 - 辅导费、培训费收入明细 click
    onConfirmMoneyinitem1() {
      selectCheck(this.multipleSelection, "培训费收入明细", false, () => {
        this.$set(
          this.dynamicValidateForm,
          "itemname1",
          this.multipleSelection[0].name
        );
        this.dynamicValidateForm.itemid = this.multipleSelection[0].id;
        this.dynamicValidateForm.itemid1 = this.multipleSelection[0].id;//辅导班要传，已用上。
        this.showSelectItem1Modal = false;
        this.multipleSelection = [];
				this.selectQuery();
      });
    },



    // 确定 - 收入二级类别 click
    onConfirmType() {
      selectCheck(this.multipleSelection, "二级类别", false, () => {
        this.$set(
          this.dynamicValidateForm,
          "typem2oneyintypename",
          this.multipleSelection[0].name
        );
        this.$set(
          this.dynamicValidateForm,
          "typem1oneyintypename",
          this.multipleSelection[0].type1name
        );
        this.$set(
          this.dynamicValidateForm,
          "typemoneyintypename",
          this.multipleSelection[0].typename
        );
        this.dynamicValidateForm.type2id = this.multipleSelection[0].id;
        this.dynamicValidateForm.type1id = this.multipleSelection[0].type1id;
        this.dynamicValidateForm.typeid = this.multipleSelection[0].typeid;
        this.showSelectTypeModal = false;
        this.multipleSelection = [];
				this.selectQuery();
      });
    },

    // 确定 - 银行账户 click
    onConfirmAccount() {
      selectCheck(this.multipleSelection, "银行账户", false, () => {
        this.$set(
          this.dynamicValidateForm,
          "bankorcash",
          this.multipleSelection[0].bankorcash
        );
        this.$set(
          this.dynamicValidateForm,
          "accountname",
          this.multipleSelection[0].accountname
        );
        this.$set(
          this.dynamicValidateForm,
          "cardno",
          this.multipleSelection[0].cardno
        );
        this.dynamicValidateForm.accountid = this.multipleSelection[0].id;
        this.showSelectAccountModal = false;
				this.selectQuery();
        this.multipleSelection = [];
      });
    },

    // 提交收款 click
     onSubmitFormStudent(dynamicValidateForm, url, modal) {
      this.$refs[dynamicValidateForm].validate((valid) => {
        if (valid) {
          myRequest({
            url: url,
            data: this.dynamicValidateForm,
          }).then((res) => {
           if (res.data.code === "200") {
							this.shoujuPaper = true;
							this.printshouju(this.id);
           }
          });
        }
      });
    },
		onSubmitFormYubaoming(dynamicValidateForm, url, modal) {
		  this.$refs[dynamicValidateForm].validate((valid) => {
		    if (valid) {
		      myRequest({
		        url: url,
		        data: this.dynamicValidateForm,
		      }).then((res) => {
		        if (res.data.code === "200") {
							this.shoujuPaper = true;
							this.printbaoming();
		        }
		      });
		    }
		  });
		},
		onSubmitFudao(dynamicValidateForm, url, modal) {
		  this.$refs[dynamicValidateForm].validate((valid) => {
		    if (valid) {
		      myRequest({
		        url: url,
		        data: this.dynamicValidateForm,
		      }).then((res) => {
		        if (res.data.code === "200") {
							this.shoujuPaper = true;
							this.printfudao();
		        }
		      });
		    }
		  });
		},
		onSubmitFormTechfudao(dynamicValidateForm, url, modal) {
		  this.$refs[dynamicValidateForm].validate((valid) => {
		    if (valid) {
		      myRequest({
		        url: url,
		        data: this.dynamicValidateForm,
		      }).then((res) => {
		        if (res.data.code === "200") {
							this.shoujuPaper = true;
							this.printtechfudao();
		        }
		      });
		    }
		  });
		},
		onSubmitForm(dynamicValidateForm, url, modal) {
		  this.$refs[dynamicValidateForm].validate((valid) => {
		    if (valid) {
		      myRequest({
		        url: url,
		        data: this.dynamicValidateForm,
		      }).then((res) => {
		        if (res.data.code === "200") {
							this.shoujuPaper = true;
							this.printbook();
		        }
		      });
		    }
		  });
		},
		//报读技能培训打印收据 -- 电子版本
		// printtechfudao(id){
		// 	myRequest({
		// 		url: techfudao_print,
		// 		data:{
		// 			id: this.dynamicValidateForm.id
		// 		}
		// 	}).then((res) =>{
		// 		if(res.data.code === "200") {
		// 			window.open(
		// 				this.$url.upload +
		// 				"/shouju/" +
		// 				this.dynamicValidateForm.id +
		// 				res.data.data.tshouju.code +
		// 				".pdf"
		// 			)
		// 		}
		// 	})
		// },
		//报读技能培训打印收据 -- 纸质版本
		printtechfudao(id){
			myRequest({
			       url: techfudao_printshoujuPaper,
			       data:{
			         id: this.dynamicValidateForm.id
			         	}
			     }).then((res) => {
			       if (res.data.code === "200") {
								this.tshouju = res.data.data.tshouju;
			       		this.vtechfudao = res.data.data.vtechfudao;
								this.vmoneyinlist = res.data.data.vmoneyinlist;
								res.data.data.vtechfudao.inserttime =
								formatDare(
								  this.vtechfudao.inserttime,
								  "YYYY-MM-DD HH:mm:ss"
								);
			          var ReportViewer = document.getElementById("ReportViewer");
			          ReportViewer.Stop();    
			          var Report = ReportViewer.Report;
			          var Recordset = Report.DetailGrid.Recordset;
			          //获得参数
								var ppzh = ReportViewer.Report.ParameterByName("pzh");
								var pname = ReportViewer.Report.ParameterByName("name");
								var pinserttime= ReportViewer.Report.ParameterByName("inserttime");
								var plevel= ReportViewer.Report.ParameterByName("level");
								var pmajor= ReportViewer.Report.ParameterByName("major");
								var premark= ReportViewer.Report.ParameterByName("remark");
								var pshoukuairen= ReportViewer.Report.ParameterByName("shoukuairen");
								var pkaipiaoren= ReportViewer.Report.ParameterByName("kaipiaoren");
								//填充参数
								ppzh.AsString = this.tshouju.code;
								pname.AsString = this.vtechfudao.studentname;
								pinserttime.AsString= this.vtechfudao.inserttime;
								plevel.AsString=this.vtechfudao.fudaoclasstypename;
								premark.AsString=this.vtechfudao.shoukuanremark === null ? '' + '* 德立教育集团投诉与建议: 020-89226488' : this.vtechfudao.shoukuanremark + '* 德立教育集团投诉与建议: 020-89226488';
								pmajor.AsString="技能培训";
								pshoukuairen.AsString=this.vtechfudao.insertusername;
								pkaipiaoren.AsString=this.vtechfudao.insertusername;

								//获得字段
								var fcollege = ReportViewer.Report.FieldByName("college");
								var fitem0 = ReportViewer.Report.FieldByName("item0");
								var fprice0 = ReportViewer.Report.FieldByName("price0");
								var maohao0 = ReportViewer.Report.FieldByName("maohao0");
								var yuan0 = ReportViewer.Report.FieldByName("yuan0");
								var fitem = ReportViewer.Report.FieldByName("item");
								var maohao = ReportViewer.Report.FieldByName("maohao");
								var fprice = ReportViewer.Report.FieldByName("price");
								var yuan = ReportViewer.Report.FieldByName("yuan");
								Report.PrepareRecordset();


								for (var index = 0; index <	this.vmoneyinlist.length; index++){
									Recordset.Append();
									if(index == 0) {
										fcollege.AsString = this.vtechfudao.fudaoclasstypename;
									}
									if(this.vmoneyinlist[index].itemmoneyinitemname === '培训费') {
                    fitem0.AsString = this.vmoneyinlist[index].itemmoneyinitemname;
										fprice0.AsFloat = this.vtechfudao.fudaofee;//应收
                    maohao0.AsString ="：";
                    yuan0.AsString ="元" ;
									}
                  if (this.vmoneyinlist[index].amount > 0 && this.vmoneyinlist[index].itemmoneyinitemname === '预收教材费' ) {
                      fitem0.AsString = this.vmoneyinlist[index].itemmoneyinitemname;
                      fprice0.AsFloat = this.vtechfudao.bookfee;
                      maohao0.AsString ="：";
                      yuan0.AsString ="元" ;
                  }
                  if(this.vmoneyinlist[index].amount > 0) {
                    fitem.AsString = this.vmoneyinlist[index].itemmoneyinitemname; //实收
									  maohao.AsString ="：" ;
									  fprice.AsFloat = this.vmoneyinlist[index].amount;
									  yuan.AsString ="元" ;
									}
                if(this.vmoneyinlist[index].itemmoneyinitemname === '培训费') {
                  fitem.AsString = this.vmoneyinlist[index].itemmoneyinitemname; //实收
									maohao.AsString ="：" ;
									fprice.AsFloat = this.vmoneyinlist[index].amount;
									yuan.AsString ="元" ;
                }
									Recordset.Post();
								}
								if(0 - this.vtechfudao.preferentialmoney <0){
												Recordset.Append();
												fitem0.AsString ="优惠" ;
												fprice0.AsFloat =0-this.vtechfudao.preferentialmoney;
												yuan0.AsString ="元" ;
												maohao0.AsString ="：" ;

												Recordset.Post();
											}
								if(0 - this.vtechfudao.extrasubmoney <0){
									Recordset.Append();
									fitem0.AsString ="额外减免金额" ;
									fprice0.AsFloat =0-this.vtechfudao.extrasubmoney;
									yuan0.AsString ="元" ;
									maohao0.AsString ="：" ;

									Recordset.Post();
								}


			          ReportViewer.Start();
			       }
			  });

		},

		//代预报名打印收据 -- 电子版本
		// printbaoming(id){
		// 	myRequest({
		// 		url: studentyubaoming_printshouju,
		// 		data:{
		// 			id: this.dynamicValidateForm.id
		// 		}
		// 	}).then((res) =>{
		// 		if(res.data.code === "200") {
		// 			window.open(
		// 				this.$url.upload +
		// 				"/shouju/" +
		// 				this.dynamicValidateForm.id +
		// 				res.data.data.tshouju.code +
		// 				".pdf"
		// 			)
		// 		}
		// 	})
		// },
		//代预报名打印收据 -- 纸质版本
		printbaoming(id){
			myRequest({
			       url: studentyubaoming_printshoujuPaper,
			       data:{
			         id: this.dynamicValidateForm.id
			         	}
			     }).then((res) => {
			       if (res.data.code === "200") {
								this.tshouju = res.data.data.tshouju;
			       		this.vstudentyubaoming = res.data.data.vstudentyubaoming;
								this.vmoneyinlist = res.data.data.vmoneyinlist;
								res.data.data.vstudentyubaoming.shoukuantime =
								formatDare(
								  this.vstudentyubaoming.shoukuantime,
								  "YYYY-MM-DD HH:mm:ss"
								);
			          var ReportViewer = document.getElementById("ReportViewer");
			          ReportViewer.Stop();    
			          var Report = ReportViewer.Report;
			          var Recordset = Report.DetailGrid.Recordset;
			          //获得参数
								var ppzh = ReportViewer.Report.ParameterByName("pzh");
								var pname = ReportViewer.Report.ParameterByName("name");
								var pinserttime= ReportViewer.Report.ParameterByName("inserttime");
								var pgrade = ReportViewer.Report.ParameterByName("grade");
								var plevel= ReportViewer.Report.ParameterByName("level");
								var pmajor= ReportViewer.Report.ParameterByName("major");

								var premark= ReportViewer.Report.ParameterByName("remark");
								var pshoukuairen= ReportViewer.Report.ParameterByName("shoukuairen");
								var pkaipiaoren= ReportViewer.Report.ParameterByName("kaipiaoren");

									ppzh.AsString = this.tshouju.code;
									pname.AsString = this.vstudentyubaoming.qianzainame;
									pinserttime.AsString = this.vstudentyubaoming.shoukuantime;
									pgrade.AsString = this.vstudentyubaoming.year;
									plevel.AsString = this.vstudentyubaoming.wantlevelval === null ? '' : this.vstudentyubaoming.wantlevelval;
									pmajor.AsString = "成人高考";

									premark.AsString = this.vmoneyinlist[0].shoukuanremark === null ? '' + '* 德立教育集团投诉与建议: 020-89226488' :this.vmoneyinlist[0].shoukuanremark + '* 德立教育集团投诉与建议: 020-89226488' ;
									pshoukuairen.AsString = this.vmoneyinlist[0].inusername;
									pkaipiaoren.AsString = this.vmoneyinlist[0].inusername;

								//获得字段
								var fcollege = ReportViewer.Report.FieldByName("college");
								var fitem0 = ReportViewer.Report.FieldByName("item0");
								var maohao0 = ReportViewer.Report.FieldByName("maohao0");
								var fprice0 = ReportViewer.Report.FieldByName("price0");
								var yuan0 = ReportViewer.Report.FieldByName("yuan0");
								var yuan = ReportViewer.Report.FieldByName("yuan");
								var fitem = ReportViewer.Report.FieldByName("item");
								var fprice = ReportViewer.Report.FieldByName("price");
								var maohao = ReportViewer.Report.FieldByName("maohao");
							 //填充字段
								Report.PrepareRecordset();
								for (var index = 0; index <	this.vmoneyinlist.length; index++){
									Recordset.Append();
									fcollege.AsString = this.vmoneyinlist[index].itemmoneyinitemname;
									fitem0.AsString = this.vmoneyinlist[index].itemmoneyinitemname;
									maohao0.AsString ="：" ;
									fprice0.AsFloat = this.vstudentyubaoming.totalmoney;
									yuan0.AsString ="元" ;
									yuan.AsString ="元" ;
									maohao.AsString ="：" ;
									fitem.AsString =	this.vmoneyinlist[index].itemmoneyinitemname;
									fprice.AsFloat = this.vmoneyinlist[index].amount;
									 Recordset.Post();

								}

			          ReportViewer.Start();
			       }
			  });
		},
		//购买教材打印收据 -- 电子版本
		// printbook(id){
		// 	myRequest({
		// 		url: studentbook_print,
		// 		data:{
		// 			id: this.dynamicValidateForm.id
		// 		}
		// 	}).then((res) =>{
		// 		if(res.data.code === "200") {
		// 			window.open(
		// 				this.$url.upload +
		// 				"/shouju/" +
		// 				this.dynamicValidateForm.id +
		// 				res.data.data.tshouju.code +
		// 				".pdf"
		// 			)
		// 		}
		// 	})
		// },
		//购买教材打印收据 -- 纸质版本
		printbook(id){
			myRequest({
			       url: studentbook_printshoujuPaper,
			       data:{
			         id: this.dynamicValidateForm.id
			         	}
			     }).then((res) => {
			       if (res.data.code === "200") {
								this.tshouju = res.data.data.tshouju;
			       		this.vstudentbook = res.data.data.vstudentbook;
								this.vmoneyinlist = res.data.data.vmoneyinlist;
								this.studentbookdetaillist = res.data.data.studentbookdetaillist;
								this.vstudentpotential = res.data.data.vstudentpotential;
								res.data.data.vstudentbook.shoukuantime =
								formatDare(
								  this.vstudentbook.shoukuantime,
								  "YYYY-MM-DD HH:mm:ss"
								);
			          var ReportViewer = document.getElementById("ReportViewer");
			          ReportViewer.Stop();    
			          var Report = ReportViewer.Report;
			          var Recordset = Report.DetailGrid.Recordset;
			          //获得参数
								var ppzh= ReportViewer.Report.ParameterByName("pzh");
								var pinserttime= ReportViewer.Report.ParameterByName("inserttime");
								var pshoukuairen= ReportViewer.Report.ParameterByName("shoukuairen");
								var pname= ReportViewer.Report.ParameterByName("name");
								var pmajor= ReportViewer.Report.ParameterByName("major");
								var plevel= ReportViewer.Report.ParameterByName("level");
								var pkaipiaoren= ReportViewer.Report.ParameterByName("kaipiaoren");
								var premark= ReportViewer.Report.ParameterByName("remark");
								//填充参数
								ppzh.AsString	= this.tshouju.code;
								pname.AsString = this.vstudentbook.studentname;
								plevel.AsString = this.vstudentpotential.levelval;
								pmajor.AsString = "成人高考";
								premark.AsString = this.vstudentbook.shoukuanremark + '* 德立教育集团投诉与建议: 020-89226488';
								pinserttime.AsString = this.vstudentbook.shoukuantime;
								pshoukuairen.AsString = this.vstudentbook.shoukuanusername;
								pkaipiaoren.AsString = this.vstudentbook.shoukuanusername;
								//获得字段
								var fitem0 = ReportViewer.Report.FieldByName("item0");
								var fprice0 = ReportViewer.Report.FieldByName("price0");
								var fcollege = ReportViewer.Report.FieldByName("college");
								var fitem = ReportViewer.Report.FieldByName("item");
								var fprice = ReportViewer.Report.FieldByName("price");
								var yuan0 = ReportViewer.Report.FieldByName("yuan0");
								var maohao0 = ReportViewer.Report.FieldByName("maohao0");
								var yuan = ReportViewer.Report.FieldByName("yuan");
								var maohao = ReportViewer.Report.FieldByName("maohao");

								//填充字段
								Report.PrepareRecordset();

								for (var index = 0; index <	this.studentbookdetaillist.length; index++){
									Recordset.Append();
									fcollege.AsString = this.studentbookdetaillist[index].bookname2;
									fitem0.AsString = this.studentbookdetaillist[index].price +'*' + this.studentbookdetaillist[index].quality + '*' + this.studentbookdetaillist[index].discount + '=';
									fprice0.AsFloat = this.studentbookdetaillist[index].price * + this.studentbookdetaillist[index].quality * this.studentbookdetaillist[index].discount;//应收
									if(index == 0) {
										fitem.AsString ="合计";
										maohao.AsString ="：";
										fprice.AsFloat =	this.vmoneyinlist[0].amount; //实收
										yuan.AsString ="元";

									}

									yuan0.AsString ="元";

									Recordset.Post();
								}
								if(0- this.vstudentbook.preferentialmoney <0){
										Recordset.Append();
										fitem0.AsString ="优惠";
										fprice0.AsFloat =	0 - this.vstudentbook.preferentialmoney;
										yuan0.AsString ="元";
										maohao0.AsString ="：";


										Recordset.Post();
								}

			          ReportViewer.Start();
			       }
			  });
		},
		//辅导班打印收据 -- 电子版本
		// printfudao(id){
		// 	myRequest({
		// 		url: studentfudao_print,
		// 		data:{
		// 			id: this.dynamicValidateForm.id
		// 		}
		// 	}).then((res) =>{
		// 		if(res.data.code === "200") {
		// 			window.open(
		// 				this.$url.upload +
		// 				"/shouju/" +
		// 				this.dynamicValidateForm.id +
		// 				res.data.data.tshouju.code +
		// 				".pdf"
		// 			)
		// 		}
		// 	})
		// },
		//辅导班打印收据 -- 纸质版本
		printfudao(id){
			myRequest({
			       url: studentfudao_printshoujuPaper,
			       data:{
			         id: this.dynamicValidateForm.id
			         	}
			     }).then((res) => {
			       if (res.data.code === "200") {
								this.tshouju = res.data.data.tshouju;
			       		this.vstudentfudao = res.data.data.vstudentfudao;
								this.vmoneyinlist = res.data.data.vmoneyinlist;
								res.data.data.vstudentfudao.inserttime =
								formatDare(
								  this.vstudentfudao.inserttime,
								  "YYYY-MM-DD HH:mm:ss"
								);
			          var ReportViewer = document.getElementById("ReportViewer");
			          ReportViewer.Stop();    
			          var Report = ReportViewer.Report;
			          var Recordset = Report.DetailGrid.Recordset;
			          //获得参数
								var ppzh = ReportViewer.Report.ParameterByName("pzh");
								var pname = ReportViewer.Report.ParameterByName("name");
								var pinserttime= ReportViewer.Report.ParameterByName("inserttime");
								var plevel= ReportViewer.Report.ParameterByName("level");
								var pmajor= ReportViewer.Report.ParameterByName("major");

								var premark= ReportViewer.Report.ParameterByName("remark");
								var pshoukuairen= ReportViewer.Report.ParameterByName("shoukuairen");
								var pkaipiaoren= ReportViewer.Report.ParameterByName("kaipiaoren");

									ppzh.AsString = this.tshouju.code;
									pname.AsString = this.vstudentfudao.studentname;
									pinserttime.AsString = this.vstudentfudao.inserttime;
									plevel.AsString = this.vstudentfudao.levelval;
									pmajor.AsString="成人高考";

                                    premark.AsString = this.vstudentfudao.shoukuanremark === null ? '' + '* 德立教育集团投诉与建议: 020-89226488' : this.vstudentfudao.shoukuanremark + '* 德立教育集团投诉与建议: 020-89226488';
									pshoukuairen.AsString = this.vmoneyinlist[0].inusername;
									pkaipiaoren.AsString = this.vmoneyinlist[0].inusername;

								//获得字段
								var fcollege = ReportViewer.Report.FieldByName("college");
								var fitem = ReportViewer.Report.FieldByName("item");
								var fitem0 = ReportViewer.Report.FieldByName("item0");
								var maohao0 = ReportViewer.Report.FieldByName("maohao0");
								var fprice0 = ReportViewer.Report.FieldByName("price0");
								var yuan0 = ReportViewer.Report.FieldByName("yuan0");
								var yuan = ReportViewer.Report.FieldByName("yuan");
								var fitem = ReportViewer.Report.FieldByName("item");
								var fprice = ReportViewer.Report.FieldByName("price");
								var maohao = ReportViewer.Report.FieldByName("maohao");
							 //填充字段
								Report.PrepareRecordset();
									Recordset.Append();
									fcollege.AsString = this.vstudentfudao.fudaoclasstypename;
									fitem0.AsString =this.vmoneyinlist[1].itemmoneyinitemname;//应收金额明细项名称（辅导费等）
									maohao0.AsString ="：" ;
									fprice0.AsFloat =this.vstudentfudao.fudaofee;
									if(this.vmoneyinlist[1]!=null) {
										fitem.AsString = this.vmoneyinlist[1].itemmoneyinitemname;
										maohao.AsString ="：" ;
										fprice.AsFloat = this.vmoneyinlist[1].amount;
										yuan.AsString ="元" ;
										pshoukuairen.AsString= this.vmoneyinlist[1].inusername;
										pkaipiaoren.AsString=this.vmoneyinlist[1].inusername;
									}

										Recordset.Post();

                           if(this.vmoneyinlist[0].amount > 0) {//如果教材费的实收大于0
                               Recordset.Append();
                               fitem0.AsString = "教材费";
                               maohao0.AsString = "：";
                               fprice0.AsFloat = this.vstudentfudao.bookfee;//应收
                               yuan0.AsString = "元";
                               //Recordset.Post();
                           }
                           if(this.vmoneyinlist[0].amount > 0){//如果教材费的实收大于0
                               //Recordset.Append();
                               fitem.AsString =this.vmoneyinlist[0].itemmoneyinitemname;
                               maohao.AsString ="：" ;
                               fprice.AsFloat =this.vmoneyinlist[0].amount;//实收
                               yuan.AsString ="元" ;
                               Recordset.Post();
                           }

                         if(0-this.vstudentfudao.preferentialmoney <0){
												Recordset.Append();
												fitem0.AsString ="优惠" ;
												maohao0.AsString ="：" ;
												fprice0.AsFloat = 0-this.vstudentfudao.preferentialmoney;
												yuan0.AsString ="元" ;
												Recordset.Post();
                          }
                         if(0-this.vstudentfudao.extrasubmoney <0){
												Recordset.Append();
												fitem0.AsString ="额外减免金额";
												maohao0.AsString ="：" ;
												fprice0.AsFloat =0-this.vstudentfudao.extrasubmoney;
												yuan0.AsString ="元" ;

												Recordset.Post();
                         }


			          ReportViewer.Start();
			       }
			  });
		},
		//德立学生打印收据 -- 电子版本
		// printshouju(id){
		// 	myRequest({
		// 		url: student_printshouju,
		// 		data:{
		// 			id: this.dynamicValidateForm.id
		// 		}
		// 	}).then((res) =>{
		// 		console.log(res,'======================')
		// 		if(res.data.code === "200") {
		// 			window.open(
		// 				this.$url.upload +
		// 				"/shouju/" +
		// 				this.dynamicValidateForm.id +
		// 				res.data.data.tshouju.code +
		// 				".pdf"
		// 			)
		// 		}
		// 	})
		// },
		//德立学生打印收据 -- 纸质版本
		printshouju(id){
			myRequest({
			       url: student_printshoujuPaper,
			       data:{
			         id: this.dynamicValidateForm.id
			         	}
			     }).then((res) => {
			       if (res.data.code === "200") {
								this.tshouju = res.data.data.tshouju;
			       		this.vstudent = res.data.data.vstudent;
								this.vmoneyinlist = res.data.data.vmoneyinlist;
								res.data.data.vstudent.shoukuantime =
								formatDare(
								  this.vstudent.shoukuantime,
								  "YYYY-MM-DD HH:mm:ss"
								);
			          var ReportViewer = document.getElementById("ReportViewer");
			          ReportViewer.Stop();    
			          var Report = ReportViewer.Report;
			          var Recordset = Report.DetailGrid.Recordset;
			          //获得参数
			          var ppzh = ReportViewer.Report.ParameterByName("pzh");
								var pname = ReportViewer.Report.ParameterByName("name");
								var psfzhm = ReportViewer.Report.ParameterByName("sfzhm");
								var pinserttime= ReportViewer.Report.ParameterByName("inserttime");
								var pgrade = ReportViewer.Report.ParameterByName("grade");
								var plevel= ReportViewer.Report.ParameterByName("level");
								var pmajor= ReportViewer.Report.ParameterByName("major");

								var premark= ReportViewer.Report.ParameterByName("remark");
								var pshoukuairen= ReportViewer.Report.ParameterByName("shoukuairen");
								var pkaipiaoren= ReportViewer.Report.ParameterByName("kaipiaoren");

			          ppzh.AsString = this.tshouju.code;
								pname.AsString = this.vstudent.qianzainame;
								psfzhm.AsString = this.vstudent.studentidcard;
								pinserttime.AsString = this.vstudent.shoukuantime;
								pgrade.AsString = this.vstudent.grade;
								plevel.AsString=this.vstudent.levelval;
								pmajor.AsString=this.vstudent.majorname1;

								premark.AsString = this.vstudent.shoukuanrenark === null ? '' + '* 德立教育集团投诉与建议: 020-89226488' : this.vstudent.shoukuanrenark + '* 德立教育集团投诉与建议: 020-89226488' ;
								pshoukuairen.AsString = this.vmoneyinlist[0].inusername;
								pkaipiaoren.AsString = this.vmoneyinlist[0].inusername;

									//获得字段
								var fcollege = ReportViewer.Report.FieldByName("college");
								var fitem0 = ReportViewer.Report.FieldByName("item0");
								var fprice0 = ReportViewer.Report.FieldByName("price0");
								var yuan0 = ReportViewer.Report.FieldByName("yuan0");
								var fitem = ReportViewer.Report.FieldByName("item");
								var fprice = ReportViewer.Report.FieldByName("price");
								var pmaohao = ReportViewer.Report.FieldByName("maohao");
								var maohao0 = ReportViewer.Report.FieldByName("maohao0");
								var yuan = ReportViewer.Report.FieldByName("yuan");


							 //填充字段
								Report.PrepareRecordset();
								for (var index = 0; index <	this.vmoneyinlist.length; index++) {
								Recordset.Append();
								fcollege.AsString = this.vstudent.collegename1;
								maohao0.AsString ="：" ;
								yuan0.AsString ="元" ;
								fprice.AsFloat = this.vmoneyinlist[index].amount;
								fitem0.AsString =this.vmoneyinlist[index].itemmoneyinitemname;
								fitem.AsString =this.vmoneyinlist[index].itemmoneyinitemname;
								pmaohao.AsString ="：" ;
								fprice0.AsFloat =this.vstudent.shouldrecvmoney;
								yuan.AsString ="元" ;
								Recordset.Post();
						}
            if(this.vstudent.discountfee !==0){
                  Recordset.Append();
                  fitem0.AsString ="优惠" ;
                  maohao0.AsString ="："
                  fprice0.AsFloat =0-this.vstudent.discountfee;
                  yuan0.AsString ="元" ;
                  Recordset.Post();
             }

			          	ReportViewer.Start();
			       }
			  });
		},
    //  查看详情 tab切换 change
    handleClick() {
      this.watchDetail(this.tabId);
    },
    // 查看详情 clik
    watchDetail(id) {
      this.tabId = id;
      myRequest({
        url: studentpotential_show,
        data: {
          id: id
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.dialogTitle = "查看";
          this.watchDetailList = res.data.data;
          this.vstudentpotential = this.watchDetailList.vstudentpotential;
          this.showDetailModal = true;
					this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
        }
      });
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },
  },
};
</script>
<style lang="scss">
	@import "../../../assets/css/pulid.scss";
	.abase{
		width: 800px;
		background-color: #D1EEEE;
		border: 1px solid #4d5d6f;
		margin: auto;
		.top{
			text-align: center;
			line-height: 35px;
			color: #904209;
			font-size:20px;
		}
		table{
			border-collapse: collapse;
			border-spacing: 0;
			border: 1px solid #afa6ff;
			font: 90% "Trebuchet MS", Tahoma, Arial, Helvetica, sans-serif;
		}
		.tlist{
			td,th{
				border-right: 1px solid #afa6ff;
				border-bottom: 1px solid #afa6ff;
				border-top: 0px;
				border-left: 0px;
				vertical-align: middle;
			}
			.heigsuperrig {
				height: 38px!important;
				margin-top: -24px;
			}
			.ltd{
				width: 100px;
				text-align: right;
			}
		}
	}
	.kelei_pro {
		border-top: 1px solid #afa6ff!important;
	}
	.flex-xl {
		display: flex;
		/* justify-content: start; */
		align-items: center;
	}
	.title-font{
		margin-left: 10px;
	}
	.heigsuper,.heigsuperrig{
		height: 38px;
	}
.ex-col{
	height: 44px;
	margin-top: -24px!important;
}
.ex-col_el{
	height: 58px;
  margin-top: -7px;
}

	.more-form-www {
		display: flex;
		flex-wrap: nowrap;
		.el-form-item {
			width: 24%;
			vertical-align: top;
		}
	}

	.sex .el-form-item__label{
		margin-top: -11px;
	}

</style>
